import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RouterAction } from 'connected-react-router';
import { CommonActions, Normalized } from '@gts-common/client';
import { AddedTable, AddedServiceArea } from '@gts-ft/utils';
import {
  ClientReservation,
  InitialDataLoad,
  ReduxState,
  RestaurantReservationTimesState,
  RestaurantSettingsState,
} from '../types';

export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_CHECK_SUCCEEDED = 'LOGIN_CHECK_SUCCEEDED';
export const LOGIN_CHECK_FAILED = 'LOGIN_CHECK_FAILED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';

export const RESERVATION_ADDED = 'RESERVATION_ADDED';
export const RESERVATION_DELETED = 'RESERVATION_DELETED';
export const RESERVATION_CANCELLED = 'RESERVATION_CANCELLED';
export const RESERVATION_UPDATED = 'RESERVATION_UPDATED';
export const GET_RESERVATIONS_SUCCEEDED = 'GET_RESERVATIONS_SUCCEEDED';
export const SET_ARRIVAL_STATUS_SUCCEEDED = 'SET_ARRIVAL_STATUS_SUCCEEDED';
export const EARLIEST_LOADED_DATE_CHANGED = 'EARLIEST_LOADED_DATE_CHANGED';
export const RESERVATIONS_DATE_CHANGED = 'RESERVATIONS_DATE_CHANGED';

export const TABLE_ADDED = 'TABLE_ADDED';
export const TABLE_UPDATED = 'TABLE_UPDATED';
export const TABLE_DELETED = 'TABLE_DELETED';
export const TABLE_BULK_ADDED = 'TABLE_BULK_ADDED';
export const TABLE_BOOKING_STATUS_DATETIME = 'TABLE_BOOKING_STATUS_DATETIME';

export const SERVICE_AREA_ADDED = 'SERVICE_AREA_ADDED';
export const SERVICE_AREA_UPDATED = 'SERVICE_AREA_UPDATED';
export const SERVICE_AREA_DELETED = 'SERVICE_AREA_DELETED';

export const APP_LIVE_DATE_CHANGED = 'APP_LIVE_DATE_CHANGED';

export const RESTAURANT_RESERVATION_TIMES_UPDATED =
  'RESTAURANT_RESERVATION_TIMES_UPDATED';
export const RESTAURANT_SETTINGS_UPDATED = 'RESTAURANT_SETTINGS_UPDATED';
export const UPDATE_LOGO_FILENAME = 'UPDATE_LOGO_FILENAME';

// Online - Offline
export const COULD_NOT_STORE_OFFLINE_DATA_SHOWN =
  'COULD_NOT_STORE_OFFLINE_DATA_SHOWN';
export const SHOW_OFFLINE_VIEW = 'SHOW_OFFLINE_VIEW';
export const SHOW_APP_WITH_OFFLINE_DATA = 'SHOW_APP_WITH_OFFLINE_DATA';

// features
export const FEATURE_INDEXED_DB_TESTED = 'FEATURE_INDEXED_DB_TESTED';

export const SETUP_WIZARD_NEXT_STEP = 'SETUP_WIZARD_NEXT_STEP';
export const SETUP_WIZARD_PREVIOUS_STEP = 'SETUP_WIZARD_PREVIOUS_STEP';
export const SETUP_COMPLETED = 'SETUP_COMPLETED';

export const IS_ONLINE = 'IS_ONLINE';
export const IS_OFFLINE = 'IS_OFFLINE';

export interface LoginSucceededAction extends Action {
  type: typeof LOGIN_SUCCEEDED;
  payload: InitialDataLoad;
}

export interface LoginFailedAction extends Action {
  type: typeof LOGIN_FAILED;
}

export interface LoginCheckSucceededAction extends Action {
  type: typeof LOGIN_CHECK_SUCCEEDED;
  payload: InitialDataLoad;
}

export interface LoginCheckFailedAction extends Action {
  type: typeof LOGIN_CHECK_FAILED;
}

export interface LogoutSucceededAction extends Action {
  type: typeof LOGOUT_SUCCEEDED;
}

export interface ReservationAddedAction extends Action {
  type: typeof RESERVATION_ADDED;
  payload: Normalized<ClientReservation>;
}

export interface ReservationDeletedAction extends Action {
  type: typeof RESERVATION_DELETED;
  payload: string;
}

export interface ReservationCancelledAction extends Action {
  type: typeof RESERVATION_CANCELLED;
  payload: {
    reservationId: string;
    cancelledAt: string;
  };
}

export interface ReservationUpdatedAction extends Action {
  type: typeof RESERVATION_UPDATED;
  payload: {
    reservationId: string;
    reservation: Partial<ClientReservation>;
  };
}

export interface SetArrivalStatusSucceededAction extends Action {
  type: typeof SET_ARRIVAL_STATUS_SUCCEEDED;
  payload: { reservationId: string; arrived: boolean };
}

export interface GetReservationsSucceededAction extends Action {
  type: typeof GET_RESERVATIONS_SUCCEEDED;
  payload: Normalized<ClientReservation>;
}

export interface ReservationsDateChangedAction extends Action {
  type: typeof RESERVATIONS_DATE_CHANGED;
  payload: Date;
}

export interface TableAddedAction extends Action {
  type: typeof TABLE_ADDED;
  payload: Normalized<AddedTable>;
}

export interface TableBulkAddedAction extends Action {
  type: typeof TABLE_BULK_ADDED;
  payload: Normalized<AddedTable>;
}

export interface TableUpdatedAction extends Action {
  type: typeof TABLE_UPDATED;
  payload: {
    tableId: string;
    table: Partial<AddedTable>;
  };
}

export interface TableDeletedAction extends Action {
  type: typeof TABLE_DELETED;
  payload: string;
}

export interface TableBookingStatusDatetimeChanged extends Action {
  type: typeof TABLE_BOOKING_STATUS_DATETIME;
  payload: Date;
}

export interface ShowOfflineViewAction extends Action {
  type: typeof SHOW_OFFLINE_VIEW;
}

export interface ShowAppWithOfflineDataAction extends Action {
  type: typeof SHOW_APP_WITH_OFFLINE_DATA;
  payload: number | undefined;
}

export interface ServiceAreaAddedAction extends Action {
  type: typeof SERVICE_AREA_ADDED;
  payload: Normalized<AddedServiceArea>;
}

export interface ServiceAreaUpdatedAction extends Action {
  type: typeof SERVICE_AREA_UPDATED;
  payload: {
    serviceAreaId: string;
    serviceArea: Partial<AddedServiceArea>;
  };
}

export interface ServiceAreaDeletedAction extends Action {
  type: typeof SERVICE_AREA_DELETED;
  payload: string; // serviceAreaId
}

export interface AppLiveDateChangedAction extends Action {
  type: typeof APP_LIVE_DATE_CHANGED;
  payload: Date;
}

export interface EarliestLoadedDateChangedAction extends Action {
  type: typeof EARLIEST_LOADED_DATE_CHANGED;
  payload: {
    loadedDate: Date;
    entities: Record<string, ClientReservation>;
    items: Array<string>;
  };
}

export interface RestaurantReservationTimesUpdatedAction extends Action {
  type: typeof RESTAURANT_RESERVATION_TIMES_UPDATED;
  payload: RestaurantReservationTimesState;
}

export interface RestaurantSettingsUpdatedAction extends Action {
  type: typeof RESTAURANT_SETTINGS_UPDATED;
  payload: Partial<RestaurantSettingsState>;
}

export interface UpdatedLogoFilenameAction extends Action {
  type: typeof UPDATE_LOGO_FILENAME;
  payload: RestaurantSettingsState['logoFilename'];
}

export interface FeatureIndexedDbTestedAction extends Action {
  type: typeof FEATURE_INDEXED_DB_TESTED;
  payload: boolean;
}

export interface CouldNotStoreOfflineDataShownAction extends Action {
  type: typeof COULD_NOT_STORE_OFFLINE_DATA_SHOWN;
}

export interface GoToSetupWizardNextStep extends Action {
  type: typeof SETUP_WIZARD_NEXT_STEP;
}

export interface GoToSetupWizardPreviousStep extends Action {
  type: typeof SETUP_WIZARD_PREVIOUS_STEP;
}

export interface SetupCompleted extends Action {
  type: typeof SETUP_COMPLETED;
}

export interface IsOnlineAction extends Action {
  type: typeof IS_ONLINE;
}
export interface IsOfflineAction extends Action {
  type: typeof IS_OFFLINE;
}

export type ReservationAction =
  | ReservationAddedAction
  | ReservationDeletedAction
  | ReservationCancelledAction
  | ReservationUpdatedAction
  | SetArrivalStatusSucceededAction
  | GetReservationsSucceededAction
  | ReservationsDateChangedAction;

export type TableAction =
  | TableAddedAction
  | TableUpdatedAction
  | TableDeletedAction
  | TableBulkAddedAction
  | TableBookingStatusDatetimeChanged;

export type NavigationAction =
  | ShowOfflineViewAction
  | ShowAppWithOfflineDataAction;

export type ServiceAreaAction =
  | ServiceAreaAddedAction
  | ServiceAreaUpdatedAction
  | ServiceAreaDeletedAction;

export type Actions =
  | LoginSucceededAction
  | LoginFailedAction
  | LoginCheckSucceededAction
  | LoginCheckFailedAction
  | LogoutSucceededAction
  | ReservationAction
  | TableAction
  | NavigationAction
  | ServiceAreaAction
  | AppLiveDateChangedAction
  | EarliestLoadedDateChangedAction
  | RestaurantReservationTimesUpdatedAction
  | RestaurantSettingsUpdatedAction
  | UpdatedLogoFilenameAction
  | FeatureIndexedDbTestedAction
  | CouldNotStoreOfflineDataShownAction
  | RouterAction
  | GoToSetupWizardNextStep
  | GoToSetupWizardPreviousStep
  | SetupCompleted
  | IsOfflineAction
  | IsOnlineAction
  | CommonActions;

export type Thunk<A extends Action> = ThunkAction<void, ReduxState, unknown, A>;
