import { prepareTablesForSelect } from '@gts-ft/ui';
import {
  TextField,
  SelectField,
  SaveButton,
  AddButton,
} from '@gts-common/client';

import { Box } from '@material-ui/core';
import { Form } from 'formik';
import { AddedTable } from '@gts-ft/utils';

interface Props {
  isEdit: boolean;
  realTables: Array<AddedTable>;
  isOnline: boolean;
}

const formId = 'service-area-form';

export const ServiceAreaForm = ({ realTables, isEdit, isOnline }: Props) => (
  <Form id={formId} noValidate={true}>
    <TextField label="Name" name="name" required autoFocus />
    <SelectField
      clearable={true}
      name="tableIds"
      label="Tische"
      noResultsText="Keine Tische verfügbar"
      options={prepareTablesForSelect(realTables)}
      isMulti={true}
      required={true}
    />
    <Box mt="1rem" textAlign="center">
      {isEdit && <SaveButton submitForForm={formId} disabled={!isOnline} />}
      {!isEdit && <AddButton submitForForm={formId} disabled={!isOnline} />}
    </Box>
  </Form>
);
