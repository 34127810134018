import { Box, Grid, List, Typography } from '@material-ui/core';

import { AddButton, View } from '@gts-common/client';
import { HOME_VIEW } from '../../constants';
import { ServiceAreaWithTableNos } from '../../types';
import { ServiceArea } from './ServiceArea';

interface Props {
  serviceAreas: Array<ServiceAreaWithTableNos>;
  onEdit: (serviceAreaId: string) => void;
  onDelete: (serviceAreaId: string) => void;
  onAdd: () => void;
  isOnline: boolean;
}

export const ServiceAreasList = ({
  serviceAreas,
  onEdit,
  onDelete,
  onAdd,
  isOnline,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Servicebereiche verwalten"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Servicebereiche bieten Ihnen die Möglichkeit Reservierungen über die
            Tische zu verteilen. Beispiel: Servicebereich 1 hat Tische 01-10 und
            Servicebereich 2 hat Tische 11-20. Die erste Reservierung über das
            Reservierungsformular wird dem Servicebereich 1 zugeordnet. Die
            zweite Reservierung Servicebereich 2. Die dritte Reservierung wieder
            Servicebereich 1 usw..
          </Typography>
          <List>
            {serviceAreas.map((serviceArea) => (
              <ServiceArea
                key={serviceArea.serviceAreaId}
                {...serviceArea}
                onEdit={() => onEdit(serviceArea.serviceAreaId)}
                onDelete={() => onDelete(serviceArea.serviceAreaId)}
                isOnline={isOnline}
              />
            ))}
          </List>
        </Grid>
      </Grid>
      <Box mt="1rem" textAlign="center">
        <AddButton
          onClick={onAdd}
          label="Neuer Servicebereich"
          disabled={!isOnline}
        />
      </Box>
    </View>
  );
};
