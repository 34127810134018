import { TablesState } from '../../../types';

export function isTableNameUnique(
  newTableNo: string | undefined,
  items: Array<string>,
  entities: TablesState['entities'],
  currentTableId?: string,
) {
  // In case of an update the tableNo could be undefined if the user
  // does not want to update it. Consider it to be unique and skip the test
  if (typeof newTableNo === 'undefined') {
    return true;
  }

  return items
    .filter((tableId) => tableId !== currentTableId)
    .every((tableId) => entities[tableId].tableNo !== newTableNo);
}
