import { ReservationFormData, reservationAddValidation } from '@gts-ft/ui';

import { Formik } from 'formik';

import { View } from '@gts-common/client';
import { AddedTable } from '@gts-ft/utils';
import { NavigationCameFrom, NavigationTo } from '../../types';
import { ReservationForm } from './ReservationForm';

interface Props {
  execAddReservation: (
    reservation: ReservationFormData,
    navigateBackTo: NavigationTo,
  ) => void;
  tables: Record<string, AddedTable>;
  reservation: ReservationFormData;
  isOnline: boolean;
  cameFrom: NavigationCameFrom;
}

export const ReservationAdd = ({
  execAddReservation,
  tables,
  reservation,
  isOnline,
  cameFrom,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      backButtonLabel={cameFrom.backButtonLabel}
      navigateBackTo={cameFrom.to}
      title="Reservierung hinzufügen"
    >
      <Formik
        onSubmit={(values) => execAddReservation(values, cameFrom.to)}
        initialValues={reservation}
        validationSchema={reservationAddValidation}
      >
        {({ values, errors }) => {
          return (
            <ReservationForm
              isOnline={isOnline}
              isEdit={false}
              arrived={false}
              values={values}
              errors={errors}
              tables={tables}
            />
          );
        }}
      </Formik>
    </View>
  );
};
