import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { replace } from 'connected-react-router';
import { serverRequestFailed } from '@gts-common/client';
import { ReduxState, RestaurantSettingsState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { RestaurantSettings } from '../components/RestaurantSettings/RestaurantSettings';
import { execRestaurantSettingsChange } from '../actions';
import { HOME_VIEW } from '../constants';
import { FileUploaderAction } from '../components/UI-Components';
import { execUpdateLogoFilename } from './helpers/execUpdateLogoFilename';

const mapStateToProps = (state: ReduxState) => ({
  settings: state.restaurant.settings,
  widgetUrl: state.restaurant.runtimeSettings.widgetUrl,
  isOnline: state.app.isOnline,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execRestaurantSettingsChange(
    restaurantSettingsObject: RestaurantSettingsState,
  ) {
    dispatch(
      execRestaurantSettingsChange(
        restaurantSettingsObject,
        replace(HOME_VIEW),
      ),
    );
  },
  execDataLoadFailed(reason: string) {
    dispatch(serverRequestFailed(reason));
  },
  execUpdateLogoFilename(
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) {
    execUpdateLogoFilename(dispatch, action, files);
  },
});

export const RestaurantSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestaurantSettings);
