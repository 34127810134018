import { Typography, Box } from '@material-ui/core';

import { AddButton, View } from '@gts-common/client';

import { useEffect } from 'react';
import { HOME_VIEW } from '../../constants';
import { ClientReservationWithExtraProperties } from '../../types';
import { Picker } from '../UI-Components/Picker/Picker';
import { ReservationsListGroup } from './ReservationsListGroup';

interface Props {
  arrivedReservations: Array<ClientReservationWithExtraProperties>;
  notArrivedReservations: Array<ClientReservationWithExtraProperties>;
  isOnline: boolean;
  numOfReservations: number;
  numOfPersons: number;
  numOfCancelled: number;
  reservationsDatetime: Date;
  showReservationAdd: () => void;
  execSetArrivalStatus: (reservationId: string, arrived: boolean) => void;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
  changeReservationsDate: (newReservationsDate: Date | null) => void;
  resetReservationsDate: () => void;
}

export const ReservationsAllView = ({
  arrivedReservations,
  notArrivedReservations,
  isOnline,
  numOfReservations,
  numOfPersons,
  numOfCancelled,
  reservationsDatetime,
  showReservationAdd,
  execSetArrivalStatus,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
  changeReservationsDate,
  resetReservationsDate,
}: Props) => {
  useEffect(() => {
    return () => {
      resetReservationsDate();
    };
  }, [resetReservationsDate]);

  const subtitle = `Reservierungen: ${numOfReservations} Personen: ${numOfPersons} Storniert: ${numOfCancelled}`;

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Reservierungen"
    >
      <Picker
        label="Reservierungen am"
        value={reservationsDatetime}
        onAccept={(newDate) => changeReservationsDate(newDate)}
        type="date"
        variant="button"
      />
      <Typography variant="subtitle1" align="center">
        {subtitle}
      </Typography>
      <ReservationsListGroup
        title="Nicht angekommen"
        reservations={notArrivedReservations}
        isOnline={isOnline}
        execCancelReservation={execCancelReservation}
        showReservationEdit={showReservationEdit}
        showReservationDetails={showReservationDetails}
        execSetArrivalStatus={execSetArrivalStatus}
        defaultOpen={true}
      />
      <Box mt="1rem">
        <ReservationsListGroup
          title="Angekommen"
          reservations={arrivedReservations}
          isOnline={isOnline}
          execCancelReservation={execCancelReservation}
          showReservationEdit={showReservationEdit}
          showReservationDetails={showReservationDetails}
          execSetArrivalStatus={execSetArrivalStatus}
          defaultOpen={false}
        />
      </Box>
      <Box mt="1rem">
        <AddButton
          onClick={showReservationAdd}
          label="Neue Reservierung"
          disabled={!isOnline}
        />
      </Box>
    </View>
  );
};
