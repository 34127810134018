import { serviceAreaValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { View, NotFoundText } from '@gts-common/client';
import { AddedServiceArea, AddedTable } from '@gts-ft/utils';
import { SERVICE_AREAS_LIST_VIEW } from '../../constants';
import { ServiceAreaForm } from './ServiceAreaForm';

interface Props {
  serviceArea: AddedServiceArea | undefined;
  availableTables: Array<AddedTable>;
  execUpdateServiceArea: (
    serviceAreaId: string,
    serviceArea: Omit<AddedServiceArea, 'serviceAreaId'>,
  ) => void;
  isOnline: boolean;
}

export const ServiceAreaEdit = ({
  serviceArea,
  availableTables,
  execUpdateServiceArea,
  isOnline,
}: Props) => {
  if (!serviceArea) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={SERVICE_AREAS_LIST_VIEW}
        backButtonLabel="Servicebereiche verwalten"
        title="Servicebereich bearbeiten"
      >
        <NotFoundText>
          Der Servicebereich existiert nicht. Bitte überprüfen Sie den Link und
          versuchen Sie es erneut.
        </NotFoundText>
      </View>
    );
  }
  return (
    <View
      hasBackButton={true}
      navigateBackTo={SERVICE_AREAS_LIST_VIEW}
      backButtonLabel="Servicebereiche verwalten"
      title="Servicebereich bearbeiten"
    >
      <Formik
        onSubmit={(values) =>
          execUpdateServiceArea(serviceArea.serviceAreaId, values)
        }
        initialValues={serviceArea}
        validationSchema={serviceAreaValidation}
      >
        <ServiceAreaForm
          realTables={availableTables}
          isEdit={true}
          isOnline={isOnline}
        />
      </Formik>
    </View>
  );
};
