import {
  RESERVATION_CANCEL_PATH,
  ClientCancelReservationResponse,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  execCloseMessage,
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  RESERVATION_CANCELLED,
  ReservationCancelledAction,
  Thunk,
} from '../reduxActionTypes';
import { NavigationTo } from '../../types';
import { execReplace } from '../navigation';
import { updateReservationOfflineDB } from './update';

function cancelReservationSucceeded(
  reservationId: string,
  cancelledAt: string,
): ReservationCancelledAction {
  return {
    type: RESERVATION_CANCELLED,
    payload: { reservationId, cancelledAt },
  };
}

export function execCancelReservation(
  reservationId: string,
  navigateBackTo?: NavigationTo,
): Thunk<Actions> {
  return (dispatch) => {
    dispatch(execCloseMessage());
    dispatch(startServerRequest());
    return serverComm
      .execPatchRequest<ClientCancelReservationResponse>(
        `${RESERVATION_CANCEL_PATH}/${reservationId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(serverRequestSucceeded('Reservierung storniert.'));
            dispatch(
              cancelReservationSucceeded(reservationId, resp.body.cancelledAt),
            );
            void dispatch(
              updateReservationOfflineDB(reservationId, {
                cancelledAt: resp.body.cancelledAt,
              }),
            );
            dispatch(execReplace(navigateBackTo));
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execCancelReservationDecision(
  reservationId: string,
  navigateBackTo?: NavigationTo,
) {
  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: ModalType.DECISION_MODAL,
    title: 'Reservierung stornieren?',
    body:
      'Wollen Sie die Reservierung wirklich stornieren? Diese Aktion kann nicht rückgängig gemacht werden.',
    extraProps: {
      confirmAction: execCancelReservation(reservationId, navigateBackTo),
    },
  };

  return execOpenMessage(modal);
}
