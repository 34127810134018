import { serviceAreaValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { View } from '@gts-common/client';
import { AddedTable, AddServiceAreaRequestBody } from '@gts-ft/utils';
import { SERVICE_AREAS_LIST_VIEW } from '../../constants';
import { ServiceAreaForm } from './ServiceAreaForm';

const initialValues: AddServiceAreaRequestBody = {
  name: '',
  tableIds: [],
};

interface Props {
  availableTables: Array<AddedTable>;
  execAddServiceArea: (serviceArea: AddServiceAreaRequestBody) => void;
  isOnline: boolean;
}

export const ServiceAreaAdd = ({
  availableTables,
  execAddServiceArea,
  isOnline,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      backButtonLabel="Servicebereiche verwalten"
      navigateBackTo={SERVICE_AREAS_LIST_VIEW}
      title="Servicebereich hinzufügen"
    >
      <Formik
        onSubmit={execAddServiceArea}
        initialValues={initialValues}
        validationSchema={serviceAreaValidation}
      >
        <ServiceAreaForm
          realTables={availableTables}
          isEdit={false}
          isOnline={isOnline}
        />
      </Formik>
    </View>
  );
};
