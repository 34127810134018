import {
  Typography,
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core';

import { TableStatus } from '@gts-ft/utils';
import { tableStatusClassesWithoutHover } from '../helpers/tableStatusClassesWithHover';

const styles = (theme: Theme) =>
  createStyles({
    table: {
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',

      boxShadow: theme.shadows[2],

      '&:before': {
        content: `''`,
        display: 'table',
        padding: '45% 0',
      },
    },

    ...tableStatusClassesWithoutHover(),

    tableNoTimePosition: {
      flex: '1 1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    tableContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  });

interface Props
  extends WithStyles<typeof styles & typeof tableStatusClassesWithoutHover> {
  tableNo: string;
  personsNo: number;
  status: TableStatus;
  reservationTime: string | undefined;
}

const UnstyledBookingStatusTable = ({
  tableNo,
  personsNo,
  status,
  reservationTime = '',
  classes,
}: Props) => (
  <div className={`${classes.table} ${classes[`tableStatus${status}`]}`}>
    <div className={classes.tableContent}>
      <div className={classes.tableNoTimePosition}>
        <Typography>
          {tableNo} - {personsNo}P
        </Typography>
      </div>
      <div className={classes.tableNoTimePosition}>
        <Typography>{reservationTime}</Typography>
      </div>
    </div>
  </div>
);

export const BookingStatusTable = withStyles(styles)(
  UnstyledBookingStatusTable,
);
