import { Grid } from '@material-ui/core';
import { FieldArray, Form } from 'formik';
import { TableAdd } from '../../../types';
import { Table } from './Table';

interface Props {
  tables: Array<TableAdd>;
  removeTable: (index: number) => void;
}

export const multiTablesFormId = 'multi-tables-form';

export const TablesList = ({ tables, removeTable }: Props) => {
  return (
    <Form id={multiTablesFormId} noValidate={true}>
      <Grid container spacing={2}>
        <FieldArray name="tables">
          {() =>
            tables.map((table, index) => {
              return (
                <Grid xs={12} sm={6} item key={table.tableNo}>
                  <Table
                    tableNo={table.tableNo}
                    name={`tables.${index}`}
                    remove={() => removeTable(index)}
                  />
                </Grid>
              );
            })
          }
        </FieldArray>
      </Grid>
    </Form>
  );
};
