import { isAfter, subMinutes } from 'date-fns';
import { getMinutesFromDecimal } from '@gts-ft/utils';
import { ReservationStatus } from '../../../constants';
import { ClientReservation } from '../../../types';

export function getReservationStatus(
  reservation: ClientReservation,
  datetime: Date,
  tableStatusReservedTime: number,
) {
  if (reservation.cancelledAt !== null) {
    return ReservationStatus.CANCELLED;
  }

  if (reservation.arrived) {
    return ReservationStatus.NOT_UPCOMING;
  }

  // Add 1 minute so that time now 10:00 with status reserved time
  // 30 minutes considers reservations for 10:15 and 10:30 as upcoming
  const tableStatusReservedMinutes =
    getMinutesFromDecimal(tableStatusReservedTime) + 1;

  if (isAfter(datetime, reservation.datetime)) {
    return ReservationStatus.OVERDUE;
  } else if (
    isAfter(
      datetime,
      subMinutes(reservation.datetime, tableStatusReservedMinutes),
    )
  ) {
    return ReservationStatus.UPCOMING;
  } else {
    return ReservationStatus.NOT_UPCOMING;
  }
}
