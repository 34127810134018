import { Typography, Card, CardContent } from '@material-ui/core';

interface Props {
  url: string;
}

export const WidgetLink = ({ url }: Props) => (
  <Card style={{ marginBottom: '30px' }}>
    <CardContent>
      <Typography variant="subtitle1">
        Link zum Reservierungsformular
      </Typography>
      <Typography variant="subtitle1">
        Diesen Link können Sie z.B. auf Ihre Webseite, Google Place oder soziale
        Medien veröffentlichen. Der Link sollte prominent platziert werden,
        damit Ihre Gäste ihn auch sofort wahrnehmen. Auf Ihrer Webseite sollte
        der Link als Button sofort sichtbar sein, sobald Ihr Gast die Webseite
        aufruft.
      </Typography>
      <Typography variant="body2">
        <a
          style={{ color: 'black' }}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {url}
        </a>
      </Typography>
    </CardContent>
  </Card>
);
