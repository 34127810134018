export const quarterHours = 'Erlaubt sind nur viertel, halbe und volle Stunden';
export const minPersonsSmallerEqualPersons = 'Die Mindestbelegung darf nicht größer sein als die Personenanzahl';
export const manualTableAllocationValidationMessage = 'Manuelle Tischzuweisung aktiviert. Wählen Sie mindestens einen Tisch';
export const widgetInactiveMessage = 'Reservierungen über das Reservierungsformular sind momentan nicht erlaubt.';
export const serverStatusErrors = {
    1000: {
        client: 'Keine Tische verfügbar.',
        widget: 'Es sind für die ausgewählte Uhrzeit keine Tische verfügbar. Bitte wählen Sie eine andere Uhrzeit oder auch ein anderes Datum aus.',
    },
    1001: 'Der Tisch ist reserviert.',
    1002: 'Personenanzahl zu klein für die Reservierungen.',
    1003: 'Diese E-Mail-Adresse ist gesperrt und kann für eine Reservierung nicht verwendet werden.',
    1004: 'Mindestbelegung nicht erfüllt.',
    1005: 'Gewählter Tisch nicht verfügbar.',
    1006: 'Der Tisch ist einem virtuellen Tisch zugeordnet und kann daher nicht gelöscht werden.',
    1007: widgetInactiveMessage,
    1008: 'Der Tisch ist belegt. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    1009: 'Der Tisch hat eine frühere Reservierung. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    1010: 'Der Tisch hat eine Reservierung. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    1011: 'Der Tisch hat eine frühere Folge-Reservierung. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    1012: 'Der Tisch hat eine Folge-Reservierung. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
    1013: 'Die Reservierung kann so kurzfristig nicht storniert werden. Kontaktieren Sie bitte direkt den Gastronomen.',
    1014: 'Der Link ist abgelaufen. Bitte melden Sie sich erneut an.',
};
