import { compareDesc } from 'date-fns';
import { ClientReservation } from '../../../types';

export function sortReservationsByTime<T>(
  reservations: Array<ClientReservation & T>,
  order: 'ASC' | 'DESC' = 'ASC',
): Array<ClientReservation & T> {
  const sortedReservations = [...reservations];

  sortedReservations.sort((a, b) => {
    if (order === 'DESC') {
      return compareDesc(a.datetime, b.datetime);
    } else {
      return compareDesc(b.datetime, a.datetime);
    }
  });

  return sortedReservations;
}
