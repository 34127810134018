import { Box, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { tableValidation } from '@gts-ft/ui';
import { useRef, useState } from 'react';
import * as yup from 'yup';
import { AddedTable, AddRealTableRequestBody } from '@gts-ft/utils';
import { NavButtons } from '../NavButtons';
import {
  Actions,
  SETUP_WIZARD_NEXT_STEP,
  SETUP_WIZARD_PREVIOUS_STEP,
} from '../../../actions/reduxActionTypes';
import { TableForm } from './TableForm';
import { multiTablesFormId, TablesList } from './TablesList';

const initialValues = {
  tableNo: '',
  personsNo: 4,
  minPersons: 2,
  visibleInWidget: true,
};

interface Props {
  execBulkAddTables: (
    tables: Array<AddRealTableRequestBody>,
    action: Actions,
  ) => void;
  tables: Array<
    Pick<AddedTable, 'tableNo' | 'personsNo' | 'minPersons' | 'visibleInWidget'>
  >;
}

export const TableStep = ({
  tables: initialTables,
  execBulkAddTables,
}: Props) => {
  const [tables, setTables] = useState<Array<typeof initialValues>>(
    initialTables,
  );
  const actionRef = useRef<
    typeof SETUP_WIZARD_NEXT_STEP | typeof SETUP_WIZARD_PREVIOUS_STEP
  >(SETUP_WIZARD_NEXT_STEP);

  return (
    <>
      <Typography gutterBottom={true} align="justify">
        Hier kann ich meine Tische initial für mein Restaurant anlegen. Die hier
        angelegten Tische kann ich auch zu einem späteren Zeitpunkt anpassen.
        Alternativ kann ich auch die Tische zu einem späteren Zeitpunkt anlegen.
      </Typography>

      <Formik
        onSubmit={(values, { resetForm }) => {
          setTables([...tables, values]);
          resetForm();
        }}
        initialValues={initialValues}
        validationSchema={tableValidation}
        validate={({ tableNo }) => {
          if (tables.find((table) => table.tableNo === tableNo)) {
            return { tableNo: 'Die Tischnummer existiert bereits.' };
          }
        }}
      >
        <TableForm />
      </Formik>
      <Formik
        onSubmit={({ tables }) => {
          execBulkAddTables(tables, { type: actionRef.current });
          // Reset the ref for the next time.
          // Needed in case the user clicks on then back button but
          // the server call fails and we stay in this component.
          actionRef.current = SETUP_WIZARD_NEXT_STEP;
        }}
        initialValues={{ tables }}
        enableReinitialize
        validationSchema={yup.object({
          tables: yup.array().of(tableValidation),
        })}
        validateOnChange
      >
        {({ submitForm, values }) => {
          // Use the value supplied by formik and not the tables value directly to avoid
          // unnecessary re-rendering and to avoid having undefined as initial values for
          // the form controls
          const formikTablesValue = values.tables;
          return (
            <>
              {formikTablesValue.length > 0 && (
                <Box mt="1rem" mb="1rem">
                  <Typography component="h3" variant="h6" align="center">
                    Hinzugefügte Tische
                  </Typography>
                </Box>
              )}
              {formikTablesValue.length > 0 && (
                <TablesList
                  tables={formikTablesValue}
                  removeTable={(index: number) =>
                    setTables(
                      formikTablesValue.filter((_table, i) => i !== index),
                    )
                  }
                />
              )}
              <NavButtons
                backClicked={() => {
                  actionRef.current = SETUP_WIZARD_PREVIOUS_STEP;
                  void submitForm();
                }}
                submitForForm={multiTablesFormId}
                isDisabled={formikTablesValue.length === 0}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};
