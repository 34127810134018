import { Typography } from '@material-ui/core';
import { NotReservedTable, TableWithExtraProperties } from '../../types';

interface OccupiedTableReservationTimesProps {
  table: TableWithExtraProperties | NotReservedTable;
}

export const OccupiedTableReservationTimes = ({
  table,
}: OccupiedTableReservationTimesProps) => {
  const firstReservationText = `von ${table.firstReservationTime} bis ${table.firstReservedUntilTime}`;
  const secondReservationText = table.secondReservationTime
    ? `von ${table.secondReservationTime} bis ${table.secondReservedUntilTime}`
    : undefined;
  return (
    <>
      <Typography variant="body2">{firstReservationText}</Typography>
      <Typography variant="body2">{secondReservationText}</Typography>
    </>
  );
};
