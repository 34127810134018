import {
  LOGIN_PATH,
  LOGOUT_PATH,
  LoginResponse,
  LoginCheckRequestQuery,
  LoginRequestBody,
  okFailedResponseBodies,
} from '@gts-ft/utils';

import { getClientOperationFailureReason, serverComm } from '@gts-ft/ui';
import {
  getUrl,
  SERVER_DATE_FORMAT,
  ServiceCode,
  UrlType,
  ServerResponseError,
  ServerResponseErrorType,
  IndexSignatureHack,
} from '@gts-common/client-server';
import { format } from 'date-fns';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { db } from '../db';
import { InitialDataLoad } from '../types';
import { SETUP_WIZARD_VIEW } from '../constants';
import {
  Actions,
  Thunk,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LoginCheckSucceededAction,
  LoginSucceededAction,
  LOGOUT_SUCCEEDED,
  LogoutSucceededAction,
  LOGIN_CHECK_FAILED,
  LoginCheckFailedAction,
  LOGIN_FAILED,
  LoginFailedAction,
} from './reduxActionTypes';

import { startPollingReservations } from './reservations';
import { startLiveDate } from './liveDatetime';
import { saveDataInOfflineStorage } from './helpers/saveDataInOfflineStorage';
import { prepareLoginResponseForClient } from './helpers/prepareLoginResponseForClient';
import { execReplace } from './navigation';

export function loginCheckSucceeded(
  data: InitialDataLoad,
): LoginCheckSucceededAction {
  return {
    type: LOGIN_CHECK_SUCCEEDED,
    payload: data,
  };
}

export function loginCheckFailed(): LoginCheckFailedAction {
  return {
    type: LOGIN_CHECK_FAILED,
  };
}

export function loginSucceeded(data: InitialDataLoad): LoginSucceededAction {
  return {
    type: LOGIN_SUCCEEDED,
    payload: data,
  };
}

export function loginFailed(): LoginFailedAction {
  return {
    type: LOGIN_FAILED,
  };
}

export function logoutSucceeded(): LogoutSucceededAction {
  return {
    type: LOGOUT_SUCCEEDED,
  };
}

function checkLoggedIn(): Thunk<Actions> {
  return (dispatch, getState) => {
    dispatch(startServerRequest());

    const earliestLoadedDate = getState().reservations.earliestLoadedDate;
    const earliestDateToLoad = format(earliestLoadedDate, SERVER_DATE_FORMAT);
    const query: LoginCheckRequestQuery = { earliestDateToLoad };

    return serverComm
      .execGetRequest<
        LoginResponse,
        IndexSignatureHack<LoginCheckRequestQuery>
      >(LOGIN_PATH, query)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(
              saveDataInOfflineStorage(
                resp.body.tables,
                resp.body.reservations,
                resp.body.restaurant,
                resp.body.serviceAreas,
              ),
            );
            const preparedLoginData = prepareLoginResponseForClient(
              resp.body.setupCompleted,
              resp.body.tables,
              resp.body.reservations,
              resp.body.restaurant,
              resp.body.serviceAreas,
            );

            dispatch(loginCheckSucceeded(preparedLoginData));
            dispatch(serverRequestSucceeded());

            if (!resp.body.setupCompleted) {
              dispatch(execReplace(SETUP_WIZARD_VIEW));
            }

            startPollingReservations(dispatch);
            startLiveDate(dispatch);
          } else {
            window.location.href = `${getUrl(
              ServiceCode.SERVICE_MANAGER,
              UrlType.APP,
            )}/login`;
          }
        },
        (e: unknown) => {
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.UNAUTHORIZED) {
              window.location.href = `${getUrl(
                ServiceCode.SERVICE_MANAGER,
                UrlType.APP,
              )}/login`;
              return;
            }
          }
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
          dispatch(loginCheckFailed());
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

function execLogin(ticket: string): Thunk<Actions> {
  return (dispatch, getState) => {
    dispatch(startServerRequest());

    const earliestLoadedDate = getState().reservations.earliestLoadedDate;
    const earliestDateToLoad = format(earliestLoadedDate, SERVER_DATE_FORMAT);

    const dataToSend: LoginRequestBody = {
      ticket,
      earliestDateToLoad,
    };

    return serverComm
      .execPostRequest<LoginResponse, LoginRequestBody>(LOGIN_PATH, dataToSend)
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(
              saveDataInOfflineStorage(
                resp.body.tables,
                resp.body.reservations,
                resp.body.restaurant,
                resp.body.serviceAreas,
              ),
            );
            const preparedLoginData = prepareLoginResponseForClient(
              resp.body.setupCompleted,
              resp.body.tables,
              resp.body.reservations,
              resp.body.restaurant,
              resp.body.serviceAreas,
            );

            dispatch(loginSucceeded(preparedLoginData));
            dispatch(serverRequestSucceeded());

            if (!resp.body.setupCompleted) {
              dispatch(execReplace(SETUP_WIZARD_VIEW));
            } else {
              // clear ticket from url
              window.history.replaceState({}, document.title, '/');
            }

            startPollingReservations(dispatch);
            startLiveDate(dispatch);
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
            dispatch(loginFailed());
          }
        },
        (e: unknown) => {
          // If the validation fails (ticket has the wrong format) tell the user to get a new one
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.BAD_REQUEST) {
              dispatch(
                serverRequestFailed(
                  getClientOperationFailureReason({
                    succeeded: false,
                    body: okFailedResponseBodies.LOGIN_TICKET_EXPIRED,
                  }),
                ),
              );
              dispatch(loginFailed());
              return;
            }
          }

          dispatch(serverRequestFailed(getErrorModalMessage(e)));
          dispatch(loginFailed());
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execResolveLogin(): Thunk<Actions> {
  const ticket = getQueryParameterByName('ticket');
  return (dispatch) => {
    dispatch(ticket ? execLogin(ticket) : checkLoggedIn());
  };
}

export function execLogout(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());
    return serverComm
      .execPostRequest(LOGOUT_PATH)
      .then(
        (resp) => {
          void db.deleteDb();

          if (resp.succeeded === true) {
            dispatch(logoutSucceeded());
            window.location.href = `${getUrl(
              ServiceCode.SERVICE_MANAGER,
              UrlType.APP,
            )}/login`;
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
