"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.okFailedResponseBodies = void 0;
exports.okFailedResponseBodies = {
    NO_TABLE_FOUND: {
        status: 1000,
        reason: 'No table found',
    },
    TABLE_HAS_RESERVATIONS: {
        status: 1001,
        reason: 'Table has reservations',
    },
    TABLE_HAS_RESERVATIONS_WITH_MORE_PERSONS: {
        status: 1002,
        reason: 'Table has reservation with more persons',
    },
    EMAIL_LOCKED: {
        status: 1003,
        reason: 'This email is locked and can not be used to book a table using the widget',
    },
    NO_TABLE_FOUND_WITH_MIN_PERSONS: {
        status: 1004,
        reason: 'No table found when considering minPersons, but table found when not considering it',
    },
    TABLE_NOT_AVAILABLE: {
        status: 1005,
        reason: 'Table not available',
    },
    TABLE_HAS_VIRTUAL_TABLE: {
        status: 1006,
        reason: 'Table is assigned to a virtual table',
    },
    WIDGET_NOT_ACTIVE: {
        status: 1007,
        reason: 'The reservation widget is not active',
    },
    WALK_IN_TABLE_IS_OCCUPIED: {
        status: 1008,
        reason: 'Table is occupied',
    },
    WALK_IN_FIRST_RESERVATION_IS_EARLIER: {
        status: 1009,
        reason: 'First reservation earlier',
    },
    WALK_IN_FIRST_RESERVATION_EXISTS: {
        status: 1010,
        reason: 'First reservation exists',
    },
    WALK_IN_SECOND_RESERVATION_IS_EARLIER: {
        status: 1011,
        reason: 'Second reservation earlier',
    },
    WALK_IN_SECOND_RESERVATION_EXISTS: {
        status: 1012,
        reason: 'Second reservation exists',
    },
    CANT_CANCEL_SO_CLOSE_TO_RES_TIME: {
        status: 1013,
        reason: 'Cannot cancel reservation so close to reservation time',
    },
    LOGIN_TICKET_EXPIRED: {
        status: 1014,
        reason: 'Login ticket expired',
    },
};
