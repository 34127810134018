import { getTimeSelectOptions } from '@gts-ft/ui';
import { SelectField, AddButton } from '@gts-common/client';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { getWeekDayFromDecimal, TIME_STEP } from '@gts-ft/utils';
import { Box, Grid } from '@material-ui/core';
import { RestaurantReservationTimesState } from '../../../types';
import { getDecimalTimeListAndStarts } from '../helpers/getDecimalTimeListAndStarts';

const MAX_INTERVAL_LENGTH = 24 - TIME_STEP; //In decimal hours

const startTimeOptions = (timeList: Array<number>, startDay: number) => {
  return getTimeSelectOptions(
    timeList.filter((decimal) => getWeekDayFromDecimal(decimal) === startDay),
  );
};

const endTimeOptions = (
  timeList: Array<number>,
  startTime: number,
  starts: Array<number>,
) => {
  const filtered = starts.filter((start) => start >= startTime);
  let nextStart: number;
  let lastTime: number;
  if (startTime + MAX_INTERVAL_LENGTH >= 7 * 24) {
    // either first after start time or first of the week (startTime on sunday => relevant start on monday)
    nextStart = filtered[0] || Math.min(...starts);
    lastTime = Math.min(
      (startTime + MAX_INTERVAL_LENGTH) % (7 * 24),
      nextStart,
    );
  } else {
    nextStart = filtered[0] || Infinity;
    lastTime = Math.min(startTime + MAX_INTERVAL_LENGTH, nextStart);
  }
  if (lastTime < startTime) {
    return getTimeSelectOptions([
      ...timeList.filter((decimal) => decimal >= startTime),
      ...timeList.filter((decimal) => decimal <= lastTime),
    ]);
  } else {
    return getTimeSelectOptions(
      timeList.filter((decimal) => decimal >= startTime && decimal <= lastTime),
    );
  }
};

export const dayLabels = [
  { value: 0, label: 'Montag' },
  { value: 1, label: 'Dienstag' },
  { value: 2, label: 'Mittwoch' },
  { value: 3, label: 'Donnerstag' },
  { value: 4, label: 'Freitag' },
  { value: 5, label: 'Samstag' },
  { value: 6, label: 'Sonntag' },
];

interface Props {
  reservationHours: RestaurantReservationTimesState['reservationHours'];
}

const formId = 'reservation-hours-form';

export const AddReservationHours = ({ reservationHours }: Props) => {
  const { values } = useFormikContext<{
    startDay: number;
    startTime: number;
    endTime: number;
  }>();
  const [{ starts, timeList }, setTimeList] = useState<{
    starts: Array<number>;
    timeList: Array<number>;
  }>({ starts: [], timeList: [] });

  useEffect(() => setTimeList(getDecimalTimeListAndStarts(reservationHours)), [
    reservationHours,
  ]);

  return (
    <Form id={formId} noValidate={true}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectField
            label="Tag"
            options={dayLabels}
            name="startDay"
            required
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectField
            label="Startzeit"
            options={startTimeOptions(timeList, values.startDay)}
            name="startTime"
            required
            disabled={values.startDay === undefined}
            noResultsText="Keine Zeiten verfügbar"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectField
            label="Endzeit"
            options={endTimeOptions(timeList, values.startTime, starts)}
            name="endTime"
            required
            disabled={values.startTime === undefined}
          />
        </Grid>
      </Grid>
      <Box mt="1rem" textAlign="center">
        <AddButton submitForForm={formId} />
      </Box>
    </Form>
  );
};
