import { RestaurantSettingsState } from '../../../types';
import { NavButtons } from '../NavButtons';
import { WidgetSettings, widgetSettingsFormId } from './WidgetSettings';

interface Props {
  settings: RestaurantSettingsState;
  execRestaurantSettingsChange: (settings: RestaurantSettingsState) => void;
}

export const WidgetStep = ({
  settings,
  execRestaurantSettingsChange,
}: Props) => {
  return (
    <>
      <WidgetSettings
        settings={settings}
        execRestaurantSettingsChange={execRestaurantSettingsChange}
      />
      <NavButtons
        showBackBtn={false}
        submitForForm={widgetSettingsFormId}
      ></NavButtons>
    </>
  );
};
