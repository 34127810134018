import { initialState } from '../initialState';
import {
  Actions,
  EARLIEST_LOADED_DATE_CHANGED,
  GET_RESERVATIONS_SUCCEEDED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  RESERVATION_ADDED,
  RESERVATION_CANCELLED,
  RESERVATION_DELETED,
  RESERVATION_UPDATED,
  SET_ARRIVAL_STATUS_SUCCEEDED,
  RESERVATIONS_DATE_CHANGED,
} from '../actions/reduxActionTypes';
import { ReservationsState } from '../types';

export function reservationsReducer(
  state = initialState.reservations,
  action: Actions,
): ReservationsState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload.reservations,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        ...action.payload.reservations,
      };

    // Reset all data
    case LOGOUT_SUCCEEDED:
      return initialState.reservations;

    case GET_RESERVATIONS_SUCCEEDED: {
      // Only add new items so that we don't change the references of the existing items
      // changing the refs might cause issues if the user is updating the reservation at the time we receive new
      // reservations.
      const stateItems = state.items;
      const stateEntities = state.entities;

      const { newItems, changedItems } = action.payload.items.reduce<{
        newItems: Array<string>;
        changedItems: Array<string>;
      }>(
        (items, reservationId) => {
          if (!stateItems.includes(reservationId)) {
            return { ...items, newItems: [...items.newItems, reservationId] };
          } else {
            return JSON.stringify(action.payload.entities[reservationId]) !==
              JSON.stringify(stateEntities[reservationId])
              ? {
                  ...items,
                  changedItems: [...items.changedItems, reservationId],
                }
              : items;
          }
        },
        { newItems: [], changedItems: [] },
      );

      const newEntities = [...newItems, ...changedItems].reduce(
        (entities, reservationId) => ({
          ...entities,
          [reservationId]: action.payload.entities[reservationId],
        }),
        {},
      );

      return {
        ...state,
        items: [...stateItems, ...newItems],
        entities: { ...stateEntities, ...newEntities },
      };
    }

    case RESERVATION_ADDED:
      return {
        ...state,
        items: [...state.items, ...action.payload.items],
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
      };

    case RESERVATION_DELETED:
      return {
        ...state,
        items: state.items.filter(
          (reservationId) => reservationId !== action.payload,
        ),
      };

    case SET_ARRIVAL_STATUS_SUCCEEDED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.reservationId]: {
            ...state.entities[action.payload.reservationId],
            arrived: action.payload.arrived,
          },
        },
      };

    case RESERVATION_UPDATED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.reservationId]: {
            ...state.entities[action.payload.reservationId],
            ...action.payload.reservation,
          },
        },
      };

    case RESERVATION_CANCELLED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.reservationId]: {
            ...state.entities[action.payload.reservationId],
            cancelledAt: action.payload.cancelledAt,
          },
        },
      };

    case EARLIEST_LOADED_DATE_CHANGED: {
      const newItems = action.payload.items.filter(
        (reservationId) => !state.items.includes(reservationId),
      );
      const newEntities = newItems.reduce(
        (entities, reservationId) => ({
          ...entities,
          [reservationId]: action.payload.entities[reservationId],
        }),
        {},
      );

      return {
        ...state,
        earliestLoadedDate: action.payload.loadedDate,
        items: [...state.items, ...newItems],
        entities: { ...state.entities, ...newEntities },
      };
    }

    case RESERVATIONS_DATE_CHANGED: {
      return {
        ...state,
        reservationsDatetime: action.payload,
      };
    }

    default:
      return state;
  }
}
