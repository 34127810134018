// makes sure that if partial Reservation includes either
// tableIds or manualTableAllocation, that the other one is added as well
import { ReservationFormData } from '@gts-ft/ui';
import { AddedReservation } from '@gts-ft/utils';

export function pairTableIds(
  partialReservation: Partial<ReservationFormData>,
  originalReservation: AddedReservation,
) {
  const hasTableId = partialReservation.tableIds !== undefined;
  const hasManTabAlloc = partialReservation.manualTableAllocation !== undefined;

  if (!hasTableId && hasManTabAlloc) {
    return Object.assign({}, partialReservation, {
      tableIds: originalReservation.tableIds,
    });
  }
  if (hasTableId && !hasManTabAlloc) {
    return Object.assign({}, partialReservation, {
      manualTableAllocation: originalReservation.manualTableAllocation,
    });
  }
  return partialReservation;
}
