import { compareDesc } from 'date-fns';
import { TableWithExtraProperties, WalkInTableGroup } from '../../../types';

export function prepareWalkInTableGroups(
  timeGroupedFreeTables: Record<string, Array<TableWithExtraProperties>>,
) {
  const groupedFreeTables = Object.keys(timeGroupedFreeTables).reduce<
    Array<WalkInTableGroup>
  >((groups, time) => {
    const tables = timeGroupedFreeTables[time];

    return [
      ...groups,
      {
        time,
        tables,
      },
    ];
  }, []);

  groupedFreeTables.sort((group1, group2) => {
    return compareDesc(
      group1.tables[0].firstReservationDatetime,
      group2.tables[0].firstReservationDatetime,
    );
  });

  return groupedFreeTables;
}
