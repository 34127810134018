import { Normalized } from '@gts-common/client';
import {
  GET_RESERVATIONS_SUCCEEDED,
  GetReservationsSucceededAction,
} from '../../reduxActionTypes';
import { ClientReservation } from '../../../types';

export function getReservationsSucceeded(
  data: Normalized<ClientReservation>,
): GetReservationsSucceededAction {
  return {
    type: GET_RESERVATIONS_SUCCEEDED,
    payload: data,
  };
}
