import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { replace } from 'connected-react-router';
import { ReduxState, RestaurantReservationTimesState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { RestaurantOpeningTimes } from '../components/RestaurantOpeningTimes/RestaurantOpeningTimes';
import { execRestaurantOpeningTimesChange } from '../actions/restaurant';
import { HOME_VIEW } from '../constants';

const mapStateToProps = (state: ReduxState) => {
  return {
    isOnline: state.app.isOnline,
    openingTimes: state.restaurant.openingTimes,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execRestaurantOpeningTimesChange(
    restaurantReservationTimesObject: RestaurantReservationTimesState,
  ) {
    dispatch(
      execRestaurantOpeningTimesChange(
        restaurantReservationTimesObject,
        replace(HOME_VIEW),
      ),
    );
  },
});

export const RestaurantReservationTimesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestaurantOpeningTimes);
