import { combineReducers } from 'redux';
import { commonStateReducer } from '@gts-common/client';

import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { appReducer } from './app';
import { reservationsReducer } from './reservations';
import { tablesReducer } from './tables';
import { serviceAreasReducer } from './serviceAreas';
import { restaurantReducer } from './restaurant';
import { setupWizardReducer } from './setupWizard';

export const rootReducer = (history: History) =>
  combineReducers({
    app: appReducer,
    reservations: reservationsReducer,
    tables: tablesReducer,
    serviceAreas: serviceAreasReducer,
    restaurant: restaurantReducer,
    setupWizard: setupWizardReducer,
    common: commonStateReducer,
    router: connectRouter(history),
  });
