import { createDecimalTimeListInRange, TIME_STEP } from '@gts-ft/utils';
import { RestaurantReservationTimesState } from '../../../types';

export function getDecimalTimeListAndStarts(
  reservationHours: RestaurantReservationTimesState['reservationHours'],
) {
  const starts: Array<number> = [];
  const intervals = reservationHours.reduce<
    Array<{ start: number; end: number }>
  >((prev, { startTime, endTime }) => {
    const start = startTime;
    const end = endTime;

    starts.push(start);

    if (start <= end) {
      return [...prev, { start, end }];
    } else {
      return [...prev, { start: 0, end }, { start, end: 7 * 24 - TIME_STEP }];
    }
  }, []);

  const sortedIntervals = intervals.sort((a, b) => {
    if (a.start < b.start || (a.start === b.start && a.end <= b.end)) {
      return -1;
    }
    return 1;
  });

  const { nextStart, freeIntervals } = sortedIntervals.reduce<{
    nextStart: number;
    freeIntervals: Array<{ start: number; end: number }>;
  }>(
    ({ nextStart, freeIntervals }, interval) => {
      if (nextStart >= interval.start) {
        return { nextStart: interval.end + TIME_STEP, freeIntervals };
      } else {
        return {
          nextStart: interval.end + TIME_STEP,
          freeIntervals: [
            ...freeIntervals,
            { start: nextStart, end: interval.start },
          ],
        };
      }
    },
    { nextStart: 0, freeIntervals: [] },
  );

  const completeIntervals = [
    ...freeIntervals,
    { start: nextStart, end: 7 * 24 - TIME_STEP },
  ];

  const decimalTimeList = completeIntervals.reduce<Array<number>>(
    (prev, { start, end }) => [
      ...prev,
      ...createDecimalTimeListInRange(start, end),
    ],

    [],
  );

  return {
    starts,
    timeList: decimalTimeList,
  };
}
