import { convertLocalDateStringToServerDateString, } from '@gts-ft/utils';
import { serverComm } from './serverComm';
export function getFreeTables({ personsNo, date, time, reservationId, errorHandler, serverPath, additionalQueryParams, }) {
    const paramsToSend = Object.assign(Object.assign({}, additionalQueryParams), { personsNo, date: convertLocalDateStringToServerDateString(date), time,
        reservationId });
    return serverComm
        .execGetRequest(serverPath, paramsToSend)
        .then((resp) => {
        if (resp.succeeded) {
            return resp.body;
        }
        return [];
    })
        .catch((e) => {
        errorHandler(e);
        return [];
    });
}
