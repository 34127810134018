import { restaurantSettingsValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { View } from '@gts-common/client';
import { RestaurantSettingsState } from '../../types';
import { HOME_VIEW } from '../../constants';
import { FileUploaderAction } from '../UI-Components';
import { RestaurantSettingsForm } from './RestaurantSettingsForm';
import { WidgetLink } from './subforms';

interface Props {
  execRestaurantSettingsChange: (
    restaurantSettings: RestaurantSettingsState,
  ) => void;
  isOnline: boolean;
  widgetUrl: string;
  settings: RestaurantSettingsState;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
}

export const RestaurantSettings = ({
  settings,
  execRestaurantSettingsChange,
  isOnline,
  widgetUrl,
  execDataLoadFailed,
  execUpdateLogoFilename,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Einstellungen"
    >
      <Formik
        onSubmit={execRestaurantSettingsChange}
        initialValues={settings}
        validationSchema={restaurantSettingsValidation}
      >
        <>
          <WidgetLink url={widgetUrl} />
          <RestaurantSettingsForm
            isOnline={isOnline}
            logoFilename={settings.logoFilename}
            execDataLoadFailed={execDataLoadFailed}
            execUpdateLogoFilename={execUpdateLogoFilename}
          />
        </>
      </Formik>
    </View>
  );
};
