import { ClientReservationWithExtraProperties } from '../../../types';

export function splitIntoArrivedAndNotArrived(
  reservations: Array<ClientReservationWithExtraProperties>,
) {
  return reservations.reduce<{
    arrivedReservations: Array<ClientReservationWithExtraProperties>;
    notArrivedReservations: Array<ClientReservationWithExtraProperties>;
  }>(
    (prev, reservation) => {
      if (reservation.arrived) {
        return {
          ...prev,
          arrivedReservations: [...prev.arrivedReservations, reservation],
        };
      } else {
        return {
          ...prev,
          notArrivedReservations: [...prev.notArrivedReservations, reservation],
        };
      }
    },
    {
      arrivedReservations: [],
      notArrivedReservations: [],
    },
  );
}
