import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { CheckboxField, SelectField } from '@gts-common/client';
import ClearIcon from '@material-ui/icons/Clear';
import { getPersonOptions } from './helpers/getPersonOptions';

const useStyles = makeStyles(() => {
  return {
    root: {
      overflow: 'initial',
    },
  };
});

interface Props {
  name: string;
  remove: () => void;
  tableNo: string;
}

export const Table = ({ name, tableNo, remove }: Props) => {
  const classes = useStyles();
  return (
    <Card classes={classes} variant="outlined">
      <CardHeader
        action={
          <IconButton onClick={remove}>
            <ClearIcon />
          </IconButton>
        }
        title={'Nr. ' + tableNo}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4} item>
            <SelectField
              name={`${name}.personsNo`}
              label="Personen"
              options={getPersonOptions()}
              required
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <SelectField
              name={`${name}.minPersons`}
              label="Mindestbelegung"
              options={getPersonOptions()}
              required
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <CheckboxField
              type="checkbox"
              name={`${name}.visibleInWidget`}
              required
            >
              durch Gast reservierbar
            </CheckboxField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
