import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RESTAURANT_LOGO_PATH } from '@gts-ft/utils';
import { FileUploader, FileUploaderAction } from '../../UI-Components';

interface Props {
  logoFilename: string | null;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
}

export const WidgetLogo = ({
  logoFilename,
  execDataLoadFailed,
  execUpdateLogoFilename,
}: Props) => {
  const fileIds = logoFilename === null ? [] : [logoFilename];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Logo</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Need the Box here because AccordionDetails is a flex container
      this will change in the future https://github.com/mui-org/material-ui/pull/22809 */}
        <Box width="100%">
          <Typography variant="body2">
            Hier können Sie Ihr Logo hochladen. Es wird im Reservierungsformular
            angezeigt. Das Logo sollte nicht höher als 150 pixel sein. PNG und
            JPG sind als Bildformat erlaubt.
          </Typography>
          <FileUploader
            path={RESTAURANT_LOGO_PATH}
            fileIds={fileIds}
            onFailure={(_action, reason) => execDataLoadFailed(reason)}
            onSuccess={execUpdateLogoFilename}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
