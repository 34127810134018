import { AddedTable } from '@gts-ft/utils';

function getRealTables(
  tableIds: Array<string>,
  allTables: Record<string, AddedTable>,
) {
  return tableIds.map((tableId) => allTables[tableId]);
}

function getVirtualTables(
  tableId: string,
  allTables: Record<string, AddedTable>,
) {
  return Object.keys(allTables)
    .filter(
      (key) =>
        allTables[key].isVirtual && allTables[key].tableIds.includes(tableId),
    )
    .map((key) => allTables[key]);
}

export function getAssociatedTables(
  table: AddedTable,
  allTables: Record<string, AddedTable>,
) {
  return table.isVirtual
    ? getRealTables(table.tableIds, allTables)
    : getVirtualTables(table.tableId, allTables);
}
