import { InfoText, View } from '@gts-common/client';
import {
  NavigationCameFrom,
  NotReservedTable,
  TableWithExtraProperties,
  WalkInTableGroup as WalkInTableGroupType,
} from '../../../types';
import { WalkInTableGroup } from './WalkInTableGroup';

interface Props {
  groupedFreeTables: Array<WalkInTableGroupType>;
  notReservedTables: Array<NotReservedTable>;
  occupiedTables: Array<TableWithExtraProperties>;
  cameFrom: NavigationCameFrom;
  isOnline: boolean;
  walkInClicked: (table: TableWithExtraProperties | NotReservedTable) => void;
}

export const WalkInTablesView = ({
  groupedFreeTables,
  notReservedTables,
  occupiedTables,
  walkInClicked,
  cameFrom,
  isOnline,
}: Props) => {
  const noTablesAvailable =
    groupedFreeTables.length === 0 &&
    notReservedTables.length === 0 &&
    occupiedTables.length === 0;

  return (
    <View
      hasBackButton={true}
      navigateBackTo={cameFrom.to}
      backButtonLabel={cameFrom.backButtonLabel}
      title="Walk In"
    >
      {noTablesAvailable && (
        <InfoText>
          Es gibt keine Tische für die ausgewählte Personenanzahl.
        </InfoText>
      )}
      {notReservedTables.length > 0 && (
        <WalkInTableGroup
          title="Freie Tische"
          tables={notReservedTables}
          tableClicked={walkInClicked}
          isOnline={isOnline}
        />
      )}
      {groupedFreeTables.map((group) => {
        return (
          <WalkInTableGroup
            key={group.time}
            title={`Bis ${group.time} frei`}
            tables={group.tables}
            tableClicked={walkInClicked}
            isOnline={isOnline}
          />
        );
      })}
      {occupiedTables.length > 0 && (
        <WalkInTableGroup
          title="Belegte Tische"
          tables={occupiedTables}
          tableClicked={walkInClicked}
          isOnline={isOnline}
        />
      )}
    </View>
  );
};
