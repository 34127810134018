import { AddedTable, AddRealTableRequestBody } from '@gts-ft/utils';
import {
  RestaurantReservationTimesState,
  RestaurantSettingsState,
} from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { FileUploaderAction } from '../UI-Components';
import { SetupComplete } from './SetupComplete';
import { Steps } from './Steps';

interface Props {
  tables: Array<
    Pick<AddedTable, 'tableNo' | 'personsNo' | 'minPersons' | 'visibleInWidget'>
  >;
  settings: RestaurantSettingsState;
  reservationTimes: RestaurantReservationTimesState;
  currentStep: number;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
  execRestaurantSettingsChange: (
    restaurantSettings: RestaurantSettingsState,
  ) => void;
  execRestaurantReservationTimesChange: (
    reservationTimes: RestaurantReservationTimesState,
    action: Actions,
  ) => void;
  execBulkAddTables: (
    tables: Array<AddRealTableRequestBody>,
    action: Actions,
  ) => void;
  execCompleteSetup: () => void;
  goToPreviousStep: () => void;
  execUpdateWidgetActive: (isActive: boolean) => void;
  widgetUrl: string;
  isSetupCompleted: boolean;
  widgetActive: boolean;
}

export const SetupWizard = ({
  tables,
  settings,
  reservationTimes,
  execDataLoadFailed,
  execUpdateLogoFilename,
  execRestaurantSettingsChange,
  execRestaurantReservationTimesChange,
  execBulkAddTables,
  execCompleteSetup,
  widgetUrl,
  isSetupCompleted,
  currentStep,
  goToPreviousStep,
  widgetActive,
  execUpdateWidgetActive,
}: Props) => {
  if (isSetupCompleted) {
    return (
      <SetupComplete
        widgetUrl={widgetUrl}
        widgetActive={widgetActive}
        execUpdateWidgetActive={execUpdateWidgetActive}
      />
    );
  } else {
    return (
      <Steps
        tables={tables}
        settings={settings}
        reservationTimes={reservationTimes}
        currentStep={currentStep}
        execDataLoadFailed={execDataLoadFailed}
        execUpdateLogoFilename={execUpdateLogoFilename}
        execRestaurantSettingsChange={execRestaurantSettingsChange}
        execRestaurantReservationTimesChange={
          execRestaurantReservationTimesChange
        }
        execBulkAddTables={execBulkAddTables}
        execCompleteSetup={execCompleteSetup}
        goToPreviousStep={goToPreviousStep}
      />
    );
  }
};
