import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  TABLE_SETTINGS_EDIT_VIEW,
  TABLE_SETTINGS_ADD_VIRTUAL_VIEW,
  TABLE_SETTINGS_ADD_REAL_VIEW,
  TABLE_SETTINGS_LIST_VIEW,
} from '../../constants';
import { TableAddContainer } from '../../containers/TableSettings/TableAdd';
import { TableEditContainer } from '../../containers/TableSettings/TableEdit';
import { TableSettingsListContainer } from '../../containers/TableSettings/TableSettingsList';

export const TableSettingsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={TABLE_SETTINGS_ADD_REAL_VIEW}>
        <TableAddContainer isVirtual={false} />
      </Route>
      <Route path={TABLE_SETTINGS_ADD_VIRTUAL_VIEW}>
        <TableAddContainer isVirtual={true} />
      </Route>
      <Route path={TABLE_SETTINGS_EDIT_VIEW}>
        <TableEditContainer />
      </Route>
      <Route path={TABLE_SETTINGS_LIST_VIEW}>
        <TableSettingsListContainer />
      </Route>
      <Route path={path} exact={true}>
        <TableSettingsListContainer />
      </Route>
    </Switch>
  );
};
