import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { denormalize } from '@gts-common/client';
import {
  NotReservedTable,
  ReduxState,
  TableWithExtraProperties,
} from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { WalkInTablesView } from '../../components/Tables/WalkIn/WalkInTablesView';
import { tryToReserveTableForWalkIn } from '../../actions';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import { getLocationState } from '../helpers/getLocationState';
import { getReservationsAfterDate } from './helpers/getReservationsAfterDate';
import { getTableExtraProperties } from './helpers/getTableExtraProperties';
import { addExtraTableProperties } from './helpers/addExtraTableProperties';
import { groupWalkInTablesByStatus } from './helpers/groupWalkInTablesByStatus';
import { groupWalkInTablesByTime } from './helpers/groupWalkInTablesByTime';
import { prepareWalkInTableGroups } from './helpers/prepareWalkInTableGroups';

const mapStateToProps = (state: ReduxState) => {
  const routerState = getLocationState(state);
  const isOnline = state.app.isOnline;
  const startOfNextInterval = state.app.startOfNextInterval;
  const tablesState = state.tables;
  const reservationsState = state.reservations;
  const tableStatusReservedTime =
    state.restaurant.runtimeSettings.tableStatusReservedTime;
  const denormalizedTables = denormalize(
    tablesState.items,
    tablesState.entities,
  );
  const denormalizedReservations = denormalize(reservationsState);

  const reservations = getReservationsAfterDate(
    denormalizedReservations,
    startOfNextInterval,
  );

  const tableUpdates = getTableExtraProperties(
    reservations,
    startOfNextInterval,
    tablesState.entities,
    tableStatusReservedTime,
  );

  // Filter out all virtual tables because those are
  // basically defined by their real tables
  const filteredTables = denormalizedTables.filter((table) => !table.isVirtual);

  const updatedTables = addExtraTableProperties(filteredTables, tableUpdates);
  const groupedTables = groupWalkInTablesByStatus(updatedTables);
  const freeOrReservedTables = sortTablesByTableNo(
    groupedTables.freeOrReservedTables,
  );
  const timeGroupedFreeTables = groupWalkInTablesByTime(freeOrReservedTables);
  const occupiedTables = sortTablesByTableNo(groupedTables.occupiedTables);
  const notReservedTables = sortTablesByTableNo(
    groupedTables.notReservedTables,
  );

  const groupedFreeTables = prepareWalkInTableGroups(timeGroupedFreeTables);

  return {
    groupedFreeTables,
    notReservedTables,
    occupiedTables,
    cameFrom: routerState.cameFrom,
    isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  walkInClicked(table: TableWithExtraProperties | NotReservedTable) {
    dispatch(tryToReserveTableForWalkIn(table));
  },
});

export const WalkInTablesViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WalkInTablesView);
