import { AddedTable } from '@gts-ft/utils';

export function sortAlphabeticallyByTableNo(
  table1: AddedTable,
  table2: AddedTable,
) {
  if (table1.tableNo > table2.tableNo) {
    return 1;
  } else if (table1.tableNo < table2.tableNo) {
    return -1;
  } else {
    return 0;
  }
}

export function sortNumericallyByTableNo(
  table1: AddedTable,
  table2: AddedTable,
) {
  if (Number(table1.tableNo) > Number(table2.tableNo)) {
    return 1;
  } else if (Number(table1.tableNo) < Number(table2.tableNo)) {
    return -1;
  } else {
    return 0;
  }
}

export function sortTablesByTableNo<T>(tables: Array<AddedTable & T>) {
  const { num, alph } = tables.reduce<{
    num: Array<AddedTable & T>;
    alph: Array<AddedTable & T>;
  }>(
    (prev, cur) => {
      if (/^\d+$/.test(cur.tableNo)) {
        prev.num.push(cur);
      } else {
        prev.alph.push(cur);
      }
      return prev;
    },
    {
      num: [],
      alph: [],
    },
  );
  return [
    ...num.sort(sortNumericallyByTableNo),
    ...alph.sort(sortAlphabeticallyByTableNo),
  ];
}
