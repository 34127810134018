import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { TableStatus } from '@gts-ft/utils';
import { denormalize } from '@gts-common/client';
import { ReduxState, TableWithExtraProperties } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { getLocationState } from '../helpers/getLocationState';
import { UnblockTablesView } from '../../components/Tables/Unblock/UnblockTablesView';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import { tryToUnblockTable } from '../../actions/tables/unblockClicked';
import { getReservationsAfterDate } from './helpers/getReservationsAfterDate';
import { getTableExtraProperties } from './helpers/getTableExtraProperties';
import { addExtraTableProperties } from './helpers/addExtraTableProperties';

const mapStateToProps = (state: ReduxState) => {
  const routerState = getLocationState(state);
  const isOnline = state.app.isOnline;
  const startOfNextInterval = state.app.startOfNextInterval;
  const tablesState = state.tables;
  const reservationsState = state.reservations;
  const tableStatusReservedTime =
    state.restaurant.runtimeSettings.tableStatusReservedTime;
  const denormalizedTables = denormalize(
    tablesState.items,
    tablesState.entities,
  );
  const denormalizedReservations = denormalize(reservationsState);

  const reservations = getReservationsAfterDate(
    denormalizedReservations,
    startOfNextInterval,
  );

  const tableUpdates = getTableExtraProperties(
    reservations,
    startOfNextInterval,
    tablesState.entities,
    tableStatusReservedTime,
  );

  // Filter out all virtual tables because those are
  // basically defined by their real tables
  const filteredTables = denormalizedTables.filter((table) => !table.isVirtual);

  const updatedTables = addExtraTableProperties(filteredTables, tableUpdates);

  const occupiedTables = Object.keys(updatedTables).reduce<
    Array<TableWithExtraProperties>
  >((prev, tableId) => {
    const table = updatedTables[tableId];
    if (table.status === TableStatus.OCCUPIED) {
      return [...prev, table];
    } else {
      return prev;
    }
  }, []);

  const tables = sortTablesByTableNo(occupiedTables);

  return {
    isOnline,
    tables,
    cameFrom: routerState.cameFrom,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  unblockClicked(table: TableWithExtraProperties) {
    dispatch(tryToUnblockTable(table));
  },
});

export const UnblockTablesViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnblockTablesView);
