import { compareAsc } from 'date-fns';
import {
  ClientReservation,
  ExtraTableProperties,
  TablesState,
} from '../../../types';
import { getAssociatedTables } from '../../../helpers/getAssociatedTables';
import { getReservationUntilTime } from './getReservationUntilTime';
import { getTableStatus } from './getTableStatus';

function getTableUpdate(
  reservation: ClientReservation,
  currentDatetime: Date,
  tableStatusReservedTime: number,
) {
  const tableStatus = getTableStatus(
    currentDatetime,
    reservation.datetime,
    reservation.reservationDuration,
    tableStatusReservedTime,
  );
  const reservedUntilTime = getReservationUntilTime(reservation);
  const tableUpdate: ExtraTableProperties = {
    status: tableStatus,
    firstReservationId: reservation.reservationId,
    firstReservationTime: reservation.time,
    firstReservationDatetime: reservation.datetime,
    firstReservedUntilTime: reservedUntilTime,
    secondReservationId: undefined,
    secondReservationTime: undefined,
    secondReservationDatetime: null,
    secondReservedUntilTime: undefined,
  };

  return tableUpdate;
}

function getAssociatedTableUpdate(
  tableId: string,
  allTableUpdates: Record<string, ExtraTableProperties>,
  reservationTableUpdates: Record<string, ExtraTableProperties>,
  parentTableUpdate: ExtraTableProperties,
) {
  // If we already have an update use that
  if (allTableUpdates[tableId]) {
    return allTableUpdates[tableId];
    // If the update happened as part of this reservation use that.
    // This can happen if a reservation has two real tables both belonging to a virtual table we are updating now
  } else if (reservationTableUpdates[tableId]) {
    return reservationTableUpdates[tableId];
    // Use the update object of the parent
  } else {
    return parentTableUpdate;
  }
}

export function getTableExtraProperties(
  reservations: Array<ClientReservation>,
  currentDatetime: Date,
  tableEntities: TablesState['entities'],
  tableStatusReservedTime: number,
) {
  const _reservations = [...reservations];
  _reservations.sort((res1, res2) => {
    return compareAsc(res1.datetime, res2.datetime);
  });

  return _reservations.reduce<Record<string, ExtraTableProperties>>(
    (allTableUpdates, reservation) => {
      const { tableIds } = reservation;

      const resUpdates = tableIds.reduce<Record<string, ExtraTableProperties>>(
        (reservationTableUpdates, tableId) => {
          const directlyReservedTableUpdate = allTableUpdates[tableId];

          let tableUpdate: ExtraTableProperties;

          // If we already have an update object we only need to add the second reservation if there isn't one already
          // This could happen if we have multiple reservations in a night for one table.
          // Since we sort by reservation datetime ASC we always assign
          // the time and status of the current or the next reservation.
          if (!directlyReservedTableUpdate) {
            tableUpdate = getTableUpdate(
              reservation,
              currentDatetime,
              tableStatusReservedTime,
            );
          } else if (
            directlyReservedTableUpdate.secondReservationTime === undefined
          ) {
            const reservedUntilTime = getReservationUntilTime(reservation);
            tableUpdate = {
              ...directlyReservedTableUpdate,
              secondReservationId: reservation.reservationId,
              secondReservationTime: reservation.time,
              secondReservationDatetime: reservation.datetime,
              secondReservedUntilTime: reservedUntilTime,
            };
          } else {
            tableUpdate = directlyReservedTableUpdate;
          }

          const associatedTableIds = getAssociatedTables(
            tableEntities[tableId],
            tableEntities,
          ).map((associatedTable) => associatedTable.tableId);

          const associatedTableUpdates = associatedTableIds.reduce<
            Record<string, ExtraTableProperties>
          >((associatedTableUpdates, tableId) => {
            return {
              ...associatedTableUpdates,
              [tableId]: getAssociatedTableUpdate(
                tableId,
                allTableUpdates,
                reservationTableUpdates,
                tableUpdate,
              ),
            };
          }, {});

          return {
            ...reservationTableUpdates,
            [tableId]: tableUpdate,
            ...associatedTableUpdates,
          };
        },
        {},
      );
      return {
        ...allTableUpdates,
        ...resUpdates,
      };
    },
    {},
  );
}
