import { SERVER_CHECK_PATH } from '@gts-common/client-server';

import { serverComm } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  execOpenMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  Actions,
  IS_OFFLINE,
  IS_ONLINE,
  IsOfflineAction,
  IsOnlineAction,
  SHOW_APP_WITH_OFFLINE_DATA,
  ShowAppWithOfflineDataAction,
  Thunk,
} from './reduxActionTypes';
import { execResolveLogin } from './login';
import { loadDataFromOfflineStorage } from './helpers/loadDataFromOfflineStorage';

export function isOnline(): IsOnlineAction {
  return {
    type: IS_ONLINE,
  };
}

export function isOffline(): IsOfflineAction {
  return {
    type: IS_OFFLINE,
  };
}

export function showAppWithOfflineData(
  dataTimestamp: number | undefined,
): ShowAppWithOfflineDataAction {
  return {
    type: SHOW_APP_WITH_OFFLINE_DATA,
    payload: dataTimestamp,
  };
}

function startPeriodicCheckForOnline(): Thunk<Actions> {
  const INTERVAL = 30000;
  return (dispatch) => {
    function periodicCheckForOnline() {
      return serverComm
        .execGetRequest(SERVER_CHECK_PATH)
        .then(
          () => {
            dispatch(
              execOpenMessage({
                type: MessageType.MODAL,
                modalType: ModalType.RELOAD_MODAL,
                title: 'Verbindung zu free-table wiederhergestellt',
                body:
                  'Die Verbindung zu free-table wurde wiederhergestellt. Bitte laden Sie die Seite neu, um mit den aktuellen Daten zu arbeiten.',
              }),
            );
          },
          // catch block for same promise because we explicitly want to catch a failed online check and not other exceptions
          () => {
            setTimeout(periodicCheckForOnline, INTERVAL);
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    }
    setTimeout(periodicCheckForOnline, INTERVAL);
  };
}

export function checkOnlineStatus(): Thunk<Actions> {
  return (dispatch) => {
    return serverComm
      .execGetRequest(SERVER_CHECK_PATH)
      .then(
        () => {
          dispatch(isOnline());
          dispatch(execResolveLogin());
        },
        // catch block for same promise because we explicitly want to catch a failed online check and not other exceptions
        (e: unknown) => {
          dispatch(isOffline());
          dispatch(loadDataFromOfflineStorage());
          dispatch(startPeriodicCheckForOnline());
          return Promise.reject(e);
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
