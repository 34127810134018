import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { denormalize } from '@gts-common/client';
import { AddServiceAreaRequestBody } from '@gts-ft/utils';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { execAddServiceArea } from '../../actions';
import { ServiceAreaAdd } from '../../components/ServiceAreas/ServiceAreaAdd';
import { filterAssignedTables } from '../helpers/serviceAreas';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';

const mapStateToProps = (state: ReduxState) => {
  const serviceAreaState = state.serviceAreas;

  const denormalizedTables = denormalize(state.tables);
  const sortedRealTables = sortTablesByTableNo(
    denormalizedTables.filter((table) => !table.isVirtual),
  );

  const availableTables = filterAssignedTables(
    sortedRealTables,
    serviceAreaState,
  );

  return {
    availableTables,
    realTables: sortedRealTables,
    isOnline: state.app.isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddServiceArea(serviceArea: AddServiceAreaRequestBody) {
    dispatch(execAddServiceArea(serviceArea));
  },
});

export const ServiceAreaAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAreaAdd);
