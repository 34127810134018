import {
  RestaurantFromServer,
  RestaurantReservationTimesState,
} from '../../../types';
import {
  transformClosedOnDatesForClient,
  transformClosedOnDatesForServer,
} from './transformClosedOnDates';

export function createRestaurantReservationTimesObjectForClient(
  openingTimes: RestaurantFromServer['openingTimes'],
): RestaurantReservationTimesState {
  return {
    closedOnDates: transformClosedOnDatesForClient(openingTimes.closedOnDates),
    reservationHours: openingTimes.reservationHours,
  };
}

export function createRestaurantReservationTimesObjectForServer(
  openingTimes: RestaurantReservationTimesState,
) {
  return {
    reservationHours: openingTimes.reservationHours,
    closedOnDates: transformClosedOnDatesForServer(openingTimes.closedOnDates),
  };
}
