import { execOpenMessage, MessageType } from '@gts-common/client';
import {
  Actions,
  COULD_NOT_STORE_OFFLINE_DATA_SHOWN,
  CouldNotStoreOfflineDataShownAction,
  Thunk,
} from './reduxActionTypes';

function couldNotStoreOfflineDataShown(): CouldNotStoreOfflineDataShownAction {
  return {
    type: COULD_NOT_STORE_OFFLINE_DATA_SHOWN,
  };
}

export function showOnceCouldNotStoreOfflineData(): Thunk<Actions> {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.app.warnings.couldNotStoreOfflineDataShown) {
      dispatch(
        execOpenMessage({
          type: MessageType.MODAL,
          modalType: 'CouldNotStoreOfflineDataModal',
        }),
      );
      dispatch(couldNotStoreOfflineDataShown);
    }
  };
}
