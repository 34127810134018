import { TableStatus } from '@gts-ft/utils';
import { NotReservedTable, TableWithExtraProperties } from '../../../types';

export function groupWalkInTablesByStatus(
  updatedTables: Record<string, TableWithExtraProperties | NotReservedTable>,
) {
  const groupedTables = Object.keys(updatedTables).reduce<{
    notReservedTables: Array<NotReservedTable>;
    freeOrReservedTables: Array<TableWithExtraProperties>;
    occupiedTables: Array<TableWithExtraProperties>;
  }>(
    (groups, tableId) => {
      const table = updatedTables[tableId];

      if (table.status === TableStatus.NOT_RESERVED) {
        return {
          ...groups,
          notReservedTables: [
            ...groups.notReservedTables,
            table as NotReservedTable,
          ],
        };
      } else if (table.status === TableStatus.OCCUPIED) {
        return {
          ...groups,
          occupiedTables: [...groups.occupiedTables, table],
        };
      } else {
        return {
          ...groups,
          freeOrReservedTables: [...groups.freeOrReservedTables, table],
        };
      }
    },
    {
      notReservedTables: [],
      occupiedTables: [],
      freeOrReservedTables: [],
    },
  );

  return groupedTables;
}
