import {
  Checkbox,
  createStyles,
  Divider,
  fade,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  useTheme,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import MoreIcon from '@material-ui/icons/More';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/Create';

import { MouseEvent, useState } from 'react';
import { getListItemClasses } from '../UI-Components/helpers/listItemClasses';
import { ReservationStatus } from '../../constants';
import { ReservationInfos } from './ReservationInfos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overdue: {
      background: 'rgba(218, 55, 67, 0.5)',
      '&:hover': {
        backgroundColor: fade('#da3743', theme.palette.action.hoverOpacity),
      },
      borderLeftColor: '#da3743',
    },

    upcoming: {
      background: 'rgba(248, 205, 15, 0.5)',
      '&:hover': {
        backgroundColor: fade('#facc02', theme.palette.action.hoverOpacity),
      },
      borderLeftColor: '#facc02',
    },

    Cancelled: {
      background: 'rgb(220, 220, 220, 0.5)',
      '&:hover': {
        backgroundColor: fade('#808080', theme.palette.action.hoverOpacity),
      },
      borderLeftColor: '#808080',
    },

    standard: {
      borderLeftColor: 'white',
    },

    reservation: {
      paddingLeft: '8px',
      borderLeftWidth: '8px',
      borderLeftStyle: 'solid',
      marginBottom: '5px',
      ...getListItemClasses(theme),
    },

    secondaryActionRoot: {
      right: 0, // Give ListItemSecondaryAction as much space as possible
    },
  }),
);

function getClass(
  status: ReservationStatus,
  classes: ReturnType<typeof useStyles>,
) {
  switch (status) {
    case ReservationStatus.OVERDUE:
      return `${classes.overdue} ${classes.reservation}`;
    case ReservationStatus.UPCOMING:
      return `${classes.upcoming} ${classes.reservation}`;
    case ReservationStatus.CANCELLED:
      return `${classes.Cancelled} ${classes.reservation}`;
    default:
      return `${classes.standard} ${classes.reservation}`;
  }
}

interface Props {
  reservationId: string;
  arrived: boolean;
  personsNo: number;
  tableNos: Array<string>;
  name: string;
  email: string;
  date: string;
  time: string;
  manualTableAllocation: boolean;
  company: string;
  telephone: string;
  remark: string;
  status: ReservationStatus;
  isCancelButtonDisabled: boolean;
  isEditButtonDisabled: boolean;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
  execSetArrivalStatus: (reservationId: string, arrived: boolean) => void;
  isOnline: boolean;
}

interface SecondaryActionMenuProps {
  reservationId: string;
  isOnline: boolean;
  isCancelButtonDisabled: boolean;
  isEditButtonDisabled: boolean;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
}

const SecondaryActionMenu = ({
  reservationId,
  isOnline,
  isCancelButtonDisabled,
  isEditButtonDisabled,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
}: SecondaryActionMenuProps) => {
  const [anchor, setAnchor] = useState<MouseEvent['currentTarget'] | null>(
    null,
  );
  return (
    <>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        onClick={() => setAnchor(null)}
      >
        <MenuItem onClick={() => showReservationDetails(reservationId)}>
          <ListItemIcon>
            <MoreIcon />
          </ListItemIcon>
          <ListItemText>Details</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!isOnline || isEditButtonDisabled}
          onClick={() => showReservationEdit(reservationId)}
        >
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText>Bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!isOnline || isCancelButtonDisabled}
          onClick={() => execCancelReservation(reservationId)}
        >
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText>Stornieren</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export const Reservation = ({
  reservationId,
  tableNos,
  personsNo,
  name,
  time,
  arrived,
  remark,
  isCancelButtonDisabled,
  isEditButtonDisabled,
  execSetArrivalStatus,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
  isOnline,
  status,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ListItem
      divider
      disableGutters
      button
      onClick={() => execSetArrivalStatus(reservationId, !arrived)}
      className={getClass(status, classes)}
      disabled={!isOnline}
    >
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={arrived}
          tabIndex={-1}
          disableRipple
          disabled={!isOnline}
        />
      </ListItemIcon>
      <ListItemText>
        <ReservationInfos
          time={time}
          name={name}
          tableNos={tableNos}
          personsNo={personsNo}
          remark={remark}
          isCancelled={status === ReservationStatus.CANCELLED}
        />
      </ListItemText>
      <ListItemSecondaryAction classes={{ root: classes.secondaryActionRoot }}>
        <SecondaryActionMenu
          reservationId={reservationId}
          isOnline={isOnline}
          isCancelButtonDisabled={isCancelButtonDisabled}
          isEditButtonDisabled={isEditButtonDisabled}
          execCancelReservation={execCancelReservation}
          showReservationEdit={showReservationEdit}
          showReservationDetails={showReservationDetails}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
