import { MouseEvent } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { DummyFileObject, FileObject } from './types';
import { Loader } from './Loader';
import { DeleteFileBtn } from './DeleteFileBtn';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.action.active,
      backgroundColor: 'white',
      position: 'relative',
      boxShadow: theme.shadows[1],
    },
    image: {
      maxWidth: '100%',
      maxHeight: '160px',
    },
    placeholderImage: {
      width: '100px',
      height: '100px',
    },
  }),
);

interface FilePreviewProps {
  fileObjects: Array<FileObject | DummyFileObject>;
  onRemove: (e: MouseEvent, index: number) => void;
}

export const FilePreview = ({ fileObjects, onRemove }: FilePreviewProps) => {
  const classes = useStyles();

  return (
    <>
      {fileObjects.map((fileObject, index) => (
        <div key={index} className={classes.root}>
          {fileObject.isLoading && <Loader />}
          {fileObject.data !== undefined && (
            <img src={fileObject.data} alt="Bild" className={classes.image} />
          )}
          {fileObject.data === undefined && (
            <Box className={classes.placeholderImage}></Box>
          )}
          {fileObject.isLoading === false && (
            <DeleteFileBtn onRemove={(e) => onRemove(e, index)} />
          )}
        </div>
      ))}
    </>
  );
};
