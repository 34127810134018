import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import { AddedServiceArea } from '@gts-ft/utils';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { execUpdateServiceArea } from '../../actions';
import { ServiceAreaEdit } from '../../components/ServiceAreas/ServiceAreaEdit';
import { filterAssignedTables } from '../helpers/serviceAreas';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import { SERVICE_AREAS_EDIT_VIEW } from '../../constants';

const matchSelector = createMatchSelector<
  RouterRootState,
  { serviceAreaId: string }
>(SERVICE_AREAS_EDIT_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const serviceAreaId = match ? match.params.serviceAreaId : undefined;

  const serviceAreasState = state.serviceAreas;

  const selectedServiceArea = serviceAreasState.entities[serviceAreaId ?? ''];

  // serviceAreaId could be defined but still not existent
  // so check to make sure we found a table
  if (selectedServiceArea) {
    const denormalizedTables = denormalize(state.tables);
    const sortedRealTables = sortTablesByTableNo(
      denormalizedTables.filter((table) => !table.isVirtual),
    );

    const availableTables = filterAssignedTables(
      sortedRealTables,
      serviceAreasState,
      selectedServiceArea.serviceAreaId,
    );
    return {
      serviceArea: selectedServiceArea,
      availableTables,
      realTables: sortedRealTables,
      isOnline: state.app.isOnline,
    };
  } else {
    return {
      serviceArea: undefined,
      availableTables: [],
      realTables: [],
      isOnline: state.app.isOnline,
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execUpdateServiceArea(
    serviceAreaId: string,
    serviceArea: Omit<AddedServiceArea, 'serviceAreaId'>,
  ) {
    dispatch(execUpdateServiceArea(serviceAreaId, serviceArea));
  },
});

export const ServiceAreaEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAreaEdit);
