import { AddedTable, TableStatus } from '@gts-ft/utils';
import {
  ExtraTableProperties,
  NotReservedTable,
  TableWithExtraProperties,
} from '../../../types';

export function addExtraTableProperties(
  tables: Array<AddedTable>,
  tableUpdates: Record<string, ExtraTableProperties>,
) {
  const updatedTables = tables.reduce<
    Record<string, TableWithExtraProperties | NotReservedTable>
  >((updates, table) => {
    const tableId = table.tableId;
    const tableUpdate = tableUpdates[tableId];
    if (tableUpdate) {
      return {
        ...updates,
        [tableId]: {
          ...table,
          ...tableUpdate,
        },
      };
      // Make sure all tables have a status
    } else {
      return {
        ...updates,
        [tableId]: {
          ...table,
          status: TableStatus.NOT_RESERVED,
          firstReservationId: undefined,
          firstReservationTime: undefined,
          firstReservationDatetime: null,
          firstReservedUntilTime: undefined,
          secondReservationId: undefined,
          secondReservationTime: undefined,
          secondReservationDatetime: null,
          secondReservedUntilTime: undefined,
        },
      };
    }
  }, {});

  return updatedTables;
}
