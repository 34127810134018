import { List, Typography } from '@material-ui/core';
import { ClientReservationWithExtraProperties } from '../../types';
import { Reservation } from './Reservation';

interface ReservationListProps {
  isOnline: boolean;
  reservations: Array<ClientReservationWithExtraProperties>;
  execSetArrivalStatus: (reservationId: string, arrived: boolean) => void;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
}

export const ReservationsList = ({
  isOnline,
  reservations,
  execSetArrivalStatus,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
}: ReservationListProps) => {
  if (reservations.length === 0) {
    return <Typography variant="subtitle1">Keine Reservierungen</Typography>;
  } else {
    return (
      <List>
        {reservations.map((reservation) => (
          <Reservation
            key={reservation.reservationId}
            {...reservation}
            execCancelReservation={execCancelReservation}
            showReservationEdit={showReservationEdit}
            showReservationDetails={showReservationDetails}
            execSetArrivalStatus={execSetArrivalStatus}
            isOnline={isOnline}
          />
        ))}
      </List>
    );
  }
};
