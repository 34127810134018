import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  RESTAURANT_RESERVATION_TIMES_VIEW,
  RESTAURANT_SETTINGS_VIEW,
  SERVICE_AREAS_LIST_VIEW,
  TABLE_SETTINGS_LIST_VIEW,
} from '../../constants';

interface SettingsMenuProps {
  anchor: MouseEvent['currentTarget'] | null;
  isOpen: boolean;
  closeMenu: () => void;
}

export const SettingsMenu = ({
  anchor,
  isOpen,
  closeMenu,
}: SettingsMenuProps) => {
  return (
    <Menu
      id="settings-menu-appbar"
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={closeMenu}
      onClick={closeMenu}
    >
      <MenuItem component={Link} to={RESTAURANT_RESERVATION_TIMES_VIEW}>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText>Reservierungszeiten</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={RESTAURANT_SETTINGS_VIEW}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Einstellungen</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={TABLE_SETTINGS_LIST_VIEW}>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText>Tische verwalten</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to={SERVICE_AREAS_LIST_VIEW}>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText>Servicebereiche</ListItemText>
      </MenuItem>
    </Menu>
  );
};
