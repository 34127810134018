import { COMPLETE_SETUP } from '@gts-ft/utils';
import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  Actions,
  SETUP_COMPLETED,
  SetupCompleted,
  Thunk,
} from './reduxActionTypes';

export function setupCompleted(): SetupCompleted {
  return {
    type: SETUP_COMPLETED,
  };
}

export function completeSetup(): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    return serverComm
      .execPostRequest(COMPLETE_SETUP)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(setupCompleted());
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
