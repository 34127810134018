import { withStyles, WithStyles, Grid, Box } from '@material-ui/core';

import { SaveButton } from '@gts-common/client';

import { Form } from 'formik';
import { FileUploaderAction } from '../UI-Components';
import { ClientSettings, WidgetSettings, WidgetLogo } from './subforms';

const styles = {
  panelWrapper: {
    marginBottom: '30px',
  },
};

interface Props extends WithStyles<typeof styles> {
  isOnline: boolean;
  logoFilename: string | null;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
}

const formId = 'restaurant-settings-form';

const UnstyledRestaurantSettingsForm = ({
  classes,
  isOnline,
  logoFilename,
  execDataLoadFailed,
  execUpdateLogoFilename,
}: Props) => (
  <Form id={formId} noValidate={true}>
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={6}>
        <div className={classes.panelWrapper}>
          <WidgetSettings />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.panelWrapper}>
          <ClientSettings />
        </div>
        <div className={classes.panelWrapper}>
          <WidgetLogo
            execDataLoadFailed={execDataLoadFailed}
            execUpdateLogoFilename={execUpdateLogoFilename}
            logoFilename={logoFilename}
          />
        </div>
      </Grid>
    </Grid>

    <Box mt="1rem" textAlign="center">
      <SaveButton submitForForm={formId} disabled={!isOnline} />
    </Box>
  </Form>
);

export const RestaurantSettingsForm = withStyles(styles)(
  UnstyledRestaurantSettingsForm,
);
