import { ReservationFormData, reservationUpdateValidation } from '@gts-ft/ui';
import { View, NotFoundText } from '@gts-common/client';

import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import { AddedTable } from '@gts-ft/utils';
import {
  ClientReservation,
  NavigationCameFrom,
  NavigationTo,
} from '../../types';
import { RESERVATIONS_DETAILS_PATH } from '../../constants';
import { ReservationForm } from './ReservationForm';

interface Props {
  execUpdateReservation: (
    reservationId: string,
    values: ReservationFormData,
    navigateBackTo: NavigationTo,
  ) => void;
  tables: Record<string, AddedTable>;
  reservation: ClientReservation | undefined;
  isOnline: boolean;
  cameFrom: NavigationCameFrom;
}

export const ReservationEdit = ({
  reservation,
  execUpdateReservation,
  tables,
  isOnline,
  cameFrom,
}: Props) => {
  if (!reservation) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={cameFrom.to}
        backButtonLabel={cameFrom.backButtonLabel}
        title="Reservierung bearbeiten"
      >
        <NotFoundText>
          Die Reservierung existiert nicht. Bitte überprüfen Sie den Link und
          versuchen Sie es erneut.
        </NotFoundText>
      </View>
    );
  }

  // Cancelled and anonymized reservations can't be edited
  if (reservation.cancelledAt || reservation.wasAnonymized) {
    return (
      <Redirect
        to={`${RESERVATIONS_DETAILS_PATH}/${reservation.reservationId}`}
      />
    );
  }

  return (
    <View
      hasBackButton={true}
      navigateBackTo={cameFrom.to}
      backButtonLabel={cameFrom.backButtonLabel}
      title="Reservierung bearbeiten"
    >
      <Formik
        onSubmit={(values) => {
          execUpdateReservation(reservation.reservationId, values, cameFrom.to);
        }}
        initialValues={reservation}
        validationSchema={reservationUpdateValidation}
      >
        {({ values, errors }) => {
          return (
            <ReservationForm
              isOnline={isOnline}
              isEdit={true}
              arrived={reservation.arrived}
              values={values}
              errors={errors}
              tables={tables}
            />
          );
        }}
      </Formik>
    </View>
  );
};
