import { Box, Typography, Paper } from '@material-ui/core';
import { View, LinkButton, CheckboxInput } from '@gts-common/client';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import { WidgetLink } from '../RestaurantSettings/subforms';
import { HOME_VIEW, RESTAURANT_SETTINGS_VIEW } from '../../constants';

interface Props {
  widgetUrl: string;
  widgetActive: boolean;
  execUpdateWidgetActive: (isActive: boolean) => void;
}

export const SetupComplete = ({
  widgetUrl,
  widgetActive,
  execUpdateWidgetActive,
}: Props) => {
  return (
    <View hasBackButton={false} title="Ersteinrichtung abgeschlossen">
      <WidgetLink url={widgetUrl} />
      <Paper style={{ marginBottom: '30px' }}>
        <Box p="1rem">
          <Typography variant="subtitle1">
            Bitte überführen Sie Ihr Reservierungsbuch zeitnah in free-table,
            sofern das Reservierungsformular aktiv ist und der Link für das
            Reservierungsformular auf Ihrer Webseite oder woanders eingebaut ist
            um Doppelbuchungen zu verhindern.
          </Typography>
          <CheckboxInput
            type="switch"
            value={widgetActive}
            onChange={(_event, checked) => {
              execUpdateWidgetActive(checked);
            }}
          >
            Reservierungsformular ({widgetActive ? 'aktiv' : 'inaktiv'})
          </CheckboxInput>
        </Box>
      </Paper>
      <Typography align="center">
        Sie können in den Einstellungen jederzeit die Einträge für das
        Reservierungsformular anpassen.
      </Typography>
      <Typography align="center">
        Darüber hinaus gibt es noch weitere Optionen, die Sie einstellen können.
      </Typography>
      <Box
        m="1rem"
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        <Box mb="1rem" mx="0.5rem">
          <LinkButton
            to={HOME_VIEW}
            label="Zur Startseite"
            startIcon={<HomeIcon />}
          />
        </Box>
        <Box mb="1rem" mx="0.5rem">
          <LinkButton
            to={RESTAURANT_SETTINGS_VIEW}
            label="Zu den Einstellungen"
            startIcon={<SettingsIcon />}
          />
        </Box>
      </Box>
    </View>
  );
};
