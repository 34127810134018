import {
  GetReservationsRequestQuery,
  GetReservationsResponse,
  RESERVATIONS_PATH,
} from '@gts-ft/utils';
import {
  IndexSignatureHack,
  SERVER_DATE_FORMAT,
} from '@gts-common/client-server';
import { format } from 'date-fns';
import { serverComm } from '@gts-ft/ui';
import { ThunkDispatch } from 'redux-thunk';
import {
  getErrorModalMessage,
  serverRequestFailed,
  normalize,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import { ReduxState } from '../../types';
import { Actions } from '../reduxActionTypes';

import { reservationsTransformForClient } from '../helpers/prepareLoginResponseForClient';
import { getReservationsSucceeded } from './helpers/getReservationsSucceeded';
import { addReservationsToOfflineDB } from './helpers/addReservationsToOfflineDB';

function getReservations(
  dispatch: ThunkDispatch<ReduxState, unknown, Actions>,
) {
  return () => {
    const today = format(new Date(), SERVER_DATE_FORMAT);

    const query: GetReservationsRequestQuery = { today };

    return serverComm
      .execGetRequest<
        GetReservationsResponse,
        IndexSignatureHack<GetReservationsRequestQuery>
      >(RESERVATIONS_PATH, query)
      .then(
        (reservations) => {
          if (reservations.succeeded) {
            dispatch(addReservationsToOfflineDB(reservations.body));
            const normalizedReservations = normalize(
              'reservationId',
              reservationsTransformForClient(reservations.body),
            );

            dispatch(getReservationsSucceeded(normalizedReservations));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function startPollingReservations(
  dispatch: ThunkDispatch<ReduxState, unknown, Actions>,
) {
  // 5 min poll interval
  setInterval(getReservations(dispatch), 300000);
}
