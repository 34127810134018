import { AddedTable } from '@gts-ft/utils';

export function canUncheckManualAllocation(
  isChecked: boolean,
  tableIds: Array<string>,
  tables: Record<string, AddedTable>,
  personsNo: number,
) {
  if (tableIds === undefined || !isChecked) {
    return false;
  }

  return (
    tableIds.length > 1 ||
    (tableIds.length === 1 && tables[tableIds[0]].personsNo < personsNo)
  );
}
