import { initialState } from '../initialState';
import {
  Actions,
  RESTAURANT_RESERVATION_TIMES_UPDATED,
  RESTAURANT_SETTINGS_UPDATED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  UPDATE_LOGO_FILENAME,
  SETUP_COMPLETED,
} from '../actions/reduxActionTypes';
import { RestaurantState } from '../types';

export function restaurantReducer(
  state = initialState.restaurant,
  action: Actions,
): RestaurantState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
        openingTimes: {
          ...state.openingTimes,
          ...action.payload.restaurantReservationTimes,
        },
        settings: {
          ...state.settings,
          ...action.payload.restaurantSettings,
        },
        runtimeSettings: {
          ...state.runtimeSettings,
          ...action.payload.restaurantRuntimeSettings,
        },
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        restaurantId: action.payload.restaurantId,
        openingTimes: {
          ...state.openingTimes,
          ...action.payload.restaurantReservationTimes,
        },
        settings: {
          ...state.settings,
          ...action.payload.restaurantSettings,
        },
        runtimeSettings: {
          ...state.runtimeSettings,
          ...action.payload.restaurantRuntimeSettings,
        },
      };

    // Reset all data
    case LOGOUT_SUCCEEDED:
      return initialState.restaurant;

    case RESTAURANT_SETTINGS_UPDATED:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
        runtimeSettings: {
          tableStatusReservedTime:
            action.payload.tableStatusReservedTime !== undefined
              ? action.payload.tableStatusReservedTime
              : state.runtimeSettings.tableStatusReservedTime,
          reservationDuration:
            action.payload.reservationDuration !== undefined
              ? action.payload.reservationDuration
              : state.runtimeSettings.reservationDuration,
          widgetUrl: state.runtimeSettings.widgetUrl,
        },
      };

    case UPDATE_LOGO_FILENAME:
      return {
        ...state,
        settings: {
          ...state.settings,
          logoFilename: action.payload,
        },
      };

    case RESTAURANT_RESERVATION_TIMES_UPDATED:
      return {
        ...state,
        openingTimes: {
          ...state.openingTimes,
          ...action.payload,
        },
      };

    case SETUP_COMPLETED:
      return {
        ...state,
        settings: {
          ...state.settings,
          widgetActive: true,
        },
      };

    default:
      return state;
  }
}
