import { fade } from '@material-ui/core/styles/colorManipulator';
import { createStyles, Theme } from '@material-ui/core';
import { TableStatus } from '@gts-ft/utils';

export const tableStatusColors = {
  [TableStatus.NOT_RESERVED]: 'rgba(94, 199, 130, 0.6)',
  [TableStatus.FREE]: 'rgba(94, 199, 130, 0.6)',
  [TableStatus.RESERVED]: 'rgba(248, 205, 15, 0.5)',
  [TableStatus.OCCUPIED]: 'rgba(218, 55, 67, 0.5)',
};

export const tableStatusClassesWithoutHover = () =>
  createStyles({
    [`tableStatus${TableStatus.NOT_RESERVED}`]: {
      backgroundColor: tableStatusColors[TableStatus.NOT_RESERVED],
    },

    [`tableStatus${TableStatus.FREE}`]: {
      backgroundColor: tableStatusColors[TableStatus.FREE],
    },

    [`tableStatus${TableStatus.OCCUPIED}`]: {
      backgroundColor: tableStatusColors[TableStatus.OCCUPIED],
    },

    [`tableStatus${TableStatus.RESERVED}`]: {
      backgroundColor: tableStatusColors[TableStatus.RESERVED],
    },
  });

export const tableStatusClassesWithHover = (theme: Theme) =>
  createStyles({
    [`tableStatus${TableStatus.NOT_RESERVED}`]: {
      backgroundColor: tableStatusColors[TableStatus.NOT_RESERVED],
      '&:hover': {
        backgroundColor: fade(
          tableStatusColors[TableStatus.NOT_RESERVED],
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    [`tableStatus${TableStatus.FREE}`]: {
      backgroundColor: tableStatusColors[TableStatus.FREE],
      '&:hover': {
        backgroundColor: fade(
          tableStatusColors[TableStatus.FREE],
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    [`tableStatus${TableStatus.OCCUPIED}`]: {
      backgroundColor: tableStatusColors[TableStatus.OCCUPIED],
      '&:hover': {
        backgroundColor: fade(
          tableStatusColors[TableStatus.OCCUPIED],
          theme.palette.action.hoverOpacity,
        ),
      },
    },

    [`tableStatus${TableStatus.RESERVED}`]: {
      backgroundColor: tableStatusColors[TableStatus.RESERVED],
      '&:hover': {
        backgroundColor: fade(
          tableStatusColors[TableStatus.RESERVED],
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  });
