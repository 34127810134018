import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  TABLES_BOOKING_STATUS_VIEW,
  TABLES_UNBLOCK_VIEW,
  TABLES_WALK_IN_VIEW,
} from '../../constants';
import { TablesBookingStatusViewContainer } from '../../containers/Tables/TablesBookingStatusView';
import { WalkInTablesViewContainer } from '../../containers/Tables/WalkInTablesView';
import { UnblockTablesViewContainer } from '../../containers/Tables/UnblockTablesView';

export const TablesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={TABLES_BOOKING_STATUS_VIEW}>
        <TablesBookingStatusViewContainer />
      </Route>
      <Route path={TABLES_WALK_IN_VIEW}>
        <WalkInTablesViewContainer />
      </Route>
      <Route path={TABLES_UNBLOCK_VIEW}>
        <UnblockTablesViewContainer />
      </Route>
      <Route path={path} exact={true}>
        <TablesBookingStatusViewContainer />
      </Route>
    </Switch>
  );
};
