import { format } from 'date-fns';

import { SERVER_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  normalize,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  convertDecimalTimeToString,
  RESERVATION_WALK_IN_PATH,
  WalkInRequestBody,
  WalkInResponse,
  TableStatus,
} from '@gts-ft/utils';
import {
  addReservationOfflineDB,
  addReservationSucceeded,
} from '../reservations/add';

import { Actions, Thunk } from '../reduxActionTypes';
import { NotReservedTable, TableWithExtraProperties } from '../../types';
import { prepareReservationForClient } from '../reservations/helpers/prepareReservationForClient';
import {
  updateReservationOfflineDB,
  updateReservationSucceeded,
} from '../reservations';

function getWalkInReservation(
  startOfNextInterval: Date,
  table: TableWithExtraProperties | NotReservedTable,
) {
  let firstReservationDate: string | undefined;
  let firstReservationTime: string | undefined;

  if (table.firstReservationDatetime) {
    firstReservationDate = format(
      table.firstReservationDatetime,
      SERVER_DATE_FORMAT,
    );
    firstReservationTime = format(table.firstReservationDatetime, TIME_FORMAT);
  }

  let secondReservationDate: string | undefined;
  let secondReservationTime: string | undefined;

  if (table.secondReservationDatetime) {
    secondReservationDate = format(
      table.secondReservationDatetime,
      SERVER_DATE_FORMAT,
    );
    secondReservationTime = format(
      table.secondReservationDatetime,
      TIME_FORMAT,
    );
  }

  const walkInReservation = {
    date: format(startOfNextInterval, SERVER_DATE_FORMAT),
    time: format(startOfNextInterval, TIME_FORMAT),
    tableId: table.tableId,
    status: table.status,
    firstReservationDate,
    firstReservationTime,
    secondReservationDate,
    secondReservationTime,
  };

  return walkInReservation;
}

export function reserveTableForWalkIn(
  table: TableWithExtraProperties | NotReservedTable,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const state = getState();
    const startOfNextInterval = state.app.startOfNextInterval;

    dispatch(startServerRequest());

    const walkInReservation: WalkInRequestBody = getWalkInReservation(
      startOfNextInterval,
      table,
    );

    return serverComm
      .execPostRequest<WalkInResponse, WalkInRequestBody>(
        RESERVATION_WALK_IN_PATH,
        walkInReservation,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            const addedReservation = resp.body.addedReservation;
            const updatedReservation = resp.body.updatedReservation;

            const reservationForClient = prepareReservationForClient(
              addedReservation,
            );

            const normalizedReservation = normalize(
              'reservationId',
              reservationForClient,
            );

            const duration = convertDecimalTimeToString(
              addedReservation.reservationDuration,
            );
            dispatch(
              serverRequestSucceeded(
                `Tisch reserviert für ${duration} Stunden.`,
              ),
            );
            dispatch(addReservationSucceeded(normalizedReservation));
            dispatch(addReservationOfflineDB(addedReservation));

            if (updatedReservation) {
              dispatch(
                updateReservationSucceeded(
                  updatedReservation.reservationId,
                  updatedReservation,
                ),
              );
              void dispatch(
                updateReservationOfflineDB(
                  updatedReservation.reservationId,
                  updatedReservation,
                ),
              );
            }
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function tryToReserveTableForWalkIn(
  table: TableWithExtraProperties | NotReservedTable,
): Thunk<Actions> {
  return (dispatch) => {
    if (table.status === TableStatus.OCCUPIED) {
      const modal: ExtraModalMessage = {
        type: MessageType.MODAL,
        modalType: ModalType.DECISION_MODAL,
        title: 'Tisch reservieren?',
        body: 'Der Tisch ist belegt. Wollen Sie diesen wirklich reservieren?',
        extraProps: {
          confirmAction: reserveTableForWalkIn(table),
        },
      };

      dispatch(execOpenMessage(modal));
    } else {
      dispatch(reserveTableForWalkIn(table));
    }
  };
}
