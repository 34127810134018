import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Logo } from '@gts-common/client';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import { MouseEvent } from 'react';
import logoSrc from '../../images/logo.png';
import { HOME_VIEW } from '../../constants';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
  },
});

interface HeaderAppBarProps {
  date: string;
  time: string;
  isSetupCompleted: boolean;
  openSettingsMenu: (event: MouseEvent) => void;
  openAccountMenu: (event: MouseEvent) => void;
}

export const HeaderAppBar = ({
  date,
  time,
  openSettingsMenu,
  openAccountMenu,
  isSetupCompleted,
}: HeaderAppBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <AppBar position="static" color="default" classes={classes}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center" justify="center">
          {/* Time/Date */}
          <Grid item xs={4} sm={3} md={2}>
            <Typography>{date}</Typography>
            <Typography>{time} Uhr</Typography>
          </Grid>
          {/* Logo */}
          <Grid item xs={4} sm={6} md={8}>
            <Box display="flex" justifyContent="center">
              <Logo
                to={HOME_VIEW}
                src={logoSrc}
                title="free-table"
                hideText={isXsOrSmaller}
              />
            </Box>
          </Grid>
          {/* IconButtons */}
          <Grid item xs={4} sm={3} md={2}>
            <Box textAlign="right">
              {isSetupCompleted && (
                <IconButton
                  onClick={openSettingsMenu}
                  color="inherit"
                  size="small"
                >
                  <SettingsIcon />
                </IconButton>
              )}
              <IconButton
                onClick={openAccountMenu}
                color="inherit"
                size="small"
              >
                <PersonIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
