import {
  Box,
  createStyles,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CancelledText: {
      position: 'absolute',
      margin: 'auto',
      left: 0,
      right: 0,
      width: '120px',
      border: '2px solid',
      borderColor: theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgb(220, 220, 220)',
    },
    infoIconText: {
      marginRight: '2px',
    },
  }),
);

interface ReservationInfosProps {
  name: string;
  time: string;
  personsNo: number;
  tableNos: Array<string>;
  remark: string;
  isCancelled: boolean;
}

export const ReservationInfos = ({
  time,
  name,
  tableNos,
  personsNo,
  remark,
  isCancelled,
}: ReservationInfosProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
    >
      {isCancelled && (
        <Box className={classes.CancelledText}>
          <Typography color="error" variant="h6" align="center">
            Storniert
          </Typography>
        </Box>
      )}
      <Box mr="auto" pr="8px">
        <Box component="span" width="20px" pr="8px">
          <Typography component="span">{time}</Typography>
        </Box>
        <Typography component="span">{name}</Typography>
        <Typography variant="body2">{remark}</Typography>
      </Box>
      <Box display="flex" flexDirection={isSm ? 'column' : 'row'}>
        <Box display="flex" alignItems="center" pr="8px">
          <Typography component="span" className={classes.infoIconText}>
            {tableNos.join(', ')}
          </Typography>
          <SvgIcon>
            <path d="M23.85 6.81C23.85 6.8 23.85 6.8 23.85 6.8C21.65 4.61 20.44 3.4 20.19 3.15C20.1 3.06 19.96 3 19.82 3C18.25 3 5.68 3 4.11 3C3.97 3 3.83 3.06 3.74 3.16C3.38 3.52 0.5 6.44 0.14 6.81C0.1 6.85 0.07 6.9 0.05 6.95C0.04 6.97 0.04 6.98 0.03 7C0.01 7.04 0.01 7.09 0 7.14C0 7.15 0 7.16 0 7.17C0 7.33 0 8.58 0 8.73C0 8.74 0 8.75 0 8.75C0 8.82 0.01 8.88 0.04 8.93C0.04 8.94 0.04 8.94 0.04 8.94C0.07 9 0.1 9.04 0.14 9.09C0.15 9.1 0.16 9.11 0.18 9.12C0.21 9.15 0.25 9.18 0.29 9.2C0.3 9.2 0.32 9.21 0.34 9.22C0.39 9.24 0.45 9.25 0.52 9.26C0.62 9.26 1.14 9.26 2.08 9.26C2.08 15.2 2.08 18.5 2.08 19.16C2.08 19.45 2.32 19.68 2.6 19.68C2.66 19.68 3.07 19.68 3.13 19.68C3.41 19.68 3.65 19.45 3.65 19.16C3.65 18.5 3.65 15.2 3.65 9.25L5.21 9.25C5.21 12.7 5.21 14.61 5.21 14.99C5.21 15.28 5.45 15.51 5.73 15.51C5.79 15.51 6.2 15.51 6.26 15.51C6.54 15.51 6.78 15.28 6.78 14.99C6.78 14.61 6.78 12.7 6.78 9.25L17.21 9.25L17.21 12.38C17.21 13.95 17.21 14.81 17.21 14.99C17.21 15.28 17.45 15.51 17.74 15.51C17.79 15.51 18.21 15.51 18.26 15.51C18.55 15.51 18.78 15.28 18.78 14.99C18.78 14.61 18.78 12.7 18.78 9.25L20.34 9.25L20.34 16.55C20.34 18.12 20.34 18.98 20.34 19.16C20.34 19.45 20.58 19.68 20.87 19.68C20.92 19.68 21.34 19.68 21.39 19.68C21.68 19.68 21.91 19.45 21.91 19.16C21.91 18.5 21.91 15.2 21.91 9.25C22.85 9.25 23.37 9.25 23.48 9.25C23.54 9.25 23.6 9.24 23.66 9.22C23.67 9.21 23.69 9.2 23.71 9.19C23.75 9.17 23.78 9.15 23.82 9.12C23.83 9.11 23.84 9.1 23.86 9.09C23.89 9.04 23.93 9 23.95 8.95C23.95 8.94 23.95 8.94 23.96 8.93C23.98 8.88 23.99 8.82 23.99 8.75C23.99 8.75 24 8.74 24 8.73C24 8.58 24 7.33 24 7.17C24 7.16 23.99 7.15 23.99 7.14C23.98 7.01 23.93 6.9 23.85 6.81Z"></path>
          </SvgIcon>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pr="8px"
          justifyContent="flex-end"
        >
          <Typography component="span" className={classes.infoIconText}>
            {personsNo}
          </Typography>
          <GroupIcon />
        </Box>
      </Box>
    </Box>
  );
};
