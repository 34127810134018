import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import { AddedTable } from '@gts-ft/utils';
import { execUpdateTable } from '../../actions';
import { TableEdit } from '../../components/TableSettings/TableEdit';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { TABLE_SETTINGS_EDIT_VIEW } from '../../constants';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';

const matchSelector = createMatchSelector<RouterRootState, { tableId: string }>(
  TABLE_SETTINGS_EDIT_VIEW,
);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const tableId = match ? match.params.tableId : undefined;
  const tablesState = state.tables;

  const selectedTable = tablesState.entities[tableId ?? ''];

  // tableId could be defined but still not existent
  // so check to make sure we found a table
  if (selectedTable) {
    const denormalizedTables = denormalize(tablesState);
    const sortedRealTables = sortTablesByTableNo(
      denormalizedTables.filter((table) => !table.isVirtual),
    );

    return {
      table: selectedTable,
      realTables: sortedRealTables,
      isOnline: state.app.isOnline,
    };
  } else {
    return {
      table: undefined,
      realTables: [],
      isOnline: state.app.isOnline,
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execUpdateTable(
    tableId: string,
    tableUpdate: Omit<AddedTable, 'tableId' | 'isVirtual'>,
    isVirtual: boolean,
  ) {
    dispatch(execUpdateTable(tableId, tableUpdate, isVirtual));
  },
});

export const TableEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableEdit);
