// Router views
export const HOME_VIEW = '/';

export const RESERVATIONS_VIEW = '/reservations';
export const RESERVATIONS_TODAY_VIEW = `${RESERVATIONS_VIEW}/today`;
export const RESERVATIONS_ALL_VIEW = `${RESERVATIONS_VIEW}/all`;
export const RESERVATIONS_ADD_VIEW = `${RESERVATIONS_VIEW}/add`;
export const RESERVATIONS_DETAILS_PATH = `${RESERVATIONS_VIEW}/details`;
export const RESERVATIONS_DETAILS_VIEW = `${RESERVATIONS_DETAILS_PATH}/:reservationId`;
export const RESERVATIONS_EDIT_PATH = `${RESERVATIONS_VIEW}/edit`;
export const RESERVATIONS_EDIT_VIEW = `${RESERVATIONS_EDIT_PATH}/:reservationId`;

export const TABLES_VIEW = '/tables';
export const TABLES_BOOKING_STATUS_VIEW = `${TABLES_VIEW}/status`;
export const TABLES_WALK_IN_VIEW = `${TABLES_VIEW}/walk-in`;
export const TABLES_UNBLOCK_VIEW = `${TABLES_VIEW}/unblock`;

export const TABLE_SETTINGS_VIEW = '/table-settings';
export const TABLE_SETTINGS_LIST_VIEW = `${TABLE_SETTINGS_VIEW}/list`;
export const TABLE_SETTINGS_ADD_VIRTUAL_VIEW = `${TABLE_SETTINGS_VIEW}/add-virtual`;
export const TABLE_SETTINGS_ADD_REAL_VIEW = `${TABLE_SETTINGS_VIEW}/add-real`;
export const TABLE_SETTINGS_EDIT_PATH = `${TABLE_SETTINGS_VIEW}/edit`;
export const TABLE_SETTINGS_EDIT_VIEW = `${TABLE_SETTINGS_EDIT_PATH}/:tableId`;

export const RESTAURANT_RESERVATION_TIMES_VIEW = '/reservation-times';
export const RESTAURANT_SETTINGS_VIEW = '/restaurant-settings';

export const SERVICE_AREAS_VIEW = '/service-areas';
export const SERVICE_AREAS_LIST_VIEW = `${SERVICE_AREAS_VIEW}/list`;
export const SERVICE_AREAS_ADD_VIEW = `${SERVICE_AREAS_VIEW}/add`;
export const SERVICE_AREAS_EDIT_PATH = `${SERVICE_AREAS_VIEW}/edit`;
export const SERVICE_AREAS_EDIT_VIEW = `${SERVICE_AREAS_EDIT_PATH}/:serviceAreaId`;

export const SETUP_WIZARD_VIEW = '/setup';

/*
 * A reservation is OVERDUE if it is not arrived or Cancelled and the
 * reservation datetime is later than 'now'.
 * A reservation is UPCOMING XX minutes before it is OVERDUE (depends on table_status_reserved_time)
 * and the reservation is not arrived or Cancelled
 * A reservation is NOT_UPCOMING in all other cases
 * If a reservation has the cancelledAt property set then it is always CANCELLED
 *
 * The what 'now' is depends on the code getting the status
 */
export enum ReservationStatus {
  CANCELLED = 'CANCELLED',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING',
  NOT_UPCOMING = 'NOT_UPCOMING',
}
