import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { View } from '@gts-common/client';
import { AddedTable, AddRealTableRequestBody } from '@gts-ft/utils';
import {
  RestaurantReservationTimesState,
  RestaurantSettingsState,
} from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { FileUploaderAction } from '../UI-Components';
import { WidgetStep } from './WidgetStep/WidgetStep';
import { TableStep } from './TableStep/TableStep';
import { ReservationTimesStep } from './ReservationTimes/ReservationTimesStep';
import { WidgetLogoStep } from './WidgetLogoStep';
import { NavButtons } from './NavButtons';

interface Props {
  tables: Array<
    Pick<AddedTable, 'tableNo' | 'personsNo' | 'minPersons' | 'visibleInWidget'>
  >;
  settings: RestaurantSettingsState;
  reservationTimes: RestaurantReservationTimesState;
  currentStep: number;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
  execRestaurantSettingsChange: (
    restaurantSettings: RestaurantSettingsState,
  ) => void;
  execRestaurantReservationTimesChange: (
    reservationTimes: RestaurantReservationTimesState,
    action: Actions,
  ) => void;
  execBulkAddTables: (
    tables: Array<AddRealTableRequestBody>,
    action: Actions,
  ) => void;
  execCompleteSetup: () => void;
  goToPreviousStep: () => void;
}

export const Steps = ({
  tables,
  settings,
  reservationTimes,
  execDataLoadFailed,
  execRestaurantSettingsChange,
  execRestaurantReservationTimesChange,
  execBulkAddTables,
  execCompleteSetup,
  currentStep,
  goToPreviousStep,
  execUpdateLogoFilename,
}: Props) => {
  return (
    <View hasBackButton={false} title="Ersteinrichtung">
      <Stepper activeStep={currentStep} orientation="vertical">
        <Step>
          <StepLabel>Reservierungsformular</StepLabel>
          <StepContent>
            <WidgetStep
              settings={settings}
              execRestaurantSettingsChange={execRestaurantSettingsChange}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Tische</StepLabel>
          <StepContent>
            <TableStep tables={tables} execBulkAddTables={execBulkAddTables} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Reservierungszeiten</StepLabel>
          <StepContent>
            <ReservationTimesStep
              reservationTimes={reservationTimes}
              execRestaurantReservationTimesChange={
                execRestaurantReservationTimesChange
              }
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Logo</StepLabel>
          <StepContent>
            <WidgetLogoStep
              logoFilename={settings.logoFilename}
              execDataLoadFailed={execDataLoadFailed}
              execUpdateLogoFilename={execUpdateLogoFilename}
            />
            <NavButtons
              backClicked={goToPreviousStep}
              nextButtonLabel="Abschließen"
              nextClicked={execCompleteSetup}
            />
          </StepContent>
        </Step>
      </Stepper>
    </View>
  );
};
