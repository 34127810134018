import { connect } from 'react-redux';

import { App } from '../components/App';
import { ReduxState } from '../types';

const mapStateToProps = (state: ReduxState) => ({
  loginStatus: state.app.loginStatus,
  showOfflineView: state.app.showOfflineView,
});

export const AppContainer = connect(mapStateToProps)(App);
