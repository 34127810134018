import { addMinutes, isAfter } from 'date-fns';
import { getMinutesFromDecimal } from '@gts-ft/utils';
import { ClientReservation } from '../../../types';

// We use startOfNextInterval because we are only interested in reservations that are still active in the next reservation interval and not the one we are currently in. If we would consider the current interval and datetime is for example 17:58 then we would return a reservation that ends (time + duration) at 18:00 but that reservation is no longer relevant for the interval we want to reserve for (18:00 + reservation duration)
export function getReservationsAfterDate(
  reservations: Array<ClientReservation>,
  startOfNextInterval: Date,
) {
  return reservations.filter((reservation) => {
    if (reservation.cancelledAt !== null) {
      return false;
      // If the reservation will become active 6 hours from now then ignore it
      // If you change the 6 hours here you also need to adjust the server accordingly
    } else if (
      isAfter(reservation.datetime, addMinutes(startOfNextInterval, 359))
    ) {
      return false;
    } else {
      const timePlusDuration = addMinutes(
        reservation.datetime,
        getMinutesFromDecimal(reservation.reservationDuration),
      );

      return isAfter(timePlusDuration, startOfNextInterval);
    }
  });
}
