import { ErrorModal } from '@gts-common/client';
import { Action } from 'redux';
import { DialogContentText } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  onDialogAction: (action?: Action) => void;
}

export const CouldNotStoreOfflineDataModal = ({
  isOpen,
  onDialogAction,
}: Props) => {
  return (
    <ErrorModal isOpen={isOpen} onDialogAction={onDialogAction}>
      <DialogContentText>
        Achtung: die Reservierungsdaten können aktuell nicht lokal vorgehalten
        werden.
      </DialogContentText>
      <DialogContentText>
        Was dies bedeutet: Sie können grundsätzlich ganz normal weiterarbeiten.
        Sollte aber in Zukunft die Internetverbindung ausfallen oder
        Wartungsarbeiten auf Seiten von free-table stattfinden, können Sie die
        bestehenden Reservierungen nicht sehen bis die Internetverbindung wieder
        besteht oder die Wartungsarbeiten abgeschlossen sind.
      </DialogContentText>
      <DialogContentText>
        Was kann ich machen, um das Problem zu beheben:
      </DialogContentText>
      <DialogContentText>
        Bitte verwenden Sie einen der folgenden Browser:
      </DialogContentText>
      <ul>
        <li>Firefox</li>
        <li>Chrome</li>
        <li>Safari (Version 11.4 oder größer)</li>
        <li>Edge (Version 17 oder größer)</li>
      </ul>
      <DialogContentText>
        Falls Sie bereits einen der genannten Browser benutzen, prüfen Sie
        bitte, ob Sie aktuell den Inkognito-Modus des Browsers verwenden oder ob
        Sie das Speichern von Daten in den Einstellungen eingeschränkt haben.
      </DialogContentText>
      <DialogContentText>
        Sie können diese Seite neu laden. Sollte diese Warnung nach dem Neuladen
        der Seite nicht erneut angezeigt werden, ist alles in Ordnung.
      </DialogContentText>
      <DialogContentText>
        Sollte die Warnung erneut angezeigt werden, prüfen Sie bitte die
        Einstellungen in Ihrem Browser, ob das Speichern von Daten eingeschränkt
        ist.
      </DialogContentText>
    </ErrorModal>
  );
};
