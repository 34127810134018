import { initialState } from '../initialState';
import {
  Actions,
  SERVICE_AREA_ADDED,
  SERVICE_AREA_DELETED,
  SERVICE_AREA_UPDATED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { ServiceAreasState } from '../types';

export function serviceAreasReducer(
  state = initialState.serviceAreas,
  action: Actions,
): ServiceAreasState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload.serviceAreas,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        ...action.payload.serviceAreas,
      };

    // Reset all data
    case LOGOUT_SUCCEEDED:
      return initialState.serviceAreas;

    case SERVICE_AREA_ADDED:
      return {
        ...state,
        items: [...state.items, ...action.payload.items],
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
      };

    case SERVICE_AREA_UPDATED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.serviceAreaId]: {
            ...state.entities[action.payload.serviceAreaId],
            ...action.payload.serviceArea,
          },
        },
      };

    case SERVICE_AREA_DELETED:
      return {
        ...state,
        items: state.items.filter(
          (serviceAreaId) => serviceAreaId !== action.payload,
        ),
      };

    default:
      return state;
  }
}
