import { MouseEvent } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: '50%',
    top: '-10px',
    right: '-10px',
    display: 'flex',
    padding: '2px',
  },
});

interface DeleteFileBtnProps {
  onRemove: (e: MouseEvent) => void;
}

export const DeleteFileBtn = ({ onRemove }: DeleteFileBtnProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton color="secondary" size="small" onClick={onRemove}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};
