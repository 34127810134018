import { AddedTable } from '@gts-ft/utils';
import {
  ClientReservation,
  ClientReservationWithExtraProperties,
} from '../../../types';
import { getReservationStatus } from './getReservationStatus';

export function addTableNumbersAndStatus(
  reservations: Array<ClientReservation>,
  tables: Record<string, AddedTable>,
  datetime: Date,
  tableStatusReservedTime: number,
): Array<ClientReservationWithExtraProperties> {
  return reservations.map((reservation) => {
    return {
      ...reservation,
      isCancelButtonDisabled:
        reservation.wasAnonymized || reservation.cancelledAt !== null,
      isEditButtonDisabled:
        reservation.wasAnonymized || reservation.cancelledAt !== null,
      tableNos: reservation.tableIds.map((tableId) => tables[tableId].tableNo),
      status: getReservationStatus(
        reservation,
        datetime,
        tableStatusReservedTime,
      ),
    };
  });
}
