import {
  RESTAURANT_SETTINGS_PATH,
  EditRestaurantSettingsRequestBody,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  createPartial,
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import {
  Actions,
  RESTAURANT_SETTINGS_UPDATED,
  RestaurantSettingsUpdatedAction,
  Thunk,
  UPDATE_LOGO_FILENAME,
  UpdatedLogoFilenameAction,
} from '../reduxActionTypes';

import { RestaurantSettingsState } from '../../types';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { prepareRestaurantSettingsForServer } from './helpers/prepareRestaurantSettingsForServer';

function restaurantSettingsChangeSucceeded(
  partialRestaurantSettings: Partial<RestaurantSettingsState>,
): RestaurantSettingsUpdatedAction {
  return {
    type: RESTAURANT_SETTINGS_UPDATED,
    payload: partialRestaurantSettings,
  };
}

function updateRestaurantSettingsOfflineDB(
  restaurantId: string,
  update: RestaurantSettingsState,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.updateRecord('restaurant', restaurantId, {
        settings: update,
      });
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execRestaurantSettingsChange(
  restaurantSettingsToUpdate: Partial<RestaurantSettingsState>,
  actionToDispatchAfterSuccess?: Actions,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const restaurantId = getState().restaurant.restaurantId;
    const originalData = getState().restaurant.settings;

    // Add the logo filename here so that creating the partial
    // object removes it. It can be changed by the file uploader
    // but we don't want to update it here
    const partial = createPartial(originalData, {
      ...restaurantSettingsToUpdate,
      logoFilename: originalData.logoFilename,
    });

    if (Object.keys(partial).length !== 0) {
      const dataToSend: EditRestaurantSettingsRequestBody = prepareRestaurantSettingsForServer(
        partial,
      );

      dispatch(startServerRequest());

      return serverComm
        .execPatchRequest<
          Record<string, never>,
          EditRestaurantSettingsRequestBody
        >(RESTAURANT_SETTINGS_PATH, dataToSend)
        .then(
          (resp) => {
            if (resp.succeeded === true) {
              dispatch(serverRequestSucceeded('Einstellungen aktualisiert.'));
              dispatch(restaurantSettingsChangeSucceeded(dataToSend));
              dispatch(
                updateRestaurantSettingsOfflineDB(restaurantId, {
                  ...originalData,
                  ...dataToSend,
                }),
              );
              if (actionToDispatchAfterSuccess) {
                dispatch(actionToDispatchAfterSuccess);
              }
            } else {
              dispatch(
                serverRequestFailed(getClientOperationFailureReason(resp)),
              );
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    } else {
      if (actionToDispatchAfterSuccess) {
        dispatch(actionToDispatchAfterSuccess);
      }
    }
  };
}

// Filename is null if the logo is removed
export function updateLogoFilename(
  newFilename: string | null,
): UpdatedLogoFilenameAction {
  return {
    type: UPDATE_LOGO_FILENAME,
    payload: newFilename,
  };
}
