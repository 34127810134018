import { trimData } from '@gts-common/client';
import { RestaurantSettingsState } from '../../../types';

const whatToTrim: Array<keyof RestaurantSettingsState> = [
  'restaurantName',
  'reservationText',
  'widgetRestaurantInfoText',
];

// Restaurant settings is possibly just a partial
export function prepareRestaurantSettingsForServer(
  restaurantSettings: Partial<RestaurantSettingsState>,
) {
  return trimData(whatToTrim, restaurantSettings);
}
