import { MobileDatePicker } from '@material-ui/pickers';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import { useState } from 'react';
import { PickerVariantType } from './types';
import { PickerVariant } from './PickerVariant';

interface DatePickerProps {
  variant: PickerVariantType;
  value: Date | null;
  onAccept?: (newDate: Date | null) => void;
  onChange?: (newDate: Date | null) => void;
  label: string;
  disabled: boolean;
}

export const DatePicker = ({
  variant,
  value,
  onChange,
  onAccept,
  label,
  disabled,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <MobileDatePicker
      label={label}
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(newDate) => {
        if (onChange) {
          onChange(newDate);
        }
      }}
      onAccept={(newDate) => {
        if (onAccept) {
          onAccept(newDate);
        }
      }}
      disabled={disabled}
      renderInput={(props) => {
        if (props.inputProps) {
          return (
            <PickerVariant
              type="date"
              variant={variant}
              label={label}
              value={props.inputProps.value}
              onClick={() => setOpen(true)}
              disabled={disabled}
            />
          );
        } else {
          return <div></div>;
        }
      }}
      inputFormat={CLIENT_DATE_FORMAT}
      mask="__.__.____"
      disableCloseOnSelect={false}
    />
  );
};
