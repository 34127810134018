import { TABLES_PATH, TABLES_VIRTUAL_PATH } from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  ExtraModalMessage,
  MessageType,
  ModalType,
  execOpenMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  TABLE_DELETED,
  TableDeletedAction,
  Thunk,
} from '../reduxActionTypes';

import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { TABLE_SETTINGS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';

function deleteTableSucceeded(tableId: string): TableDeletedAction {
  return {
    type: TABLE_DELETED,
    payload: tableId,
  };
}

function deleteTableOfflineDB(tableId: string): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.deleteRecord('table', tableId);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execDeleteTable(
  tableId: string,
  isVirtual: boolean,
): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    return serverComm
      .execDeleteRequest(
        `${isVirtual ? TABLES_VIRTUAL_PATH : TABLES_PATH}/${tableId}`,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(serverRequestSucceeded('Tisch gelöscht.'));
            dispatch(deleteTableSucceeded(tableId));
            dispatch(deleteTableOfflineDB(tableId));
            dispatch(execReplace(TABLE_SETTINGS_LIST_VIEW));
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execDeleteTableDecision(tableId: string, isVirtual: boolean) {
  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: ModalType.DECISION_MODAL,
    title: 'Tisch löschen?',
    body:
      'Wollen Sie den Tisch wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
    extraProps: {
      confirmAction: execDeleteTable(tableId, isVirtual),
    },
  };

  return execOpenMessage(modal);
}
