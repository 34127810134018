import { MouseEvent } from 'react';
import {
  Grid,
  IconButton,
  withStyles,
  Typography,
  ListItemSecondaryAction,
  createStyles,
  ListItem,
  Theme,
  Tooltip,
  WithStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { getListItemClasses } from '../UI-Components/helpers/listItemClasses';
import { tableStatusClassesWithHover } from '../Tables/helpers/tableStatusClassesWithHover';

const styles = (theme: Theme) =>
  createStyles({
    table: {
      ...getListItemClasses(theme),
      marginBottom: '8px',
    },

    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  });

function getPersonText(personsNo: number) {
  if (personsNo === 1) {
    return 'Person';
  } else {
    return 'Personen';
  }
}
function getPersonsDisplay(minPersons: number, personsNo: number) {
  return minPersons === personsNo
    ? `${personsNo}`
    : `${minPersons} bis ${personsNo}`;
}

interface Props
  extends WithStyles<typeof styles & typeof tableStatusClassesWithHover> {
  tableNo: string;
  personsNo: number;
  minPersons: number;
  visibleInWidget: boolean;
  isVirtual: boolean;
  realTableNos: Array<string>;
  isOnline: boolean;
  onEdit: (event: MouseEvent) => void;
  onDelete: (event: MouseEvent) => void;
}

const UnstyledTable = ({
  tableNo,
  personsNo,
  minPersons,
  visibleInWidget,
  isOnline,
  onEdit,
  onDelete,
  classes,
  realTableNos,
  isVirtual,
}: Props) => {
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ListItem divider className={classes.table}>
      <Grid container direction={isXsOrSmaller ? 'column' : 'row'}>
        <Grid item xs={2}>
          <Typography>{tableNo} </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography display="inline">
            {`${getPersonsDisplay(minPersons, personsNo)} `}
          </Typography>
          <Typography display="inline">{getPersonText(personsNo)}</Typography>
        </Grid>
        {isVirtual && (
          <Grid item xs={4}>
            <Typography display="inline">
              {realTableNos.length > 1 ? 'Tische: ' : 'Tisch: '}
            </Typography>
            <Typography display="inline">{realTableNos.join(', ')}</Typography>
          </Grid>
        )}
      </Grid>
      <ListItemSecondaryAction>
        {!visibleInWidget && (
          <Tooltip title="Dieser Tisch kann nicht von Kunden selbst reserviert werden.">
            <IconButton>
              <PersonAddDisabled />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Diesen Tisch bearbeiten.">
          <IconButton onClick={onEdit} disabled={!isOnline}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Diesen Tisch löschen.">
          <IconButton onClick={onDelete} disabled={!isOnline}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const Table = withStyles(styles)(UnstyledTable);
