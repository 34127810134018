import { tableValidation, virtualTableValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { View, NotFoundText } from '@gts-common/client';
import { AddedTable } from '@gts-ft/utils';
import { TABLE_SETTINGS_LIST_VIEW } from '../../constants';
import { TableForm } from './TableForm';

interface Props {
  realTables: Array<AddedTable>;
  table: AddedTable | undefined;
  execUpdateTable: (
    tableId: string,
    table: Omit<AddedTable, 'tableId' | 'isVirtual'>,
    isVirtual: boolean,
  ) => void;
  isOnline: boolean;
}

export const TableEdit = ({
  table,
  realTables = [],
  execUpdateTable,
  isOnline,
}: Props) => {
  if (!table) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={TABLE_SETTINGS_LIST_VIEW}
        backButtonLabel="Tische verwalten"
        title="Tisch bearbeiten"
      >
        <NotFoundText>
          Der Tisch existiert nicht. Bitte überprüfen Sie den Link und versuchen
          Sie es erneut.
        </NotFoundText>
      </View>
    );
  }

  return (
    <View
      hasBackButton={true}
      navigateBackTo={TABLE_SETTINGS_LIST_VIEW}
      backButtonLabel="Tische verwalten"
      title={
        table.isVirtual ? 'Tischkombination bearbeiten' : 'Tisch bearbeiten'
      }
    >
      <Formik
        onSubmit={(values) =>
          execUpdateTable(table.tableId, values, table.isVirtual)
        }
        initialValues={table}
        validationSchema={
          table.isVirtual ? virtualTableValidation : tableValidation
        }
      >
        <TableForm
          realTables={realTables}
          isVirtual={table.isVirtual}
          isEdit={true}
          isOnline={isOnline}
        />
      </Formik>
    </View>
  );
};
