import { serverStatusErrors } from '../validationMessages';
function getOperationFailureReason(resp, context) {
    const reason = serverStatusErrors[resp.body.status];
    if (typeof reason === 'string') {
        return reason;
    }
    else if (typeof reason === 'undefined') {
        return 'Es gab einen Fehler. Bitte versuchen Sie es erneut. Falls das nicht funktioniert, kontaktieren Sie bitte den Support.';
    }
    else {
        return reason[context];
    }
}
export function getClientOperationFailureReason(resp) {
    return getOperationFailureReason(resp, 'client');
}
export function getWidgetOperationFailureReason(resp) {
    return getOperationFailureReason(resp, 'widget');
}
