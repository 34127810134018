import { isWithinInterval } from 'date-fns';
import { ClientReservation } from '../../../types';

// Note that the returned reservations include startDatetime and endDatetime
export function getReservationsForInterval(
  reservations: Array<ClientReservation>,
  startDatetime: Date,
  endDatetime: Date,
  skipCancelled: boolean,
) {
  return reservations.filter((reservation) => {
    if (skipCancelled && reservation.cancelledAt !== null) {
      return false;
    }

    return isWithinInterval(reservation.datetime, {
      start: startDatetime,
      end: endDatetime,
    });
  });
}
