import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NumberField, SelectField } from '@gts-common/client';

import { getTableReservedTimeUnits } from '../helpers/getTableReservedTimeUnits';

export const ClientSettings = () => (
  <Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">Anwendung</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumberField
            label="Reservierungsdauer (Std.)"
            name="reservationDuration"
            hint="Gibt an, wie lange die Reservierungsdauer für ein Tisch über das Reservierungsformular ist. Beispiel: Sind 2 Stunden eingestellt, wird ein Tisch von 20:00 bis 21:59 über das Reservierungsformular reserviert. Die nächste Reservierung an dem Tisch kann erst um 22 Uhr erfolgen."
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            key="tableStatusReservedTime"
            label="Vorankündigung in gelb"
            name="tableStatusReservedTime"
            hint="Wie lange vor einer Reservierung, soll der gelbe Vorankündigungsstatus angezeigt werden? Die gelbe Farbe dient als Hinweis, dass demnächst der Tisch belegt sein wird."
            options={getTableReservedTimeUnits()}
            noResultsText=""
            required
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);
