import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import deLocale from 'date-fns/locale/de';

import {
  serviceTheme,
  execOpenMessage,
  MessageType,
  ModalType,
  setupGlobalErrorHandler,
  GlobalErrorCatcher,
} from '@gts-common/client';

import { ConnectedRouter } from 'connected-react-router';
import { serverComm } from '@gts-ft/ui';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { registerServiceWorker } from './serviceWorkerRegistration';

import { history, store } from './store';
import { AppContainer } from './containers/App';
import { checkOnlineStatus } from './actions/online';
import logo from './images/logo.png';

setupGlobalErrorHandler(serverComm, store);

// Redux doesn't understand thunk actions
// Convince it that it is okay to use that here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(checkOnlineStatus() as any);

registerServiceWorker({
  onUpdate(registration: ServiceWorkerRegistration) {
    store.dispatch(
      execOpenMessage({
        type: MessageType.MODAL,
        modalType: ModalType.RELOAD_MODAL,
        title: 'free-table aktualisiert',
        body:
          'Es gibt eine neue Version von free-table. Bitte laden Sie die Seite neu, um die neue Version zu nutzen.',
        extraProps: {
          serviceWorkerRegistration: registration,
        },
      }),
    );
  },
  onFailure() {
    store.dispatch(
      execOpenMessage({
        type: MessageType.MODAL,
        modalType: 'ServiceWorkerNotInstalledModal',
      }),
    );
  },
});

function renderRoot() {
  const Root = () => (
    <GlobalErrorCatcher
      serverComm={serverComm}
      headerTitle="free-table"
      logo={logo}
      domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)}
    >
      <CssBaseline />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={serviceTheme}>
            <LocalizationProvider dateAdapter={DateFnsUtils} locale={deLocale}>
              <AppContainer />
            </LocalizationProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </GlobalErrorCatcher>
  );

  ReactDOM.render(<Root />, document.getElementById('root'));
}

renderRoot();

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    renderRoot();
  });
}
