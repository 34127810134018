import { MouseEvent } from 'react';
import {
  Grid,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  withStyles,
  createStyles,
  ListItem,
  Theme,
  Tooltip,
  WithStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { getListItemClasses } from '../UI-Components/helpers/listItemClasses';
import { tableStatusClassesWithHover } from '../Tables/helpers/tableStatusClassesWithHover';

const styles = (theme: Theme) =>
  createStyles({
    serviceArea: {
      ...getListItemClasses(theme),
      marginBottom: '8px',
    },

    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  });

interface Props
  extends WithStyles<typeof styles & typeof tableStatusClassesWithHover> {
  name: string;
  tableNos: Array<string>;
  onEdit: (event: MouseEvent) => void;
  onDelete: (event: MouseEvent) => void;
  isOnline: boolean;
}

const UnstyledServiceArea = ({
  name,
  tableNos,
  isOnline,
  onEdit,
  onDelete,
  classes,
}: Props) => (
  <ListItem divider className={classes.serviceArea}>
    <Grid container>
      <Grid item xs={2}>
        <Typography>{name} </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography display="inline">
          {tableNos.length > 1 ? 'Tische ' : 'Tisch '}
        </Typography>
        <Typography display="inline">{tableNos.join(', ')}</Typography>
      </Grid>
      <Grid item xs={4}>
        <ListItemSecondaryAction>
          <Tooltip title="Diesen Servicebereich bearbeiten.">
            <IconButton onClick={onEdit} disabled={!isOnline}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Diesen Servicebereich löschen.">
            <IconButton onClick={onDelete} disabled={!isOnline}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </Grid>
    </Grid>
  </ListItem>
);

export const ServiceArea = withStyles(styles)(UnstyledServiceArea);
