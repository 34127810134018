import * as yup from 'yup';
import { MAX_MAX_PERSONS_IN_WIDGET, MAX_TABLE_STATUS_RESERVED_TIME, MIN_LEADTIME, MIN_MAX_PERSONS_IN_WIDGET, MIN_MAX_RESERVATIONS_AT_GIVEN_TIME, MIN_PERSONS_NO, MIN_RESERVATION_DURATION, MAX_RESERVATION_DURATION, MIN_RESERVATION_IN_ADVANCED, MIN_TABLE_STATUS_RESERVED_TIME, TABLE_STATUS_RESERVED_TIME_STEP, MAX_OPENING_TIME, MIN_OPENING_TIME, MAX_COMPANY_NAME_LENGTH, MAX_RESERVATION_NAME_LENGTH, MAX_TELEPHONE_NUMBER_LENGTH, MAX_REMARK_LENGTH, MAX_RESTAURANT_NAME_LENGTH, MAX_RESTAURANT_RESERVATION_TEXT_LENGTH, MAX_WIDGET_RESTAURANT_INFO_TEXT_LENGTH, MAX_SERVICE_AREA_NAME_LENGTH, MAX_TABLE_NO_LENGTH, } from '@gts-ft/utils';
import { TELEPHONE_REGEX, ID_REGEX, CLIENT_DATE_FORMAT_REGEX, EMAIL_REGEX, MAX_EMAIL_LENGTH, } from '@gts-common/client-server';
import { emailFormatValidationMessage, getGreaterThanEqualValidationMessage, getGreaterThanValidationMessage, getLessThanEqualValidationMessage, getMaxLengthValidationMessage, requiredFieldValidationMessage, telephoneFormatValidationMessage, } from '@gts-common/client';
import { quarterHours, minPersonsSmallerEqualPersons, manualTableAllocationValidationMessage, } from './validationMessages';
function smallerEqualTo(otherKey, message) {
    return this.test({
        name: 'smallerEqualTo',
        exclusive: false,
        message,
        test(value) {
            if (value !== undefined) {
                return value <= this.parent[otherKey];
            }
            else {
                return false;
            }
        },
    });
}
function isMultiple(base, message) {
    return this.test({
        name: 'isMultiple',
        exclusive: false,
        message,
        test(value) {
            if (value !== undefined) {
                return value % base === 0;
            }
            else {
                return false;
            }
        },
    });
}
yup.addMethod(yup.number, 'smallerEqualTo', smallerEqualTo);
yup.addMethod(yup.number, 'isMultiple', isMultiple);
// Note that even though we trim here we need to trim again before we send the
// data because formik doesn't use the trimmed value. It is only used for validation
const email = yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, emailFormatValidationMessage)
    .max(MAX_EMAIL_LENGTH, getMaxLengthValidationMessage(MAX_EMAIL_LENGTH));
const telephone = yup
    .string()
    .trim()
    .max(MAX_TELEPHONE_NUMBER_LENGTH)
    .matches(TELEPHONE_REGEX, telephoneFormatValidationMessage);
const id = yup.string().matches(ID_REGEX);
const reservationValidation = {
    personsNo: yup
        .number()
        .min(MIN_PERSONS_NO, getGreaterThanEqualValidationMessage(MIN_PERSONS_NO))
        .required(requiredFieldValidationMessage),
    date: yup
        .string()
        .matches(CLIENT_DATE_FORMAT_REGEX)
        .required(requiredFieldValidationMessage),
    name: yup
        .string()
        .trim()
        .max(MAX_RESERVATION_NAME_LENGTH)
        .required(requiredFieldValidationMessage),
    email,
    telephone,
    time: yup.string().required(requiredFieldValidationMessage),
    reservationDuration: yup
        .number()
        .min(MIN_RESERVATION_DURATION, getGreaterThanValidationMessage(MIN_RESERVATION_DURATION))
        .max(MAX_RESERVATION_DURATION, getLessThanEqualValidationMessage(MAX_RESERVATION_DURATION))
        .required(requiredFieldValidationMessage),
    remark: yup.string().max(MAX_REMARK_LENGTH).trim(),
    company: yup.string().max(MAX_COMPANY_NAME_LENGTH).trim(),
    manualTableAllocation: yup.boolean(),
};
export const reservationAddValidation = yup.object(Object.assign({ tableIds: yup
        .array()
        .of(id)
        .when('manualTableAllocation', {
        is: true,
        then: yup
            .array()
            .min(1, manualTableAllocationValidationMessage)
            .required(requiredFieldValidationMessage),
        otherwise: yup.array().max(1),
    }) }, reservationValidation));
export const reservationUpdateValidation = yup.object(Object.assign({ tableIds: yup
        .array()
        .of(id)
        .when('manualTableAllocation', {
        is: true,
        then: yup
            .array()
            .min(1, manualTableAllocationValidationMessage)
            .required(requiredFieldValidationMessage),
        otherwise: yup
            .array()
            .length(1, requiredFieldValidationMessage)
            .required(requiredFieldValidationMessage),
    }) }, reservationValidation));
export const widgetReservationValidation = yup.object({
    tableIds: yup.array().of(id).max(1),
    // Don't validate min here because we use -1 to know when to display the 'max persons in widget warning'
    personsNo: yup.number().required(requiredFieldValidationMessage),
    date: yup
        .string()
        .matches(CLIENT_DATE_FORMAT_REGEX)
        .required(requiredFieldValidationMessage),
    name: yup
        .string()
        .trim()
        .max(MAX_RESERVATION_NAME_LENGTH)
        .required(requiredFieldValidationMessage),
    email: email.required(requiredFieldValidationMessage),
    telephone: telephone.required(requiredFieldValidationMessage),
    time: yup.string().required(requiredFieldValidationMessage),
    remark: yup.string().max(MAX_REMARK_LENGTH).trim(),
    acceptedPrivacy: yup.bool().oneOf([true], requiredFieldValidationMessage),
});
const tableFields = {
    personsNo: yup
        .number()
        .min(MIN_PERSONS_NO, getGreaterThanEqualValidationMessage(MIN_PERSONS_NO))
        .required(requiredFieldValidationMessage),
    minPersons: yup.number()
        .smallerEqualTo('personsNo', minPersonsSmallerEqualPersons)
        .min(MIN_PERSONS_NO, getGreaterThanEqualValidationMessage(MIN_PERSONS_NO))
        .required(requiredFieldValidationMessage),
    tableNo: yup
        .string()
        .trim()
        .max(MAX_TABLE_NO_LENGTH)
        .required(requiredFieldValidationMessage),
    visibleInWidget: yup.boolean().required(requiredFieldValidationMessage),
};
export const tableValidation = yup.object(tableFields);
export const virtualTableValidation = yup.object(Object.assign(Object.assign({}, tableFields), { tableIds: yup
        .array()
        .of(id)
        .min(1, requiredFieldValidationMessage)
        .required(requiredFieldValidationMessage) }));
export const serviceAreaValidation = yup.object({
    name: yup
        .string()
        .trim()
        .max(MAX_SERVICE_AREA_NAME_LENGTH)
        .required(requiredFieldValidationMessage),
    tableIds: yup
        .array()
        .of(id)
        .min(1, requiredFieldValidationMessage)
        .required(requiredFieldValidationMessage),
});
export const restaurantSettingsValidation = yup.object({
    restaurantName: yup
        .string()
        .trim()
        .max(MAX_RESTAURANT_NAME_LENGTH)
        .required(requiredFieldValidationMessage),
    maxPersonsInWidget: yup
        .number()
        .min(MIN_MAX_PERSONS_IN_WIDGET, getGreaterThanEqualValidationMessage(MIN_MAX_PERSONS_IN_WIDGET))
        .max(MAX_MAX_PERSONS_IN_WIDGET, getLessThanEqualValidationMessage(MAX_MAX_PERSONS_IN_WIDGET))
        .required(requiredFieldValidationMessage),
    reservationDuration: yup
        .number()
        .min(MIN_RESERVATION_DURATION, getGreaterThanEqualValidationMessage(MIN_RESERVATION_DURATION))
        .max(MAX_RESERVATION_DURATION, getLessThanEqualValidationMessage(MAX_RESERVATION_DURATION))
        .required(requiredFieldValidationMessage),
    reservationLeadtime: yup
        .number()
        .min(MIN_LEADTIME, getGreaterThanEqualValidationMessage(MIN_LEADTIME))
        .required(requiredFieldValidationMessage),
    reservationInAdvancedWeeks: yup
        .number()
        .min(MIN_RESERVATION_IN_ADVANCED, getGreaterThanEqualValidationMessage(MIN_RESERVATION_IN_ADVANCED))
        .required(requiredFieldValidationMessage),
    tableSelectionVisibleInWidget: yup.boolean(),
    tableStatusReservedTime: yup.number()
        .isMultiple(TABLE_STATUS_RESERVED_TIME_STEP, quarterHours)
        .min(MIN_TABLE_STATUS_RESERVED_TIME, getGreaterThanEqualValidationMessage(MIN_TABLE_STATUS_RESERVED_TIME))
        .max(MAX_TABLE_STATUS_RESERVED_TIME, getLessThanEqualValidationMessage(MAX_TABLE_STATUS_RESERVED_TIME))
        .required(requiredFieldValidationMessage),
    reservationText: yup
        .string()
        .trim()
        .max(MAX_RESTAURANT_RESERVATION_TEXT_LENGTH)
        .required(requiredFieldValidationMessage),
    widgetRestaurantInfoText: yup
        .string()
        .trim()
        .max(MAX_WIDGET_RESTAURANT_INFO_TEXT_LENGTH)
        .required(requiredFieldValidationMessage),
    maxReservationsAtGivenTime: yup
        .string()
        .min(MIN_MAX_RESERVATIONS_AT_GIVEN_TIME, getGreaterThanEqualValidationMessage(MIN_MAX_RESERVATIONS_AT_GIVEN_TIME))
        .required(requiredFieldValidationMessage),
});
export const restaurantOpeningTimesValidation = yup.object();
export const reservationHoursValidation = yup.object({
    startDay: yup.number().min(0).max(6).required(requiredFieldValidationMessage),
    startTime: yup
        .number()
        .min(MIN_OPENING_TIME)
        .max(MAX_OPENING_TIME)
        .required(requiredFieldValidationMessage),
    endTime: yup
        .number()
        .min(MIN_OPENING_TIME)
        .max(MAX_OPENING_TIME)
        .required(requiredFieldValidationMessage),
});
export const closedOnDatesValidation = yup.object({
    closedDate: yup
        .string()
        .matches(CLIENT_DATE_FORMAT_REGEX)
        .required(requiredFieldValidationMessage),
});
