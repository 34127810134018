import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { LinkButton, InfoText } from '@gts-common/client';
import {
  HOME_VIEW,
  RESERVATIONS_VIEW,
  RESTAURANT_RESERVATION_TIMES_VIEW,
  RESTAURANT_SETTINGS_VIEW,
  SERVICE_AREAS_VIEW,
  SETUP_WIZARD_VIEW,
  TABLE_SETTINGS_VIEW,
  TABLES_VIEW,
} from '../constants';
import { HomeContainer } from '../containers/Home';
import { RestaurantReservationTimesContainer } from '../containers/RestaurantOpeningTimes';
import { RestaurantSettingsContainer } from '../containers/RestaurantSettings';
import { SetupWizardContainer } from '../containers/SetupWizard';
import { ReservationsRoutes } from './Reservations/ResevationsRoutes';
import { TablesRoutes } from './Tables/TablesRoutes';
import { TableSettingsRoutes } from './TableSettings/TableSettingsRoutes';
import { ServiceAreasRoutes } from './ServiceAreas/ServiceAreasRoutes';

export const MainRouter = () => {
  return (
    <Switch>
      <Route path={SETUP_WIZARD_VIEW}>
        <SetupWizardContainer />
      </Route>
      <Route path={RESERVATIONS_VIEW}>
        <ReservationsRoutes />
      </Route>
      <Route path={TABLES_VIEW}>
        <TablesRoutes />
      </Route>
      <Route path={TABLE_SETTINGS_VIEW}>
        <TableSettingsRoutes />
      </Route>
      <Route path={SERVICE_AREAS_VIEW}>
        <ServiceAreasRoutes />
      </Route>
      <Route path={RESTAURANT_RESERVATION_TIMES_VIEW}>
        <RestaurantReservationTimesContainer />
      </Route>
      <Route path={RESTAURANT_SETTINGS_VIEW}>
        <RestaurantSettingsContainer />
      </Route>
      <Route path={HOME_VIEW} exact={true}>
        <HomeContainer />
      </Route>
      <Route path="*">
        <Box textAlign="center" mt="4rem">
          <InfoText>Pfad nicht gefunden.</InfoText>
          <Box mt="3rem">
            <LinkButton to={HOME_VIEW} label="Zur Startseite" />
          </Box>
        </Box>
      </Route>
    </Switch>
  );
};
