import { ServerComm } from '@gts-common/client';
import { ServiceCode } from '@gts-common/client-server';
import { VERSION_HEADER_KEY } from '@gts-ft/utils';
// REACT_APP_VERSION is defined in the .env files of the client and the widget
const extraHeaders = process.env.REACT_APP_VERSION
    ? {
        [VERSION_HEADER_KEY]: process.env.REACT_APP_VERSION,
    }
    : {};
export const serverComm = new ServerComm(ServiceCode.FREE_TABLE, extraHeaders);
