import { getWidgetUrl } from '@gts-ft/ui';
import { normalize } from '@gts-common/client';
import { LoginResponse, AddedReservation } from '@gts-ft/utils';
import { createRestaurantReservationTimesObjectForClient } from '../restaurant/helpers/createRestaurantReservationTimesObject';
import { prepareReservationForClient } from '../reservations/helpers/prepareReservationForClient';

export function reservationsTransformForClient(
  reservations: Array<AddedReservation>,
) {
  return reservations.map(prepareReservationForClient);
}

export function prepareLoginResponseForClient(
  isSetupCompleted: boolean,
  tables: LoginResponse['tables'],
  reservations: LoginResponse['reservations'],
  restaurant: LoginResponse['restaurant'],
  serviceAreas: LoginResponse['serviceAreas'],
) {
  const normalizedTables = normalize('tableId', tables);
  const normalizedReservations = normalize(
    'reservationId',
    reservationsTransformForClient(reservations),
  );
  const normalizedServiceAreas = normalize('serviceAreaId', serviceAreas);
  const { openingTimes, settings, logoFilename, restaurantId } = restaurant;

  const restaurantReservationTimes = createRestaurantReservationTimesObjectForClient(
    openingTimes,
  );

  return {
    restaurantId,
    isSetupCompleted,
    tables: normalizedTables,
    serviceAreas: normalizedServiceAreas,
    reservations: normalizedReservations,
    restaurantReservationTimes,
    restaurantSettings: { ...settings, logoFilename },
    restaurantRuntimeSettings: {
      reservationDuration: settings.reservationDuration,
      tableStatusReservedTime: settings.tableStatusReservedTime,
      widgetUrl: getWidgetUrl(restaurantId),
    },
  };
}
