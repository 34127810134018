import { Typography } from '@material-ui/core';
import { RESTAURANT_LOGO_PATH } from '@gts-ft/utils';
import { FileUploader, FileUploaderAction } from '../UI-Components';

interface Props {
  logoFilename: string | null;
  execDataLoadFailed: (reason: string) => void;
  execUpdateLogoFilename: (
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) => void;
}

export const WidgetLogoStep = ({
  logoFilename,
  execDataLoadFailed,
  execUpdateLogoFilename,
}: Props) => {
  const fileIds = logoFilename === null ? [] : [logoFilename];

  return (
    <>
      <Typography variant="body2">
        Hier können Sie Ihr Logo hochladen, dass auch im Reservierungsformular
        angezeigt wird. Die Größe des Logos sollte nicht größer als 150px hoch
        sein. PNG und JPG sind als Bildformat für das Logo erlaubt.
      </Typography>
      <FileUploader
        path={RESTAURANT_LOGO_PATH}
        fileIds={fileIds}
        onFailure={(_action, reason) => execDataLoadFailed(reason)}
        onSuccess={execUpdateLogoFilename}
      />
    </>
  );
};
