import {
  AddedServiceArea,
  AddServiceAreaRequestBody,
  AddServiceAreaResponse,
  SERVICE_AREA_PATH,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  trimData,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  execOpenMessage,
  MessageType,
  AlertType,
  normalize,
  Normalized,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  Actions,
  SERVICE_AREA_ADDED,
  ServiceAreaAddedAction,
  Thunk,
} from '../reduxActionTypes';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { clientErrors } from '../../validationMessages';

import { SERVICE_AREAS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';
import { isServiceAreaNameUnique } from './helpers/isServiceAreaNameUnique';

function addServiceAreaSucceeded(
  serviceArea: Normalized<AddedServiceArea>,
): ServiceAreaAddedAction {
  return {
    type: SERVICE_AREA_ADDED,
    payload: serviceArea,
  };
}

function addServiceAreaOfflineDB(
  serviceArea: AddedServiceArea,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.addRecord('serviceArea', serviceArea);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execAddServiceArea(
  serviceArea: AddServiceAreaRequestBody,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const { entities, items } = getState().serviceAreas;

    const dataToSend: AddServiceAreaRequestBody = trimData(
      ['name'],
      serviceArea,
    );

    if (isServiceAreaNameUnique(dataToSend.name, items, entities)) {
      dispatch(startServerRequest());

      return serverComm
        .execPostRequest<AddServiceAreaResponse, AddServiceAreaRequestBody>(
          SERVICE_AREA_PATH,
          dataToSend,
        )
        .then(
          (resp) => {
            if (resp.succeeded === true) {
              const normalizedServiceAreas = normalize(
                'serviceAreaId',
                resp.body,
              );

              dispatch(serverRequestSucceeded('Servicebereich hinzugefügt.'));
              dispatch(addServiceAreaSucceeded(normalizedServiceAreas));
              dispatch(addServiceAreaOfflineDB(resp.body));
              dispatch(execReplace(SERVICE_AREAS_LIST_VIEW));
            } else {
              dispatch(
                serverRequestFailed(getClientOperationFailureReason(resp)),
              );
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    } else {
      dispatch(
        execOpenMessage({
          type: MessageType.ALERT,
          alertType: AlertType.ERROR,
          text: clientErrors.SERVICE_AREA_NAME_EXISTS,
        }),
      );
    }
  };
}
