import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TableStatus } from '@gts-ft/utils';
import { NotReservedTable, TableWithExtraProperties } from '../../../types';
import { tableStatusClassesWithHover } from '../helpers/tableStatusClassesWithHover';
import { OccupiedTableReservationTimes } from '../OccupiedTableReservatioTimes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '40px',
      height: '100%',
      width: '100%',
      textTransform: 'none',
      display: 'block',
    },
    ...tableStatusClassesWithHover(theme),
  }),
);

interface WalkInTableProps {
  table: TableWithExtraProperties | NotReservedTable;
  isOnline: boolean;
  tableClicked: (table: TableWithExtraProperties | NotReservedTable) => void;
}

export const WalkInTable = ({
  table,
  isOnline,
  tableClicked,
}: WalkInTableProps) => {
  const theme = useTheme();
  const classes = useStyles(theme) as Record<string, string>;

  return (
    <Button
      className={`${classes.root} ${classes[`tableStatus${table.status}`]}`}
      onClick={() => tableClicked(table)}
      variant="contained"
      color="inherit"
      disabled={!isOnline}
    >
      <Typography>
        {table.tableNo} - {table.personsNo}P
      </Typography>
      {table.status === TableStatus.OCCUPIED && (
        <OccupiedTableReservationTimes table={table} />
      )}
    </Button>
  );
};
