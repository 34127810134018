import { Theme } from '@material-ui/core';

export function getListItemClasses(theme: Theme) {
  return {
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),

    boxShadow: theme.shadows[2],

    '&:active': {
      boxShadow: theme.shadows[8],
    },
  };
}
