"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_RESTAURANT_RESERVATION_TEXT_LENGTH = exports.MAX_RESTAURANT_NAME_LENGTH = exports.MAX_REMARK_LENGTH = exports.MAX_TELEPHONE_NUMBER_LENGTH = exports.MAX_RESERVATION_NAME_LENGTH = exports.MAX_COMPANY_NAME_LENGTH = exports.MAX_OPENING_TIME = exports.MIN_OPENING_TIME = exports.MAX_NUM_BULK_TABLES = exports.MAX_TABLE_STATUS_RESERVED_TIME = exports.MIN_TABLE_STATUS_RESERVED_TIME = exports.MIN_MAX_RESERVATIONS_AT_GIVEN_TIME = exports.MAX_MAX_PERSONS_IN_WIDGET = exports.MIN_MAX_PERSONS_IN_WIDGET = exports.MIN_RESERVATION_IN_ADVANCED = exports.MIN_LEADTIME = exports.MAX_RESERVATION_DURATION = exports.MIN_RESERVATION_DURATION = exports.MIN_PERSONS_NO = exports.USER_CANCEL_RESERVATION_PATH = exports.REMOVE_PERSONAL_DATA_PATH = exports.EMAIL_LOCK_PATH = exports.USER_DASHBOARD_PATH = exports.WIDGET_RESTAURANT_LOGO_PATH = exports.WIDGET_FREE_TIMES_PATH = exports.WIDGET_TABLES_FREE_PATH = exports.WIDGET_RESTAURANT_PATH = exports.WIDGET_RESERVE_PATH = exports.COMPLETE_SETUP = exports.SERVICE_AREA_PATH = exports.RESTAURANT_LOGO_PATH = exports.RESTAURANT_SETTINGS_PATH = exports.RESTAURANT_OPENING_TIMES_PATH = exports.RESTAURANT_PATH = exports.RESERVATION_UNBLOCK = exports.RESERVATION_CANCEL_PATH = exports.RESERVATION_WALK_IN_PATH = exports.RESERVATIONS_ADDITIONAL_PATH = exports.RESERVATIONS_PATH = exports.TABLES_VIRTUAL_PATH = exports.TABLES_FREE_PATH = exports.TABLES_BULK_PATH = exports.TABLES_PATH = exports.LOGOUT_PATH = exports.LOGIN_PATH = exports.TABLE_STATUS_RESERVED_TIME_STEP = exports.TIME_STEP = exports.MAX_TIME = exports.MIN_TIME = exports.VERSION_HEADER_KEY = void 0;
exports.TableStatus = exports.ReservationSource = exports.MAX_TABLE_NO_LENGTH = exports.MAX_SERVICE_AREA_NAME_LENGTH = exports.MAX_WIDGET_RESTAURANT_INFO_TEXT_LENGTH = void 0;
exports.VERSION_HEADER_KEY = 'free-table-version';
exports.MIN_TIME = 0;
exports.MAX_TIME = 23.75;
exports.TIME_STEP = 0.25;
exports.TABLE_STATUS_RESERVED_TIME_STEP = 0.25;
// paths
exports.LOGIN_PATH = '/login';
exports.LOGOUT_PATH = '/logout';
exports.TABLES_PATH = '/tables';
exports.TABLES_BULK_PATH = '/tables/bulk';
exports.TABLES_FREE_PATH = '/tables/free';
exports.TABLES_VIRTUAL_PATH = '/tables/virtual';
exports.RESERVATIONS_PATH = '/reservations';
exports.RESERVATIONS_ADDITIONAL_PATH = '/reservations/additional';
exports.RESERVATION_WALK_IN_PATH = '/reservations/walk-in';
exports.RESERVATION_CANCEL_PATH = '/cancel-reservation';
exports.RESERVATION_UNBLOCK = '/reservations/unblock';
exports.RESTAURANT_PATH = '/restaurant';
exports.RESTAURANT_OPENING_TIMES_PATH = '/restaurant-opening-times';
exports.RESTAURANT_SETTINGS_PATH = '/restaurant-settings';
exports.RESTAURANT_LOGO_PATH = '/restaurant-logo';
exports.SERVICE_AREA_PATH = '/service-area';
exports.COMPLETE_SETUP = '/complete-setup';
// Widget paths
exports.WIDGET_RESERVE_PATH = '/widget/reserve';
exports.WIDGET_RESTAURANT_PATH = '/widget/restaurant';
exports.WIDGET_TABLES_FREE_PATH = '/widget/tables/free';
exports.WIDGET_FREE_TIMES_PATH = '/widget/times/free';
exports.WIDGET_RESTAURANT_LOGO_PATH = '/widget/restaurant-logo';
// User dashboard paths
exports.USER_DASHBOARD_PATH = '/user-dashboard';
exports.EMAIL_LOCK_PATH = '/email-lock';
exports.REMOVE_PERSONAL_DATA_PATH = '/remove-data';
exports.USER_CANCEL_RESERVATION_PATH = '/user/cancel-reservation';
// Validations
exports.MIN_PERSONS_NO = 1;
exports.MIN_RESERVATION_DURATION = 0; // hour
exports.MAX_RESERVATION_DURATION = 12; // hours
exports.MIN_LEADTIME = 0.25; // hours
exports.MIN_RESERVATION_IN_ADVANCED = 0; // weeks
exports.MIN_MAX_PERSONS_IN_WIDGET = 0;
exports.MAX_MAX_PERSONS_IN_WIDGET = 50;
exports.MIN_MAX_RESERVATIONS_AT_GIVEN_TIME = 0;
exports.MIN_TABLE_STATUS_RESERVED_TIME = 0.5;
exports.MAX_TABLE_STATUS_RESERVED_TIME = 5.25;
exports.MAX_NUM_BULK_TABLES = 100;
exports.MIN_OPENING_TIME = 0; // Monday 00:00
exports.MAX_OPENING_TIME = 167.75; // Sunday 23:45
exports.MAX_COMPANY_NAME_LENGTH = 200;
exports.MAX_RESERVATION_NAME_LENGTH = 200; // First and last name
exports.MAX_TELEPHONE_NUMBER_LENGTH = 30;
exports.MAX_REMARK_LENGTH = 2000;
exports.MAX_RESTAURANT_NAME_LENGTH = 200;
exports.MAX_RESTAURANT_RESERVATION_TEXT_LENGTH = 500;
exports.MAX_WIDGET_RESTAURANT_INFO_TEXT_LENGTH = 1000;
exports.MAX_SERVICE_AREA_NAME_LENGTH = 50;
exports.MAX_TABLE_NO_LENGTH = 50;
var ReservationSource;
(function (ReservationSource) {
    ReservationSource["widget"] = "widget";
    ReservationSource["client"] = "client";
})(ReservationSource = exports.ReservationSource || (exports.ReservationSource = {}));
/*
 * FREE means that the table will be reserved at a later time
 * NOT_RESERVED means that the table is not reserved at all for the future
 * RESERVED means that the table is free but will become OCCUPIED in the next XX minutes (depends on table_status_reserved_time)
 * OCCUPIED means that 'now' is between reservation time and reservation time + duration
 *
 * The what 'now', 'later time' and 'future' is depends on the code getting the status
 */
var TableStatus;
(function (TableStatus) {
    TableStatus["NOT_RESERVED"] = "NOT_RESERVED";
    TableStatus["FREE"] = "FREE";
    TableStatus["RESERVED"] = "RESERVED";
    TableStatus["OCCUPIED"] = "OCCUPIED";
})(TableStatus = exports.TableStatus || (exports.TableStatus = {}));
