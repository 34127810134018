import { tableValidation, virtualTableValidation } from '@gts-ft/ui';

import { Formik } from 'formik';
import { View } from '@gts-common/client';
import {
  AddedTable,
  AddRealTableRequestBody,
  AddVirtualTableRequestBody,
} from '@gts-ft/utils';
import { TABLE_SETTINGS_LIST_VIEW } from '../../constants';
import { TableForm } from './TableForm';

const initialValues: AddRealTableRequestBody = {
  tableNo: '',
  personsNo: 2,
  minPersons: 2,
  visibleInWidget: true,
};

const initialVirtualValues: AddVirtualTableRequestBody = {
  ...initialValues,
  tableIds: [],
};

interface Props {
  realTables: Array<AddedTable>;
  isVirtual: boolean;
  execAddTable: (
    table: AddRealTableRequestBody | AddVirtualTableRequestBody,
    isVirtual: boolean,
  ) => void;
  isOnline: boolean;
}

export const TableAdd = ({
  realTables,
  isVirtual,
  execAddTable,
  isOnline,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      backButtonLabel="Tische verwalten"
      navigateBackTo={TABLE_SETTINGS_LIST_VIEW}
      title={isVirtual ? 'Tischkombination hinzufügen' : 'Tisch hinzufügen'}
    >
      <Formik
        onSubmit={(table) => execAddTable(table, isVirtual)}
        initialValues={isVirtual ? initialVirtualValues : initialValues}
        validationSchema={isVirtual ? virtualTableValidation : tableValidation}
      >
        <TableForm
          realTables={realTables}
          isVirtual={isVirtual}
          isEdit={false}
          isOnline={isOnline}
        />
      </Formik>
    </View>
  );
};
