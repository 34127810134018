import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  TextField,
  NumberField,
  TextareaField,
  CheckboxField,
} from '@gts-common/client';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormikContext } from 'formik';
import { RestaurantSettingsState } from '../../../types';

export const WidgetSettings = () => {
  const values = useFormikContext<RestaurantSettingsState>().values;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Reservierungsformular</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Gastronomiename"
              name="restaurantName"
              required
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaField
              label="Informationstext"
              name="widgetRestaurantInfoText"
              hint="Hier können Sie zusätzliche Informationen zu Ihrer Gastronomie hinterlegen, wie z. B. an welchen Wochentagen Sie geöffnet haben. Bitte beachten Sie, dass die Texte nicht zu lang werden, da Sie dann von der eigentlichen Reservierung ablenken."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <NumberField
              label="Nächste Reservierung (Std.)"
              name="reservationLeadtime"
              hint="Zu welchem Zeitpunkt kann Ihr Gast ein Tisch reservieren. Beispiel: Sie haben 2 Stunde eingestellt und jetzt ist 19 Uhr. Ihr Gast kann frühstens um 21 Uhr ein Tisch über das Reservierungsformular reservieren."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <NumberField
              label="Zeitraum (Wochen)"
              name="reservationInAdvancedWeeks"
              hint="Bis wann sind Reservierung in der Zukunft möglich ab heute in Wochen. Beispiel: Sie haben 4 Wochen eingetragen und heute ist der 01.01., dann können Ihre Gäste bis zum 28.01. über das Reservierformular bei Ihnen reservieren."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <NumberField
              label="Max. Personenzahl"
              name="maxPersonsInWidget"
              hint="Maximale Personenzahl pro Reservierung über das Reservierungsformular."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaField
              label="Zu viele Personen"
              name="reservationText"
              hint="Text, der angezeigt wird, wenn die maximale Personenzahl für eine Reservierung im Reservierungsformular überschritten wird."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <NumberField
              label="Reservierungen pro Uhrzeit"
              name="maxReservationsAtGivenTime"
              hint="Maximale Reservierungsanzahl pro Uhrzeit. Beispiel: Haben Sie 10 eingestellt, können Sie maximal 10 Reservierungen für 19 Uhr haben, unabhängig davon, ob die Reservierung über das Reservierungsformular erfolgt ist oder direkt durch Sie eingetragen wird."
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="switch"
              name="widgetActive"
              hint="Wenn der Status aktiv ist (An-/Aus Schalter ist blau), können Ihre Gäste über das Reservierungsformular reservieren."
            >
              Reservierungsformular ({values.widgetActive ? 'aktiv' : 'inaktiv'}
              )
            </CheckboxField>
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              type="switch"
              name="tableSelectionVisibleInWidget"
              hint="Ihre Gäste können bei der Reservierung Ihren Wunschtisch auswählen, anhand der Tischnummer."
            >
              Tischauswahl sichtbar
            </CheckboxField>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
