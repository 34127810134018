import { LoginStatus } from '@gts-common/client';
import { initialState } from '../initialState';
import {
  Actions,
  APP_LIVE_DATE_CHANGED,
  COULD_NOT_STORE_OFFLINE_DATA_SHOWN,
  IS_OFFLINE,
  IS_ONLINE,
  LOGIN_CHECK_FAILED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  SHOW_APP_WITH_OFFLINE_DATA,
  SHOW_OFFLINE_VIEW,
} from '../actions/reduxActionTypes';
import { AppState } from '../types';
import { getStartOfNextReservationInterval } from '../helpers/getStartOfNextReservationInterval';

export function appReducer(
  state = initialState.app,
  action: Actions,
): AppState {
  switch (action.type) {
    // Reset all data
    case LOGOUT_SUCCEEDED:
      return {
        ...initialState.app,
        loginStatus: LoginStatus.LOGGED_OUT,
        // Logout doesn't change the user's online status or the live date
        isOnline: state.isOnline,
        liveDatetime: state.liveDatetime,
        startOfNextInterval: state.startOfNextInterval,
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_IN,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_IN,
      };

    case LOGIN_CHECK_FAILED:
      return {
        ...state,
        loginStatus: LoginStatus.LOGGED_OUT,
      };

    case APP_LIVE_DATE_CHANGED:
      return {
        ...state,
        liveDatetime: action.payload,
        startOfNextInterval: getStartOfNextReservationInterval(action.payload),
      };

    case IS_OFFLINE:
      return {
        ...state,
        isOnline: false,
      };

    case IS_ONLINE:
      return {
        ...state,
        isOnline: true,
      };

    case COULD_NOT_STORE_OFFLINE_DATA_SHOWN:
      return Object.assign({}, state, {
        warnings: {
          ...state.warnings,
          couldNotStoreOfflineDataShown: true,
        },
      });

    case SHOW_APP_WITH_OFFLINE_DATA:
      return {
        ...state,
        offlineDataTimestamp: action.payload,
      };

    case SHOW_OFFLINE_VIEW:
      return {
        ...state,
        showOfflineView: true,
      };

    default:
      return state;
  }
}
