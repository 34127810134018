import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { denormalize } from '@gts-common/client';
import {
  AddRealTableRequestBody,
  AddVirtualTableRequestBody,
} from '@gts-ft/utils';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { execAddTable } from '../../actions';
import { TableAdd } from '../../components/TableSettings/TableAdd';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';

const mapStateToProps = (
  state: ReduxState,
  ownProps: { isVirtual: boolean },
) => {
  const tablesState = state.tables;
  const denormalizedTables = denormalize(tablesState);
  const sortedRealTables = sortTablesByTableNo(
    denormalizedTables.filter((table) => !table.isVirtual),
  );

  return {
    realTables: sortedRealTables,
    isVirtual: ownProps.isVirtual,
    isOnline: state.app.isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddTable(
    table: AddRealTableRequestBody | AddVirtualTableRequestBody,
    isVirtual: boolean,
  ) {
    dispatch(execAddTable(table, isVirtual));
  },
});

export const TableAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableAdd);
