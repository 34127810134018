import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TableSelectField, ReservationFormData } from '@gts-ft/ui';
import {
  CheckboxField,
  SelectField,
  DatepickerField,
  TextField,
  NumberField,
  EmailField,
  TextareaField,
  SaveButton,
  AddButton,
} from '@gts-common/client';
import {
  AddedTable,
  createTimeListInRange,
  TABLES_FREE_PATH,
} from '@gts-ft/utils';
import { Form, FormikErrors } from 'formik';

import { getFreeTablesErrorHandler } from '../../helpers/getFreeTablesErrorHandler';
import { canUncheckManualAllocation } from '../../helpers/canUncheckManualAllocation';
import { isTableSelectDisabled } from './helpers/isTableSelectDisabled';

const selectTimes = createTimeListInRange().map((time) => ({
  value: time,
  label: time,
}));

interface Props {
  isEdit: boolean;
  reservationId?: string;
  tables: Record<string, AddedTable>;
  isOnline: boolean;
  arrived: boolean;
  values: ReservationFormData;
  errors: FormikErrors<ReservationFormData>;
}

const formId = 'reservation-form';

export const ReservationForm = ({
  arrived,
  isEdit,
  tables,
  reservationId,
  isOnline,
  values,
  errors,
}: Props) => {
  return (
    <Form id={formId} noValidate={true}>
      {arrived && (
        <Typography variant="body2" color="primary">
          Diese Reservierung wurde als angekommen markiert.
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatepickerField label="Datum" name="date" />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="Uhrzeit"
            name="time"
            options={selectTimes}
            noResultsText=""
            required
          />
        </Grid>
        <Grid item xs={6}>
          <NumberField label="Personen" name="personsNo" required />
        </Grid>
        <Grid item xs={6}>
          <NumberField
            label="Verweildauer in Std."
            name="reservationDuration"
            required
          />
        </Grid>
      </Grid>

      <TextField label="Name" name="name" required autoFocus />
      <TextareaField label="Anmerkung (mehrzeilig)" name="remark" />
      <TextField label="Telefonnummer" name="telephone" />
      <CheckboxField
        type="switch"
        name="manualTableAllocation"
        hint="Sie können Tische und Tischkombinationen miteinander kombinieren. Bitte beachten Sie, dass bei einer manuellen Tischzuweisung die Personenanzahl der Tische ignoriert wird, d.h. Sie können 4 Personen an einen 2er Tisch setzen."
        disabled={canUncheckManualAllocation(
          values.manualTableAllocation,
          values.tableIds,
          tables,
          values.personsNo,
        )}
      >
        Manuelle Tischzuweisung (
        {values.manualTableAllocation ? 'aktiv' : 'inaktiv'})
      </CheckboxField>
      <TableSelectField
        label="Tisch"
        name="tableIds"
        tables={tables}
        disabled={isTableSelectDisabled(errors)}
        reservationId={reservationId}
        errorHandler={getFreeTablesErrorHandler}
        serverPath={TABLES_FREE_PATH}
        additionalQueryParams={{
          reservationDuration: values.reservationDuration,
          manualTableAllocation: values.manualTableAllocation,
        }}
        isMulti={values.manualTableAllocation}
        required={values.manualTableAllocation}
        date={values.date}
        time={values.time}
        personsNo={values.personsNo}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Zusätzliche Reservierungsdaten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <TextField label="Firma" name="company" />
            </Grid>
            <Grid item xs={12}>
              <EmailField label="E-Mail-Adresse" name="email" />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box mt="1rem" textAlign="center">
        {isEdit && <SaveButton submitForForm={formId} disabled={!isOnline} />}
        {!isEdit && <AddButton submitForForm={formId} disabled={!isOnline} />}
      </Box>
    </Form>
  );
};
