import {
  LoginResponse,
  AddedServiceArea,
  AddedTable,
  AddedReservation,
} from '@gts-ft/utils';
import {
  execOpenMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
} from '@gts-common/client';
import { serverComm } from '@gts-ft/ui';
import {
  Actions,
  SHOW_OFFLINE_VIEW,
  ShowOfflineViewAction,
  Thunk,
} from '../reduxActionTypes';
import { db } from '../../db';
import { showAppWithOfflineData } from '../online';
import { loginCheckSucceeded } from '../login';
import { prepareLoginResponseForClient } from './prepareLoginResponseForClient';

export function showOfflineScreen(): ShowOfflineViewAction {
  return {
    type: SHOW_OFFLINE_VIEW,
  };
}

export function loadDataFromOfflineStorage(): Thunk<Actions> {
  return async (dispatch) => {
    try {
      const tsEntry = await db.getTimestamp();
      const dataTimestamp = tsEntry ? tsEntry.value : undefined;

      const loadedTables: LoginResponse['tables'] = await db.getRecords<AddedTable>(
        'table',
      );

      const loadedReservations: LoginResponse['reservations'] = await db.getRecords<AddedReservation>(
        'reservation',
      );

      const restaurant = await db.getRecords<LoginResponse['restaurant']>(
        'restaurant',
      );
      const loadedRestaurant = restaurant[0];

      const loadedServiceAreas: LoginResponse['serviceAreas'] = await db.getRecords<AddedServiceArea>(
        'serviceArea',
      );

      if (loadedRestaurant !== undefined) {
        // If the user has offline data assume that the setup was complete
        const initialData = prepareLoginResponseForClient(
          true,
          loadedTables,
          loadedReservations,
          loadedRestaurant,
          loadedServiceAreas,
        );

        dispatch(loginCheckSucceeded(initialData));
        dispatch(showAppWithOfflineData(dataTimestamp));
      } else {
        dispatch(showOfflineScreen());
      }
    } catch (e: unknown) {
      // This will probably fail because we only load offline data when the user is offline but try anyway
      sendErrorToServer(serverComm, e);

      dispatch(
        execOpenMessage({
          type: MessageType.MODAL,
          modalType: ModalType.ERROR_MODAL,
          title: 'Fehler beim Laden der Daten',
          body: `Aktuell besteht keine Internetverbindung oder es finden
          Wartungsarbeiten auf Seiten von free-table statt. Normalerweise würden
          Ihnen jetzt die auf Ihrem Browser zwischengespeicherten Daten
          angezeigt. Leider sind diese aber wider Erwarten nicht vorhanden oder
          können nicht gelesen werden.`,
        }),
      );
      dispatch(showOfflineScreen());
    }
  };
}
