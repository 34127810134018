import { Button, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { View } from '@gts-common/client';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import ListIcon from '@material-ui/icons/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';

import { ReactNode } from 'react';

interface Props {
  showReservationAdd: () => void;
  showReservationsToday: () => void;
  showReservationsAll: () => void;
  showTablesBookingStatus: () => void;
  showWalkInTables: () => void;
  showUnblockTables: () => void;
}

interface HomeBoxProps {
  onClick: () => void;
  text: string;
  icon: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      height: '100px',
      width: '100%',
      fontSize: theme.typography.h6.fontSize,
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: '28px',
      },
    },
  };
});

const HomeBox = ({ text, onClick, icon }: HomeBoxProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      classes={classes}
      onClick={onClick}
      startIcon={icon}
      variant="contained"
      color="primary"
    >
      {text}
    </Button>
  );
};

export const Home = ({
  showReservationsToday,
  showReservationsAll,
  showReservationAdd,
  showTablesBookingStatus,
  showWalkInTables,
  showUnblockTables,
}: Props) => (
  <View hasBackButton={false} title="">
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showReservationsToday}
          text="Heutige Reservierungen"
          icon={<ListIcon />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showReservationAdd}
          text="Neue Reservierung"
          icon={<AddCircleIcon />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showWalkInTables}
          text="Walk In"
          icon={<DirectionsWalkIcon />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showUnblockTables}
          text="Tische freigeben"
          icon={<AlarmOffIcon />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showReservationsAll}
          text="Reservierungen"
          icon={<ListIcon />}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeBox
          onClick={showTablesBookingStatus}
          text="Tischbelegung"
          icon={<BorderAllIcon />}
        />
      </Grid>
    </Grid>
  </View>
);
