import { FormikErrors } from 'formik';
import { ReservationFormData } from '@gts-ft/ui';

export function isTableSelectDisabled(
  errors: FormikErrors<ReservationFormData>,
) {
  return (
    Boolean(errors.date) ||
    Boolean(errors.time) ||
    Boolean(errors.personsNo) ||
    Boolean(errors.reservationDuration)
  );
}
