import { ThunkDispatch } from 'redux-thunk';
import { FileUploaderAction } from '../../components/UI-Components';
import { updateLogoFilename } from '../../actions';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';

export function execUpdateLogoFilename(
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
  action: FileUploaderAction,
  files: Array<string> | string | undefined,
) {
  if (action === FileUploaderAction.REMOVE) {
    dispatch(updateLogoFilename(null));
    // Upload can only have an array but add the check
    // for type safety
  } else if (action === FileUploaderAction.UPLOAD) {
    if (Array.isArray(files)) {
      dispatch(updateLogoFilename(files[0]));
    }
  }
}
