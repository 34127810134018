import {
  convertServerDateStringToLocalDateString,
  AddedReservation,
} from '@gts-ft/utils';
import { parse } from 'date-fns';
import { SERVER_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import { ClientReservation } from '../../../types';

export function prepareReservationForClient(
  reservation: AddedReservation,
): ClientReservation {
  return {
    ...reservation,
    date: convertServerDateStringToLocalDateString(reservation.date),
    datetime: parse(
      `${reservation.date} ${reservation.time}`,
      `${SERVER_DATE_FORMAT} ${TIME_FORMAT}`,
      new Date(),
    ),
  };
}
