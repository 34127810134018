import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';
import { SelectInput } from '@gts-common/client';
import { getFreeTables } from '../helpers/getFreeTables';
import { prepareTablesForSelect } from './helpers/prepareTablesForSelect';
const TableSelect = (props) => {
    // Save the original table so that we can always
    // mark it as free
    let originalIds;
    if (Array.isArray(props.value)) {
        originalIds = props.value;
    }
    else if (props.value !== undefined) {
        originalIds = [props.value];
    }
    else {
        originalIds = [];
    }
    const originalTableIds = useRef(originalIds);
    const [isLoading, setLoading] = useState(false);
    const [freeTables, setFreeTables] = useState([]);
    function onOpen() {
        setLoading(true);
        void getFreeTables(props).then((currentFreeTables) => {
            const tables = props.tables;
            const freeTableIds = currentFreeTables.map((table) => table.tableId);
            const addTableIds = originalTableIds.current.filter((newTableId) => !freeTableIds.includes(newTableId));
            setLoading(false);
            setFreeTables([
                ...currentFreeTables,
                ...addTableIds.map((tableId) => tables[tableId]),
            ]);
        });
    }
    // Needed so that the select can display an already selected table
    useEffect(() => {
        const { tables, value: newTableIds } = props;
        // Make sure that we actually have tables because in the widget after
        // a table gets selected, the effect triggers but since we have no tables
        // there is an error if we don't prevent the setting of the free tables
        if (Object.keys(tables).length === 0) {
            return;
        }
        if (Array.isArray(newTableIds)) {
            setFreeTables(newTableIds.map((tableId) => tables[tableId]));
        }
        else if (newTableIds !== undefined) {
            setFreeTables([tables[newTableIds]]);
        }
    }, [props]);
    return (_jsx(SelectInput, Object.assign({ onOpen: onOpen, isLoading: isLoading, options: isLoading ? [] : prepareTablesForSelect(freeTables), noResultsText: "Keine Tische verf\u00FCgbar", clearable: false }, props), void 0));
};
export const TableSelectField = (props) => (_jsx(Field, Object.assign({ name: props.name }, { children: (fieldProps) => {
        const { setFieldValue } = fieldProps.form;
        const onChange = (value) => {
            // Make sure that this control always returns an array.
            // It makes things easier on the outside if we always deal
            // with the same data type
            if (value === undefined) {
                setFieldValue(props.name, []);
            }
            else if (Array.isArray(value)) {
                setFieldValue(props.name, value);
            }
            else {
                setFieldValue(props.name, [value]);
            }
        };
        const showError = Boolean(fieldProps.meta.touched && fieldProps.meta.error);
        let fieldValue;
        if (Array.isArray(fieldProps.field.value) &&
            fieldProps.field.value.length === 0) {
            fieldValue = props.isMulti ? [] : undefined;
        }
        else if (Array.isArray(fieldProps.field.value) &&
            props.isMulti === false) {
            fieldValue = fieldProps.field.value[0];
        }
        else {
            fieldValue = fieldProps.field.value;
        }
        return (_jsx(TableSelect, Object.assign({}, props, fieldProps.field, { value: fieldValue, error: fieldProps.meta.error, onChange: onChange, showError: showError }), void 0));
    } }), void 0));
