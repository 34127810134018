import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import {
  convertDecimalTimeToString,
  getWeekDay,
  toDayTime,
} from '@gts-ft/utils';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { RestaurantReservationTimesState } from '../../types';
import { AddReservationHours } from './subforms/AddReservationHours';

interface Props {
  reservationHours: RestaurantReservationTimesState['reservationHours'];
  deleteInterval: (index: number) => void;
}

export const ReservationHours = ({
  reservationHours,
  deleteInterval,
}: Props) => {
  /* Need the Box here because AccordionDetails is a flex container
      this will change in the future https://github.com/mui-org/material-ui/pull/22809 */
  return (
    <Box width="100%">
      <Typography gutterBottom={true} align="justify">
        Hier definieren Sie die Uhrzeiten bzw. Zeiträume, wo Ihre Gäste ein
        Tisch über das Reservierungsformular reservieren können. Wenn Sie
        Reservierungen nur für eine bestimmte Uhrzeit erlauben wollen, müssen
        Startzeit und Endzeit gleich sein. Wenn Sie Änderungen vorgenommen
        haben, müssen Sie zusätzlich noch auf Speichern klicken.
      </Typography>

      <AddReservationHours reservationHours={reservationHours} />

      {reservationHours.length > 0 && (
        <Box width="100%">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tag</TableCell>
                  <TableCell align="center">Uhrzeit</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {reservationHours.map((reservationInterval, index) => {
                  const [startDay, startTime] = toDayTime(
                    reservationInterval.startTime,
                  );
                  return (
                    <TableRow key={reservationInterval.startTime}>
                      <TableCell component="th" scope="row">
                        {getWeekDay(startDay)}
                      </TableCell>
                      <TableCell align="center">
                        {startTime}–
                        {convertDecimalTimeToString(
                          reservationInterval.endTime,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => deleteInterval(index)}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};
