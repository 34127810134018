import { RESERVATIONS_PATH, EditReservationRequestBody } from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  SET_ARRIVAL_STATUS_SUCCEEDED,
  SetArrivalStatusSucceededAction,
  Thunk,
  Actions,
} from '../reduxActionTypes';
import { updateReservationOfflineDB } from './update';

function setArrivalStatusSucceeded(
  reservationId: string,
  arrived: boolean,
): SetArrivalStatusSucceededAction {
  return {
    type: SET_ARRIVAL_STATUS_SUCCEEDED,
    payload: { reservationId, arrived },
  };
}

export function execSetArrivalStatus(
  reservationId: string,
  arrived: boolean,
): Thunk<Actions> {
  return (dispatch) => {
    const reservation: EditReservationRequestBody = { arrived };

    dispatch(startServerRequest());

    return serverComm
      .execPatchRequest<Record<string, never>, EditReservationRequestBody>(
        `${RESERVATIONS_PATH}/${reservationId}`,
        reservation,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            let successText;
            if (arrived) {
              successText = 'Reservierung als angekommen markiert';
            } else {
              successText = 'Reservierung als nicht angekommen markiert';
            }
            dispatch(serverRequestSucceeded(successText));
            dispatch(setArrivalStatusSucceeded(reservationId, arrived));
            void dispatch(
              updateReservationOfflineDB(reservationId, { arrived }),
            );
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
