import {
  convertLocalDateStringToServerDateString,
  ClientAddReservationRequestBody,
  EditReservationRequestBody,
} from '@gts-ft/utils';

import { ReservationFormData } from '@gts-ft/ui';
import { trimData } from '@gts-common/client';

const whatToTrim: Array<keyof ReservationFormData> = [
  'email',
  'company',
  'name',
  'remark',
  'telephone',
];

// Reservation is possibly just a partial
export function prepareReservationForServer(
  reservation: ReservationFormData & { arrived?: boolean },
): ClientAddReservationRequestBody;
export function prepareReservationForServer(
  reservation: Partial<ReservationFormData & { arrived?: boolean }>,
): EditReservationRequestBody;
export function prepareReservationForServer(
  reservation:
    | (ReservationFormData & { arrived?: boolean })
    | Partial<ReservationFormData & { arrived?: boolean }>,
): ClientAddReservationRequestBody | EditReservationRequestBody {
  const trimmedData = trimData(whatToTrim, reservation);

  let dataToSend;

  if (trimmedData.date) {
    dataToSend = Object.assign(trimmedData, {
      date: convertLocalDateStringToServerDateString(trimmedData.date),
    });
  } else {
    dataToSend = trimmedData;
  }

  return {
    ...dataToSend,
    arrived: Boolean(reservation.arrived),
  };
}
