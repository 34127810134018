import { format } from 'date-fns';

import { SERVER_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  execOpenMessage,
  ExtraModalMessage,
  getErrorModalMessage,
  MessageType,
  ModalType,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';

import {
  RESERVATION_UNBLOCK,
  UnblockRequestBody,
  UnblockResponse,
} from '@gts-ft/utils';
import { Actions, Thunk } from '../reduxActionTypes';
import { TableWithExtraProperties } from '../../types';
import {
  updateReservationOfflineDB,
  updateReservationSucceeded,
} from '../reservations';

export function unblockTable(table: TableWithExtraProperties): Thunk<Actions> {
  return (dispatch, getState) => {
    const state = getState();
    const startOfNextInterval = state.app.startOfNextInterval;

    dispatch(startServerRequest());

    const unblockData: UnblockRequestBody = {
      date: format(startOfNextInterval, SERVER_DATE_FORMAT),
      time: format(startOfNextInterval, TIME_FORMAT),
    };

    return serverComm
      .execPutRequest<UnblockResponse, UnblockRequestBody>(
        `${RESERVATION_UNBLOCK}/${table.firstReservationId}`,
        unblockData,
      )
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            const updatedReservation = resp.body;

            dispatch(serverRequestSucceeded('Tisch freigegeben.'));

            if (updatedReservation) {
              dispatch(
                updateReservationSucceeded(
                  updatedReservation.reservationId,
                  updatedReservation,
                ),
              );
              void dispatch(
                updateReservationOfflineDB(
                  updatedReservation.reservationId,
                  updatedReservation,
                ),
              );
            }
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function tryToUnblockTable(
  table: TableWithExtraProperties,
): Thunk<Actions> {
  return (dispatch) => {
    const modal: ExtraModalMessage = {
      type: MessageType.MODAL,
      modalType: ModalType.DECISION_MODAL,
      title: 'Tisch freigeben?',
      body: 'Wollen Sie den Tisch wirklich freigeben?',
      extraProps: {
        confirmAction: unblockTable(table),
      },
    };

    dispatch(execOpenMessage(modal));
  };
}
