import { serverComm } from '@gts-ft/ui';
import { FileObject } from './types';

export function uploadFileObjects(
  path: string,
  multiple: boolean,
  fileObjects: Array<FileObject>,
) {
  const name = multiple ? 'files' : 'file';
  const formDataElements = fileObjects.map((fileObject) => {
    return {
      name,
      data: fileObject.file,
    };
  });

  return serverComm
    .postFormDataRequest<Array<string>>(path, formDataElements)
    .then((resp) => {
      if (resp.succeeded) {
        return resp.body;
      } else {
        return Promise.reject(new Error('Posting a file failed'));
      }
    });
}

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const res = event?.target?.result;

      if (typeof res === 'string') {
        resolve(res);
      } else {
        reject(new Error('Result was not a string'));
      }
    };
    reader.onerror = (event) => {
      reader.abort();
      reject(event);
    };
    reader.readAsDataURL(file);
  });
}

export function getFile(path: string, fileId: string) {
  return serverComm.execGetRequest<Blob>(`${path}/${fileId}`).then((resp) => {
    if (resp.succeeded) {
      const fileblob = resp.body;
      const file = new File([fileblob], fileId);
      return readFile(file).then((data) => {
        return {
          file,
          data,
          id: fileId,
          isLoading: false,
        };
      });
    } else {
      return Promise.reject(new Error('Getting a file failed'));
    }
  });
}
