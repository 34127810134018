import { addMinutes, format } from 'date-fns';
import { TIME_FORMAT } from '@gts-common/client-server';
import { getMinutesFromDecimal } from '@gts-ft/utils';
import { ClientReservation } from '../../../types';

export function getReservationUntilTime(reservation: ClientReservation) {
  return format(
    addMinutes(
      reservation.datetime,
      getMinutesFromDecimal(reservation.reservationDuration),
    ),
    TIME_FORMAT,
  );
}
