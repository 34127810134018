import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { denormalize, serverRequestFailed } from '@gts-common/client';
import { AddRealTableRequestBody } from '@gts-ft/utils';
import {
  ReduxState,
  RestaurantReservationTimesState,
  RestaurantSettingsState,
} from '../types';

import {
  Actions,
  SETUP_WIZARD_NEXT_STEP,
  SETUP_WIZARD_PREVIOUS_STEP,
} from '../actions/reduxActionTypes';
import { SetupWizard } from '../components/SetupWizard/SetupWizard';
import {
  completeSetup,
  execBulkAddTables,
  execRestaurantOpeningTimesChange,
  execRestaurantSettingsChange,
} from '../actions';
import { FileUploaderAction } from '../components/UI-Components';
import { execUpdateLogoFilename } from './helpers/execUpdateLogoFilename';

const mapStateToProps = (state: ReduxState) => {
  return {
    widgetUrl: state.restaurant.runtimeSettings.widgetUrl,
    settings: state.restaurant.settings,
    reservationTimes: state.restaurant.openingTimes,
    tables: denormalize(state.tables).map(
      ({ tableNo, personsNo, minPersons, visibleInWidget }) => ({
        tableNo,
        personsNo,
        minPersons,
        visibleInWidget,
      }),
    ),
    currentStep: state.setupWizard.currentStep,
    isSetupCompleted: state.setupWizard.isSetupCompleted,
    widgetActive: state.restaurant.settings.widgetActive,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execRestaurantSettingsChange(
    restaurantSettingsObject: RestaurantSettingsState,
  ) {
    dispatch(
      execRestaurantSettingsChange(restaurantSettingsObject, {
        type: SETUP_WIZARD_NEXT_STEP,
      }),
    );
  },
  execBulkAddTables(tables: Array<AddRealTableRequestBody>, action: Actions) {
    dispatch(execBulkAddTables(tables, action));
  },
  execRestaurantReservationTimesChange(
    restaurantReservationTimesObject: RestaurantReservationTimesState,
    action: Actions,
  ) {
    dispatch(
      execRestaurantOpeningTimesChange(
        restaurantReservationTimesObject,
        action,
      ),
    );
  },
  execCompleteSetup() {
    dispatch(completeSetup());
  },
  goToPreviousStep() {
    dispatch({ type: SETUP_WIZARD_PREVIOUS_STEP });
  },
  execDataLoadFailed(reason: string) {
    dispatch(serverRequestFailed(reason));
  },
  execUpdateLogoFilename(
    action: FileUploaderAction,
    files: Array<string> | string | undefined,
  ) {
    execUpdateLogoFilename(dispatch, action, files);
  },
  execUpdateWidgetActive(isActive: boolean) {
    dispatch(execRestaurantSettingsChange({ widgetActive: isActive }));
  },
});

export const SetupWizardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupWizard);
