import {
  Actions,
  RESERVATIONS_DATE_CHANGED,
  ReservationsDateChangedAction,
  Thunk,
} from '../reduxActionTypes';
import { execGetAdditionalReservations } from './getAdditional';

function reservationsDateChanged(newDate: Date): ReservationsDateChangedAction {
  return {
    type: RESERVATIONS_DATE_CHANGED,
    payload: newDate,
  };
}

export function changeReservationsDate(newDate: Date): Thunk<Actions> {
  return (dispatch) => {
    if (newDate) {
      dispatch(execGetAdditionalReservations(newDate));
    }

    dispatch(reservationsDateChanged(newDate));
  };
}

export function resetReservationsDate(): Thunk<Actions> {
  return (dispatch, getState) => {
    const startOfNextInterval = getState().app.startOfNextInterval;
    dispatch(reservationsDateChanged(startOfNextInterval));
  };
}
