import { Box, Grid } from '@material-ui/core';
import { prepareTablesForSelect } from '@gts-ft/ui';
import {
  NumberField,
  TextField,
  AddButton,
  SaveButton,
  CheckboxField,
  SelectField,
} from '@gts-common/client';

import { Form } from 'formik';
import { AddedTable } from '@gts-ft/utils';

interface Props {
  isVirtual: boolean;
  realTables: Array<AddedTable>;
  isEdit: boolean;
  isOnline: boolean;
}

const formId = 'tables-form';

export const TableForm = ({
  isVirtual,
  realTables,
  isEdit,
  isOnline,
}: Props) => (
  <Form noValidate={true} id={formId}>
    {isVirtual && (
      <SelectField
        clearable
        name="tableIds"
        label="Echte Tische"
        noResultsText="Keine Tische verfügbar"
        options={prepareTablesForSelect(realTables)}
        isMulti={true}
        required={true}
      />
    )}
    <TextField
      label="Tischnummer"
      name="tableNo"
      hint="Beispiele für Tischnummern: 01, A01, A_01, AA-01."
      required
      autoFocus
    />
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <NumberField
          label="Personen"
          name="personsNo"
          required
          hint="Maximale Personenanzahl, die für diesen Tisch erlaubt sind."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberField
          label="Mindestbelegung"
          name="minPersons"
          required
          hint="Mindestpersonenanzahl um diesen Tisch zu reservieren, z. B. für einen 4er Tisch müssen mindestens 2 Personen reservieren."
        />
      </Grid>
    </Grid>
    <CheckboxField
      type="checkbox"
      name="visibleInWidget"
      required
      hint="Falls das Häkchen nicht gesetzt ist, kann dieser Tisch nicht über das Reservierungsformular reserviert werden. Nur Sie können für diesen Tisch eine Reservierung anlegen."
    >
      durch Gast reservierbar
    </CheckboxField>
    <Box mt="1rem" textAlign="center">
      {isEdit && <SaveButton submitForForm={formId} disabled={!isOnline} />}
      {!isEdit && <AddButton submitForForm={formId} disabled={!isOnline} />}
    </Box>
  </Form>
);
