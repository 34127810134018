import { connect } from 'react-redux';
import { addDays, set } from 'date-fns';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import {
  RESERVATIONS_DETAILS_PATH,
  RESERVATIONS_EDIT_PATH,
  RESERVATIONS_TODAY_VIEW,
  TABLES_WALK_IN_VIEW,
} from '../../constants';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { ReservationsTodayView } from '../../components/Reservations/ReservationsTodayView';
import {
  execCancelReservationDecision,
  execSetArrivalStatus,
} from '../../actions';
import { getReservationsForInterval } from './helpers/getReservationsForInterval';
import { addTableNumbersAndStatus } from './helpers/addTableNumbersAndStatus';
import { splitIntoArrivedAndNotArrived } from './helpers/splitIntoArrivedAndNotArrived';
import { sortReservationsByTime } from './helpers/sortReservationsByTime';

const mapStateToProps = (state: ReduxState) => {
  const reservationsState = state.reservations;
  const isOnline = state.app.isOnline;
  const tables = state.tables;
  const liveDatetime = state.app.liveDatetime;
  const tableStatusReservedTime =
    state.restaurant.runtimeSettings.tableStatusReservedTime;

  const denormalizedReservations = denormalize(reservationsState);

  // Filter from 04:00 today till 04:00 the next day.
  // This should be enough to cover most opening times of a restaurant.
  // This might not work for bars, clubs etc.
  const filterStartDatetime = set(liveDatetime, {
    hours: 4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const filterEndDatetime = addDays(filterStartDatetime, 1);
  const reservationForToday = getReservationsForInterval(
    denormalizedReservations,
    filterStartDatetime,
    filterEndDatetime,
    true,
  );

  const numOfPersons = reservationForToday.reduce(
    (prev, cur) => prev + cur.personsNo,
    0,
  );
  const numOfReservations = reservationForToday.length;

  const reservationWithTableNumbers = addTableNumbersAndStatus(
    reservationForToday,
    tables.entities,
    liveDatetime,
    tableStatusReservedTime,
  );

  const {
    arrivedReservations,
    notArrivedReservations,
  } = splitIntoArrivedAndNotArrived(reservationWithTableNumbers);

  return {
    arrivedReservations: sortReservationsByTime(arrivedReservations, 'DESC'),
    notArrivedReservations: sortReservationsByTime(notArrivedReservations),
    isOnline,
    numOfReservations,
    numOfPersons,
  };
};

function getNavigationObject(path: string) {
  return {
    pathname: path,
    state: {
      cameFrom: {
        backButtonLabel: 'Heutige Reservierungen',
        to: RESERVATIONS_TODAY_VIEW,
      },
    },
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execSetArrivalStatus(reservationId: string, arrived: boolean) {
    dispatch(execSetArrivalStatus(reservationId, arrived));
  },
  showReservationDetails(reservationId: string) {
    dispatch(
      push(
        getNavigationObject(`${RESERVATIONS_DETAILS_PATH}/${reservationId}`),
      ),
    );
  },
  showWalkInTables() {
    dispatch(push(getNavigationObject(TABLES_WALK_IN_VIEW)));
  },
  execCancelReservation(reservationId: string) {
    dispatch(execCancelReservationDecision(reservationId));
  },
  showReservationEdit(reservationId: string) {
    dispatch(
      push(getNavigationObject(`${RESERVATIONS_EDIT_PATH}/${reservationId}`)),
    );
  },
});

export const ReservationsTodayViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationsTodayView);
