import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TableStatus } from '@gts-ft/utils';
import { TableWithExtraProperties } from '../../../types';
import { tableStatusClassesWithHover } from '../helpers/tableStatusClassesWithHover';
import { OccupiedTableReservationTimes } from '../OccupiedTableReservatioTimes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '40px',
      height: '100%',
      width: '100%',
      textTransform: 'none',
      display: 'block',
    },
    [`tableStatus${TableStatus.OCCUPIED}`]: tableStatusClassesWithHover(theme)[
      `tableStatus${TableStatus.OCCUPIED}`
    ],
  }),
);

interface UnblockTableProps {
  table: TableWithExtraProperties;
  isOnline: boolean;
  tableClicked: (table: TableWithExtraProperties) => void;
}

export const UnblockTable = ({
  table,
  isOnline,
  tableClicked,
}: UnblockTableProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      className={`${classes.root} ${
        classes[`tableStatus${TableStatus.OCCUPIED}`]
      }`}
      onClick={() => tableClicked(table)}
      variant="contained"
      color="inherit"
      disabled={!isOnline}
    >
      <Typography>
        {table.tableNo} - {table.personsNo}P
      </Typography>
      <OccupiedTableReservationTimes table={table} />
    </Button>
  );
};
