import {
  convertLocalDateStringToServerDateString,
  convertServerDateStringToLocalDateString,
} from '@gts-ft/utils';

export function transformClosedOnDatesForClient(closedOnDates: Array<string>) {
  return closedOnDates.map((closedOnDate) =>
    convertServerDateStringToLocalDateString(closedOnDate),
  );
}

export function transformClosedOnDatesForServer(closedOnDates: Array<string>) {
  return closedOnDates.map((closedOnDate) =>
    convertLocalDateStringToServerDateString(closedOnDate),
  );
}
