import { getWeekDay, toDayTime } from '@gts-ft/utils';
export function getTimeSelectOptions(decimalTimeList) {
    const days = [];
    const dayTimes = decimalTimeList.map((decimal) => {
        const [day, time] = toDayTime(decimal);
        return { day, time, decimal };
    });
    // get unique days and keep order (sunday can be before monday)
    dayTimes.forEach(({ day }) => {
        if (!days.includes(day)) {
            days.push(day);
        }
    });
    const timesOfDays = dayTimes.reduce((prev, { day, time, decimal }) => {
        var _a;
        const dayValues = (_a = prev[day]) !== null && _a !== void 0 ? _a : [];
        return Object.assign(Object.assign({}, prev), { [day]: [...dayValues, { label: time, value: decimal }] });
    }, {});
    const options = days.reduce((prev, weekday) => {
        return [
            ...prev,
            { label: getWeekDay(weekday), options: timesOfDays[weekday] },
        ];
    }, []);
    return options;
}
