import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ReservationFormData } from '@gts-ft/ui';
import { format } from 'date-fns';
import { CLIENT_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import { NavigationTo, ReduxState } from '../../types';

import { execAddReservation } from '../../actions';
import { ReservationAdd } from '../../components/Reservations/ReservationAdd';
import { Actions } from '../../actions/reduxActionTypes';
import { getLocationState } from '../helpers/getLocationState';

const reservationToAdd: ReservationFormData = {
  personsNo: 2,
  name: '',
  email: '',
  date: '',
  time: '',
  manualTableAllocation: false,
  remark: '',
  telephone: '',
  company: '',
  reservationDuration: 2,
  tableIds: [],
};

const mapStateToProps = (state: ReduxState) => {
  const { reservationDuration } = state.restaurant.runtimeSettings;
  const routerState = getLocationState(state);
  const startOfNextInterval = state.app.startOfNextInterval;

  const date = format(startOfNextInterval, CLIENT_DATE_FORMAT);
  const time = format(startOfNextInterval, TIME_FORMAT);

  const reservation: ReservationFormData = {
    ...reservationToAdd,
    reservationDuration,
    date,
    time,
  };

  return {
    tables: state.tables.entities,
    cameFrom: routerState.cameFrom,
    reservation: reservation,
    isOnline: state.app.isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddReservation(
    reservation: ReservationFormData,
    navigateBackTo: NavigationTo,
  ) {
    dispatch(execAddReservation(reservation, navigateBackTo));
  },
});

export const ReservationAddContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationAdd);
