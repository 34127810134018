import {
  TABLES_PATH,
  TABLES_VIRTUAL_PATH,
  EditRealTableRequestBody,
  EditVirtualTableRequestBody,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  createPartial,
  getErrorModalMessage,
  trimData,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  execOpenMessage,
  MessageType,
  AlertType,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  TABLE_UPDATED,
  TableUpdatedAction,
  Thunk,
} from '../reduxActionTypes';
import { clientErrors } from '../../validationMessages';

import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { TABLE_SETTINGS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';
import { isTableNameUnique } from './helpers/isTableNameUnique';

function updateTableSucceeded(
  tableId: string,
  table: EditRealTableRequestBody | EditVirtualTableRequestBody,
): TableUpdatedAction {
  return {
    type: TABLE_UPDATED,
    payload: { tableId, table },
  };
}

function updateTableOfflineDB(
  tableId: string,
  update: EditRealTableRequestBody | EditVirtualTableRequestBody,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.updateRecord('table', tableId, update);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execUpdateTable(
  tableId: string,
  tableToUpdate: EditRealTableRequestBody | EditVirtualTableRequestBody,
  isVirtual: boolean,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const { entities, items } = getState().tables;
    const originalData = entities[tableId];

    const partial = createPartial(originalData, tableToUpdate);

    if (Object.keys(partial).length !== 0) {
      const dataToSend:
        | EditRealTableRequestBody
        | EditVirtualTableRequestBody = trimData(['tableNo'], partial);

      if (isTableNameUnique(dataToSend.tableNo, items, entities, tableId)) {
        dispatch(startServerRequest());

        return serverComm
          .execPatchRequest<
            Record<string, never>,
            EditRealTableRequestBody | EditVirtualTableRequestBody
          >(
            `${isVirtual ? TABLES_VIRTUAL_PATH : TABLES_PATH}/${tableId}`,
            dataToSend,
          )
          .then(
            (resp) => {
              if (resp.succeeded === true) {
                dispatch(serverRequestSucceeded('Tisch aktualisiert.'));
                dispatch(updateTableSucceeded(tableId, dataToSend));
                dispatch(updateTableOfflineDB(tableId, dataToSend));
                dispatch(execReplace(TABLE_SETTINGS_LIST_VIEW));
              } else {
                dispatch(
                  serverRequestFailed(getClientOperationFailureReason(resp)),
                );
              }
            },
            (e: unknown) => {
              dispatch(serverRequestFailed(getErrorModalMessage(e)));
            },
          )
          .catch((e: unknown) => {
            sendErrorToServer(serverComm, e);
            dispatch(showError(getErrorModalMessage(e)));
          });
      } else {
        dispatch(
          execOpenMessage({
            type: MessageType.ALERT,
            alertType: AlertType.ERROR,
            text: clientErrors.TABLE_NUMBER_EXISTS,
          }),
        );
      }
    } else {
      dispatch(execReplace(TABLE_SETTINGS_LIST_VIEW));
    }
  };
}
