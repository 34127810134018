import { Box, Grid } from '@material-ui/core';
import {
  TextField,
  AddButton,
  CheckboxField,
  SelectField,
} from '@gts-common/client';

import { Form } from 'formik';

import { getPersonOptions } from './helpers/getPersonOptions';

const formId = 'tables-form';

export const TableForm = () => (
  <Form id={formId} noValidate={true}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Tischnummer"
          name="tableNo"
          required
          autoFocus
          hint="Beispiele für Tischnummern: 01, A01, A_01, AA-01."
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CheckboxField
          type="checkbox"
          name="visibleInWidget"
          hint="Falls das Häkchen nicht gesetzt ist, kann dieser Tisch nicht über das Reservierungsformular reserviert werden. Nur Sie können für diesen Tisch eine Reservierung anlegen."
        >
          durch Gast reservierbar
        </CheckboxField>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="personsNo"
          label="Personen"
          options={getPersonOptions()}
          required={true}
          hint="Maximale Personenanzahl, die für diesen Tisch erlaubt sind."
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="minPersons"
          label="Mindestbelegung"
          options={getPersonOptions()}
          required={true}
          hint="Mindestpersonenanzahl um diesen Tisch zu reservieren, z. B. für einen 4er Tisch müssen mindestens 2 Personen reservieren."
        />
      </Grid>
    </Grid>
    <Box mt="1rem" textAlign="center">
      <AddButton submitForForm={formId} label="Tisch hinzufügen" />
    </Box>
  </Form>
);
