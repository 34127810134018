import { PureComponent } from 'react';
import {
  Footer,
  LoginStatus,
  MessagesContainer,
  ReloadButton,
  SimpleHeader,
  WarningText,
  LinkButton,
  PleaseWaitText,
  GlobalError,
  sendErrorToServer,
} from '@gts-common/client';

import { Box, Container } from '@material-ui/core';

import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { serverComm } from '@gts-ft/ui';
import logo from '../images/logo.png';
import { HeaderContainer } from '../containers/Header';

import { HOME_VIEW } from '../constants';
import { MainRouter } from './MainRouter';
import { CouldNotStoreOfflineDataModal } from './Modals/CouldNotStoreOfflineDataModal';
import { ServiceWorkerNotInstalledModal } from './Modals/ServiceWorkerNotInstalledModal';

interface Props {
  loginStatus: LoginStatus;
  showOfflineView: boolean;
}

interface State {
  hasError: boolean;
}

const extraModals = {
  CouldNotStoreOfflineDataModal: CouldNotStoreOfflineDataModal,
  ServiceWorkerNotInstalledModal: ServiceWorkerNotInstalledModal,
};

export class App extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    sendErrorToServer(serverComm, error);
  }

  renderHeader() {
    const { loginStatus } = this.props;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      return <HeaderContainer />;
    } else {
      return <SimpleHeader to={HOME_VIEW} title="free-table" logoSrc={logo} />;
    }
  }

  renderOfflineView() {
    return (
      <Box mt="2rem">
        <WarningText>Die Anwendung befindet sich im Offline-Modus.</WarningText>
        <WarningText>
          Aktuell liegen keine Daten vor, die Ihnen free-table anzeigen kann.
        </WarningText>
        <Box mt="1rem" textAlign="center">
          <ReloadButton />
        </Box>
      </Box>
    );
  }

  renderMainContent() {
    const { loginStatus, showOfflineView } = this.props;

    if (showOfflineView) {
      return this.renderOfflineView();
    }

    if (loginStatus === LoginStatus.PENDING) {
      return (
        <Box mt="2rem">
          <PleaseWaitText />
        </Box>
      );
    } else if (loginStatus === LoginStatus.LOGGED_OUT) {
      return (
        <Box mt="2rem">
          <WarningText>
            Sie sind nicht angemeldet. Bitte melden Sie sich an, um
            fortzufahren.
          </WarningText>
          <Box mt="1rem" textAlign="center">
            <LinkButton
              external={true}
              to={`${getUrl(ServiceCode.SERVICE_MANAGER, UrlType.APP)}/login`}
              label="Zur Anmeldung"
              target="_self"
            />
          </Box>
        </Box>
      );
    } else {
      return <MainRouter />;
    }
  }

  renderFooter() {
    const { loginStatus } = this.props;

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      return (
        <Footer domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)} />
      );
    } else {
      return null;
    }
  }

  renderError() {
    return (
      <GlobalError
        to={HOME_VIEW}
        headerTitle="get the service"
        logo={logo}
        domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)}
      />
    );
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return this.renderError();
    }

    return (
      <>
        {this.renderHeader()}
        {/* bottom margin is used to offset the footer height */}
        <Container disableGutters={true} style={{ marginBottom: '80px' }}>
          {this.renderMainContent()}
        </Container>
        {this.renderFooter()}
        <MessagesContainer extraModals={extraModals} />
      </>
    );
  }
}
