import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PolicyIcon from '@material-ui/icons/Policy';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew';
import { MouseEvent } from 'react';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';

interface AccountMenuProps {
  anchor: MouseEvent['currentTarget'] | null;
  isOpen: boolean;
  isOnline: boolean;
  execLogout: () => void;
  closeMenu: () => void;
}

export const AccountMenu = ({
  anchor,
  isOpen,
  isOnline,
  execLogout,
  closeMenu,
}: AccountMenuProps) => {
  const domain = getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE);

  return (
    <Menu
      id="account-menu-appbar"
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={closeMenu}
      onClick={closeMenu}
    >
      <MenuItem component="a" href={domain + '/agb.html'} target="_blank">
        <ListItemIcon>
          <PolicyIcon />
        </ListItemIcon>
        <ListItemText>AGB</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem component="a" href={domain + '/impressum.html'} target="_blank">
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText>Impressum</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        component="a"
        href={domain + '/datenschutz.html'}
        target="_blank"
      >
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText>Datenschutz</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={execLogout} disabled={!isOnline}>
        <ListItemIcon>
          <PowerSettingsIcon />
        </ListItemIcon>
        <ListItemText>Abmelden</ListItemText>
      </MenuItem>
    </Menu>
  );
};
