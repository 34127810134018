import { Box, Grid } from '@material-ui/core';
import {
  EditButton,
  TextField,
  NumberField,
  EmailField,
  TextareaField,
  View,
  InfoText,
  NotFoundText,
  WarningText,
  CancelButton,
} from '@gts-common/client';
import { convertServerDateStringToLocalDateString } from '@gts-ft/utils';
import { Formik } from 'formik';
import { ReservationFormData } from '@gts-ft/ui';
import { NavigationCameFrom, NavigationTo } from '../../types';

interface Props {
  reservationId: string | undefined;
  reservationFormData:
    | (Partial<ReservationFormData> & { tableNos: string })
    | undefined;
  reservationArrived: boolean;
  reservationCancelledAt: string | null | undefined;
  reservationWasAnonymized: boolean;
  execCancelReservation: (
    reservationId: string,
    navigateBackTo: NavigationTo,
  ) => void;
  showReservationEdit: (
    reservationId: string,
    cameFrom: NavigationCameFrom,
  ) => void;
  isOnline: boolean;
  isCancelButtonDisabled: boolean;
  isEditButtonDisabled: boolean;
  cameFrom: NavigationCameFrom;
}

export const ReservationDetails = ({
  reservationId,
  execCancelReservation,
  showReservationEdit,
  isOnline,
  isCancelButtonDisabled,
  isEditButtonDisabled,
  reservationFormData,
  reservationArrived,
  reservationCancelledAt,
  reservationWasAnonymized,
  cameFrom,
}: Props) => {
  if (!reservationId) {
    return (
      <View
        hasBackButton={true}
        navigateBackTo={cameFrom.to}
        backButtonLabel={cameFrom.backButtonLabel}
        title="Details"
      >
        <NotFoundText>
          Die Reservierung existiert nicht. Bitte überprüfen Sie den Link und
          versuchen Sie es erneut.
        </NotFoundText>
      </View>
    );
  }

  const [cancelDateString, cancelTimeString] = reservationCancelledAt
    ? reservationCancelledAt.split(' ')
    : ['', ''];

  return (
    <View
      hasBackButton={true}
      navigateBackTo={cameFrom.to}
      backButtonLabel={cameFrom.backButtonLabel}
      title="Details"
    >
      {reservationCancelledAt && (
        <WarningText>
          Diese Reservierung wurde am{' '}
          {convertServerDateStringToLocalDateString(cancelDateString)} um{' '}
          {cancelTimeString} Uhr storniert.
        </WarningText>
      )}

      {reservationWasAnonymized && (
        <InfoText>
          Diese Reservierung wurde aus Datenschutzgründen anonymisiert.
        </InfoText>
      )}

      {reservationArrived && (
        <InfoText>Diese Reservierung wurde als angekommen markiert.</InfoText>
      )}

      <Formik
        onSubmit={() => undefined}
        initialValues={reservationFormData ?? {}}
      >
        <>
          <TextField disabled label="Name" name="name" />
          <TextareaField disabled label="Anmerkung" name="remark" />
          <TextField disabled label="Telefonnummer" name="telephone" />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <TextField disabled label="Datum" name="date" />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField disabled label="Uhrzeit" name="time" />
            </Grid>
          </Grid>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <NumberField disabled label="Personen" name="personsNo" />
            </Grid>
            <Grid item={true} xs={6}>
              <NumberField
                disabled
                label="Verweildauer in Std."
                name="reservationDuration"
              />
            </Grid>
          </Grid>
          <TextField disabled label="Tisch" name="tableNos" />
          <TextField disabled label="Firma" name="company" />
          <EmailField disabled label="E-Mail-Adresse" name="email" />
        </>
      </Formik>
      <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
        <Box mb="1rem" mx="0.5rem">
          <CancelButton
            onClick={() => execCancelReservation(reservationId, cameFrom.to)}
            label="Stornieren"
            disabled={!isOnline || isCancelButtonDisabled}
            variant="contained"
          />
        </Box>
        <Box mb="1rem" mx="0.5rem">
          <EditButton
            onClick={() => showReservationEdit(reservationId, cameFrom)}
            label="Bearbeiten"
            disabled={!isOnline || isEditButtonDisabled}
          />
        </Box>
      </Box>
    </View>
  );
};
