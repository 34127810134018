import {
  TABLES_PATH,
  TABLES_VIRTUAL_PATH,
  AddedTable,
  AddRealTableRequestBody,
  AddRealTableResponse,
  AddVirtualTableRequestBody,
  AddVirtualTableResponse,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  trimData,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  execOpenMessage,
  MessageType,
  AlertType,
  normalize,
  Normalized,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  TABLE_ADDED,
  TableAddedAction,
  Thunk,
} from '../reduxActionTypes';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';

import { clientErrors } from '../../validationMessages';
import { TABLE_SETTINGS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';
import { isTableNameUnique } from './helpers/isTableNameUnique';

function addTableSucceeded(table: Normalized<AddedTable>): TableAddedAction {
  return {
    type: TABLE_ADDED,
    payload: table,
  };
}

function addTableOfflineDB(table: AddedTable): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.addRecord('table', table);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execAddTable(
  table: AddRealTableRequestBody | AddVirtualTableRequestBody,
  isVirtual: boolean,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const { entities, items } = getState().tables;

    const dataToSend:
      | AddRealTableRequestBody
      | AddVirtualTableRequestBody = trimData(['tableNo'], table);

    if (isTableNameUnique(dataToSend.tableNo, items, entities)) {
      dispatch(startServerRequest());

      return serverComm
        .execPostRequest<
          AddRealTableResponse | AddVirtualTableResponse,
          AddRealTableRequestBody | AddVirtualTableRequestBody
        >(isVirtual ? TABLES_VIRTUAL_PATH : TABLES_PATH, dataToSend)
        .then(
          (resp) => {
            if (resp.succeeded) {
              const normalizedTable = normalize('tableId', resp.body);

              dispatch(serverRequestSucceeded('Tisch hinzugefügt.'));
              dispatch(addTableSucceeded(normalizedTable));
              dispatch(addTableOfflineDB(resp.body));
              dispatch(execReplace(TABLE_SETTINGS_LIST_VIEW));
            } else {
              dispatch(
                serverRequestFailed(getClientOperationFailureReason(resp)),
              );
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    } else {
      dispatch(
        execOpenMessage({
          type: MessageType.ALERT,
          alertType: AlertType.ERROR,
          text: clientErrors.TABLE_NUMBER_EXISTS,
        }),
      );
    }
  };
}
