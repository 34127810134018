import { MobileTimePicker } from '@material-ui/pickers';
import { useState } from 'react';
import { TIME_FORMAT } from '@gts-common/client-server';
import { PickerVariant } from './PickerVariant';
import { PickerVariantType } from './types';

interface TimePickerProps {
  variant: PickerVariantType;
  value: Date | null;
  onAccept?: (newDate: Date | null) => void;
  onChange?: (newDate: Date | null) => void;
  label: string;
  disabled: boolean;
}

export const TimePicker = ({
  variant,
  value,
  onChange,
  onAccept,
  label,
  disabled,
}: TimePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <MobileTimePicker
      label={label}
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(newDate) => {
        if (onChange) {
          onChange(newDate);
        }
      }}
      onAccept={(newDate) => {
        if (onAccept) {
          onAccept(newDate);
        }
      }}
      renderInput={(props) => {
        if (props.inputProps) {
          return (
            <PickerVariant
              type="time"
              variant={variant}
              label={label}
              value={props.inputProps.value}
              onClick={() => setOpen(true)}
              disabled={disabled}
            />
          );
        } else {
          return <div></div>;
        }
      }}
      inputFormat={TIME_FORMAT}
      mask="__:__"
      minutesStep={15}
      shouldDisableTime={(
        value: number,
        clockType: 'hours' | 'minutes' | 'seconds',
      ) => {
        if (clockType === 'minutes') {
          if (value === 0 || value === 15 || value === 30 || value === 45) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }}
      disableCloseOnSelect={false}
    />
  );
};
