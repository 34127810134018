import { AddedReservation } from '@gts-ft/utils';
import { sendErrorToServer } from '@gts-common/client';
import { serverComm } from '@gts-ft/ui';
import { db } from '../../../db';
import { showOnceCouldNotStoreOfflineData } from '../../showOnceCouldNotStoreOfflineData';
import { Actions, Thunk } from '../../reduxActionTypes';

export function addReservationsToOfflineDB(
  reservations: Array<AddedReservation>,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.addRecords('reservation', reservations);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}
