import { InfoText, View } from '@gts-common/client';
import { NavigationCameFrom, TableWithExtraProperties } from '../../../types';
import { UnblockTablesList } from './UnblockTablesList';

interface Props {
  cameFrom: NavigationCameFrom;
  isOnline: boolean;
  unblockClicked: (table: TableWithExtraProperties) => void;
  tables: Array<TableWithExtraProperties>;
}

export const UnblockTablesView = ({
  unblockClicked,
  cameFrom,
  isOnline,
  tables,
}: Props) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={cameFrom.to}
      backButtonLabel={cameFrom.backButtonLabel}
      title="Tische freigeben"
    >
      {tables.length === 0 && <InfoText>Keine Tische zum Freigeben.</InfoText>}
      <UnblockTablesList
        tables={tables}
        tableClicked={unblockClicked}
        isOnline={isOnline}
      />
    </View>
  );
};
