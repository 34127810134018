import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';
import {
  APP_LIVE_DATE_CHANGED,
  AppLiveDateChangedAction,
} from './reduxActionTypes';

function tick(): AppLiveDateChangedAction {
  return {
    type: APP_LIVE_DATE_CHANGED,
    payload: new Date(),
  };
}

export function startLiveDate(
  dispatch: ThunkDispatch<ReduxState, unknown, AppLiveDateChangedAction>,
) {
  setInterval(() => dispatch(tick()), 60000);
}
