import { initialState } from '../initialState';
import {
  Actions,
  TABLE_ADDED,
  TABLE_BULK_ADDED,
  TABLE_DELETED,
  TABLE_UPDATED,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  TABLE_BOOKING_STATUS_DATETIME,
} from '../actions/reduxActionTypes';
import { TablesState } from '../types';

export function tablesReducer(
  state = initialState.tables,
  action: Actions,
): TablesState {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload.tables,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        ...action.payload.tables,
      };

    // Reset all data
    case LOGOUT_SUCCEEDED:
      return initialState.tables;

    case TABLE_ADDED:
      return {
        ...state,
        items: [...state.items, ...action.payload.items],
        entities: { ...state.entities, ...action.payload.entities },
      };

    // Throw all other tables away, since this can only be called from the wizard
    case TABLE_BULK_ADDED:
      return {
        ...state,
        items: action.payload.items,
        entities: action.payload.entities,
      };

    case TABLE_UPDATED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.tableId]: {
            ...state.entities[action.payload.tableId],
            ...action.payload.table,
          },
        },
      };

    case TABLE_DELETED:
      return {
        ...state,
        items: state.items.filter((tableId) => tableId !== action.payload),
      };

    case TABLE_BOOKING_STATUS_DATETIME:
      return {
        ...state,
        tableBookingStatusDatetime: action.payload,
      };

    default:
      return state;
  }
}
