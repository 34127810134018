import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  createMatchSelector,
  push,
  RouterRootState,
} from 'connected-react-router';
import { AddedTable } from '@gts-ft/utils';
import {
  ClientReservation,
  NavigationCameFrom,
  NavigationTo,
  ReduxState,
} from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { execCancelReservationDecision } from '../../actions';
import { ReservationDetails } from '../../components/Reservations/ReservationDetails';
import {
  RESERVATIONS_DETAILS_PATH,
  RESERVATIONS_DETAILS_VIEW,
  RESERVATIONS_EDIT_PATH,
} from '../../constants';
import { getLocationState } from '../helpers/getLocationState';

const matchSelector = createMatchSelector<
  RouterRootState,
  { reservationId: string }
>(RESERVATIONS_DETAILS_VIEW);

function getReservationFormData(
  reservation: ClientReservation,
  tableEntities: Record<string, AddedTable>,
) {
  const tableNos = reservation.tableIds
    .map((tableId) => tableEntities[tableId].tableNo)
    .join(', ');

  return {
    name: reservation.name ? reservation.name : 'k.A.',
    remark: reservation.remark ? reservation.remark : 'k.A.',
    telephone: reservation.telephone ? reservation.telephone : 'k.A.',
    company: reservation.company ? reservation.company : 'k.A.',
    email: reservation.email ? reservation.email : 'k.A.',
    date: reservation.date,
    time: reservation.time,
    personsNo: reservation.personsNo,
    reservationDuration: reservation.reservationDuration,
    tableIds: reservation.tableIds,
    tableNos,
  };
}

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const reservationId = match ? match.params.reservationId : undefined;
  const reservationsState = state.reservations;
  const tableEntities = state.tables.entities;
  const isOnline = state.app.isOnline;
  const { entities } = reservationsState;
  const selectedReservation = entities[reservationId ?? ''];
  const routerState = getLocationState(state);

  // reservationId could be defined but still not existent
  // so check to make sure we found a reservation
  if (selectedReservation) {
    const isCancelButtonDisabled =
      selectedReservation.wasAnonymized ||
      selectedReservation.cancelledAt !== null;
    const isEditButtonDisabled = isCancelButtonDisabled;

    return {
      reservationId: selectedReservation.reservationId,
      reservationFormData: getReservationFormData(
        selectedReservation,
        tableEntities,
      ),
      reservationArrived: selectedReservation.arrived,
      reservationCancelledAt: selectedReservation.cancelledAt,
      reservationWasAnonymized: selectedReservation.wasAnonymized,
      isOnline,
      isCancelButtonDisabled,
      isEditButtonDisabled,
      cameFrom: routerState.cameFrom,
    };
  } else {
    return {
      reservationId: undefined,
      reservationFormData: undefined,
      isOnline,
      isCancelButtonDisabled: true,
      isEditButtonDisabled: true,
      reservationArrived: false,
      reservationCancelledAt: null,
      reservationWasAnonymized: false,
      cameFrom: routerState.cameFrom,
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execCancelReservation(reservationId: string, navigateBackTo: NavigationTo) {
    dispatch(execCancelReservationDecision(reservationId, navigateBackTo));
  },
  showReservationEdit(reservationId: string, cameFrom: NavigationCameFrom) {
    dispatch(
      push({
        pathname: `${RESERVATIONS_EDIT_PATH}/${reservationId}`,
        state: {
          cameFrom: {
            backButtonLabel: 'Details',
            to: {
              pathname: `${RESERVATIONS_DETAILS_PATH}/${reservationId}`,
              state: {
                cameFrom,
              },
            },
          },
        },
      }),
    );
  },
});

export const ReservationDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationDetails);
