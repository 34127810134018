import { denormalize } from '@gts-common/client';

import { AddedTable } from '@gts-ft/utils';
import { ServiceAreasState } from '../../types';

export function filterAssignedTables(
  tables: Array<AddedTable>,
  serviceAreaState: ServiceAreasState,
  serviceAreaId?: string,
) {
  const denormalizedServiceAreas = denormalize(serviceAreaState);
  // own assigned Tables should be available
  const assignedTableIds = (serviceAreaId === undefined
    ? denormalizedServiceAreas
    : denormalizedServiceAreas.filter(
        (serviceArea) => serviceArea.serviceAreaId !== serviceAreaId,
      )
  ).flatMap((serviceArea) => serviceArea.tableIds);
  return tables.filter((table) => !assignedTableIds.includes(table.tableId));
}
