import { LoginResponse } from '@gts-ft/utils';
import { sendErrorToServer } from '@gts-common/client';
import { serverComm } from '@gts-ft/ui';
import { Actions, Thunk } from '../reduxActionTypes';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';

export function saveDataInOfflineStorage(
  tables: LoginResponse['tables'],
  reservations: LoginResponse['reservations'],
  restaurant: LoginResponse['restaurant'],
  serviceAreas: LoginResponse['serviceAreas'],
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.saveInitialData(tables, reservations, restaurant, serviceAreas);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}
