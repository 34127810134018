import {
  RESERVATIONS_ADDITIONAL_PATH,
  GetAdditionalReservationsRequestQuery,
  GetReservationsResponse,
} from '@gts-ft/utils';
import {
  IndexSignatureHack,
  SERVER_DATE_FORMAT,
} from '@gts-common/client-server';
import { isBefore, format, subDays } from 'date-fns';
import { serverComm } from '@gts-ft/ui';
import {
  normalize,
  getErrorModalMessage,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  EARLIEST_LOADED_DATE_CHANGED,
  Thunk,
} from '../reduxActionTypes';

import { reservationsTransformForClient } from '../helpers/prepareLoginResponseForClient';
import { addReservationsToOfflineDB } from './helpers/addReservationsToOfflineDB';

export function execGetAdditionalReservations(
  chosenDate: Date,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const lastDate = getState().reservations.earliestLoadedDate;
    if (isBefore(chosenDate, lastDate)) {
      const start = format(subDays(chosenDate, 7), SERVER_DATE_FORMAT);
      const end = format(lastDate, SERVER_DATE_FORMAT);

      dispatch(startServerRequest());

      const query: GetAdditionalReservationsRequestQuery = {
        start,
        end,
      };

      // treats dates as if time := 00:00.00, so reservations at start date are included, at end date are excluded
      return serverComm
        .execGetRequest<
          GetReservationsResponse,
          IndexSignatureHack<GetAdditionalReservationsRequestQuery>
        >(RESERVATIONS_ADDITIONAL_PATH, query)
        .then(
          (resp) => {
            if (resp.succeeded === true) {
              dispatch(addReservationsToOfflineDB(resp.body));
              const normalizedReservations = normalize(
                'reservationId',
                reservationsTransformForClient(resp.body),
              );

              dispatch({
                type: EARLIEST_LOADED_DATE_CHANGED,
                payload: {
                  ...normalizedReservations,
                  loadedDate: chosenDate,
                },
              });
              dispatch(serverRequestSucceeded());
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    }
  };
}
