import { RESTAURANT_OPENING_TIMES_PATH } from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  Actions,
  RESTAURANT_RESERVATION_TIMES_UPDATED,
  RestaurantReservationTimesUpdatedAction,
  Thunk,
} from '../reduxActionTypes';
import {
  RestaurantFromServer,
  RestaurantReservationTimesState,
} from '../../types';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import {
  createRestaurantReservationTimesObjectForClient,
  createRestaurantReservationTimesObjectForServer,
} from './helpers/createRestaurantReservationTimesObject';

function restaurantReservationTimesChangeSucceeded(
  restaurantReservationTimesObject: RestaurantFromServer['openingTimes'],
): RestaurantReservationTimesUpdatedAction {
  const restaurantReservationTimes = createRestaurantReservationTimesObjectForClient(
    restaurantReservationTimesObject,
  );

  return {
    type: RESTAURANT_RESERVATION_TIMES_UPDATED,
    payload: restaurantReservationTimes,
  };
}

function updateRestaurantOpeningTimesOfflineDB(
  restaurantId: string,
  update: RestaurantReservationTimesState,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.updateRecord('restaurant', restaurantId, {
        openingTimes: update,
      });
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execRestaurantOpeningTimesChange(
  restaurantOpeningTimesObject: RestaurantReservationTimesState,
  actionToDispatchAfterSuccess: Actions,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const restaurantId = getState().restaurant.restaurantId;
    const restaurantOpeningTimesDb = createRestaurantReservationTimesObjectForServer(
      restaurantOpeningTimesObject,
    );

    dispatch(startServerRequest());
    return serverComm
      .execPatchRequest(RESTAURANT_OPENING_TIMES_PATH, restaurantOpeningTimesDb)
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(
              serverRequestSucceeded('Reservierungszeiten aktualisiert.'),
            );
            dispatch(
              updateRestaurantOpeningTimesOfflineDB(
                restaurantId,
                restaurantOpeningTimesDb,
              ),
            );
            dispatch(
              restaurantReservationTimesChangeSucceeded(
                restaurantOpeningTimesDb,
              ),
            );
            dispatch(actionToDispatchAfterSuccess);
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
