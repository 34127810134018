import { SERVICE_AREA_PATH } from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  ExtraModalMessage,
  MessageType,
  ModalType,
  execOpenMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  SERVICE_AREA_DELETED,
  ServiceAreaDeletedAction,
  Thunk,
} from '../reduxActionTypes';

import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { SERVICE_AREAS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';

function deleteServiceAreaSucceeded(
  serviceAreaId: string,
): ServiceAreaDeletedAction {
  return {
    type: SERVICE_AREA_DELETED,
    payload: serviceAreaId,
  };
}

function deleteServiceAreaOfflineDB(serviceAreaId: string): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.deleteRecord('serviceArea', serviceAreaId);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execDeleteServiceArea(serviceAreaId: string): Thunk<Actions> {
  return (dispatch) => {
    dispatch(startServerRequest());

    return serverComm
      .execDeleteRequest(`${SERVICE_AREA_PATH}/${serviceAreaId}`)
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            dispatch(serverRequestSucceeded('Servicebereich gelöscht.'));
            dispatch(deleteServiceAreaSucceeded(serviceAreaId));
            dispatch(deleteServiceAreaOfflineDB(serviceAreaId));
            dispatch(execReplace(SERVICE_AREAS_LIST_VIEW));
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}

export function execDeleteServiceAreaDecision(serviceAreaId: string) {
  const modal: ExtraModalMessage = {
    type: MessageType.MODAL,
    modalType: ModalType.DECISION_MODAL,
    title: 'Servicebereich löschen?',
    body:
      'Wollen Sie den Servicebereich wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
    extraProps: {
      confirmAction: execDeleteServiceArea(serviceAreaId),
    },
  };

  return execOpenMessage(modal);
}
