import { TextField, NumberField, TextareaField } from '@gts-common/client';
import { Form, Formik } from 'formik';
import { restaurantSettingsValidation } from '@gts-ft/ui';
import { RestaurantSettingsState } from '../../../types';

interface Props {
  settings: RestaurantSettingsState;
  execRestaurantSettingsChange: (settings: RestaurantSettingsState) => void;
}

export const widgetSettingsFormId = 'widget-settings';

export const WidgetSettings = ({
  settings,
  execRestaurantSettingsChange,
}: Props) => {
  return (
    <Formik
      onSubmit={execRestaurantSettingsChange}
      initialValues={settings}
      validationSchema={restaurantSettingsValidation}
    >
      <Form id={widgetSettingsFormId} noValidate={true}>
        <TextField
          label="Gastronomiename"
          name="restaurantName"
          required
          autoFocus
        />
        <TextareaField
          label="Informationstext"
          name="widgetRestaurantInfoText"
          hint="Hier können Sie zusätzliche Informationen zu Ihrer Gastronomie hinterlegen, wie z. B. an welchen Wochentagen Sie geöffnet haben. Bitte beachten Sie, dass die Texte nicht zu lang werden, da Sie dann von der eigentlichen Reservierung ablenken."
          required
        />
        <NumberField
          label="Zeitraum (Wochen)"
          name="reservationInAdvancedWeeks"
          hint="Bis wann sind Reservierung in der Zukunft möglich ab heute in Wochen. Beispiel: Sie haben 4 Wochen eingetragen und heute ist der 01.01., dann können Ihre Gäste bis zum 28.01. über das Reservierformular bei Ihnen reservieren."
          required
        />
        <NumberField
          label="Max. Personenzahl"
          name="maxPersonsInWidget"
          hint="Maximale Personenzahl pro Reservierung über das Reservierungsformular."
          required
        />
        <TextareaField
          label="Zu viele Personen"
          name="reservationText"
          hint="Text, der angezeigt wird, wenn die maximale Personenzahl  für eine Reservierung überschritten wird."
          required
        />
      </Form>
    </Formik>
  );
};
