import { connect } from 'react-redux';
import { format } from 'date-fns';
import DE from 'date-fns/locale/de';
import {
  CLIENT_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '@gts-common/client-server';
import { ThunkDispatch } from 'redux-thunk';
import { ReduxState } from '../types';

import { Header } from '../components/Header/Header';
import { execLogout } from '../actions';
import { Actions } from '../actions/reduxActionTypes';

const mapStateToProps = (state: ReduxState) => {
  const {
    offlineDataTimestamp: offlineDataTimestampMillis,
    isOnline,
    liveDatetime,
  } = state.app;
  const { isSetupCompleted } = state.setupWizard;

  const dateToDisplay = format(liveDatetime, 'cccccc. dd.MM', {
    locale: DE,
  });
  const timeToDisplay = format(liveDatetime, TIME_FORMAT);

  const offlineDataTimestamp = offlineDataTimestampMillis
    ? format(offlineDataTimestampMillis, CLIENT_DATE_TIME_FORMAT)
    : '';

  return {
    date: dateToDisplay,
    time: timeToDisplay,
    offlineDataTimestamp,
    isOnline,
    isSetupCompleted,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execLogout() {
    dispatch(execLogout());
  },
});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
