import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { ServiceAreasList } from '../../components/ServiceAreas/ServiceAreasList';

import { execDeleteServiceAreaDecision } from '../../actions';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import {
  SERVICE_AREAS_ADD_VIEW,
  SERVICE_AREAS_EDIT_PATH,
} from '../../constants';

const mapStateToProps = (state: ReduxState) => {
  const isOnline = state.app.isOnline;
  const serviceAreasState = state.serviceAreas;
  const tableState = state.tables;
  const tables = tableState.entities;
  const denormalizedServiceAreas = denormalize(serviceAreasState).map(
    (serviceArea) => ({
      ...serviceArea,
      tableNos: sortTablesByTableNo(
        serviceArea.tableIds.map((tableId) => tables[tableId]),
      ).map((table) => table.tableNo),
    }),
  );
  return {
    serviceAreas: denormalizedServiceAreas,
    isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  onDelete(serviceAreaId: string) {
    dispatch(execDeleteServiceAreaDecision(serviceAreaId));
  },
  onEdit(serviceAreaId: string) {
    dispatch(push(`${SERVICE_AREAS_EDIT_PATH}/${serviceAreaId}`));
  },
  onAdd() {
    dispatch(push(SERVICE_AREAS_ADD_VIEW));
  },
});

export const ServiceAreasListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAreasList);
