import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReservationsTodayViewContainer } from '../../containers/Reservations/ReservationsTodayView';
import { ReservationAddContainer } from '../../containers/Reservations/ReservationAdd';
import { ReservationEditContainer } from '../../containers/Reservations/ReservationEdit';
import { ReservationDetailsContainer } from '../../containers/Reservations/ReservationDetails';

import {
  RESERVATIONS_ADD_VIEW,
  RESERVATIONS_ALL_VIEW,
  RESERVATIONS_DETAILS_VIEW,
  RESERVATIONS_EDIT_VIEW,
  RESERVATIONS_TODAY_VIEW,
} from '../../constants';
import { ReservationsAllViewContainer } from '../../containers/Reservations/ReservationsAllView';

export const ReservationsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={RESERVATIONS_ADD_VIEW}>
        <ReservationAddContainer />
      </Route>
      <Route path={RESERVATIONS_DETAILS_VIEW}>
        <ReservationDetailsContainer />
      </Route>
      <Route path={RESERVATIONS_EDIT_VIEW}>
        <ReservationEditContainer />
      </Route>
      <Route path={RESERVATIONS_TODAY_VIEW}>
        <ReservationsTodayViewContainer />
      </Route>
      <Route path={RESERVATIONS_ALL_VIEW}>
        <ReservationsAllViewContainer />
      </Route>
      <Route path={path} exact={true}>
        <ReservationsTodayViewContainer />
      </Route>
    </Switch>
  );
};
