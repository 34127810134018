import { AddedServiceArea } from '@gts-ft/utils';

export function isServiceAreaNameUnique(
  newName: string | undefined,
  items: Array<string>,
  entities: Record<string, AddedServiceArea>,
  currentServiceAreaId?: string,
) {
  // In case of an update the name could be undefined if the user
  // does not want to update it. Consider it to be unique and skip the test
  if (typeof newName === 'undefined') {
    return true;
  }

  return items
    .filter((serviceAreaId) => serviceAreaId !== currentServiceAreaId)
    .every((serviceAreaId) => entities[serviceAreaId].name !== newName);
}
