import { addMinutes, isAfter, isBefore, subMinutes } from 'date-fns';
import { TableStatus, getMinutesFromDecimal } from '@gts-ft/utils';

export function getTableStatus(
  currentDatetime: Date,
  reservationDatetime: Date,
  reservationDuration: number,
  tableStatusReservedTime: number,
) {
  const tableStatusReservedMinutes = getMinutesFromDecimal(
    tableStatusReservedTime,
  );
  const reservationDurationMinutes = getMinutesFromDecimal(reservationDuration);
  // If the current datetime is between the reservation datetime and
  // the reservation datetime - tableStatusReservedMinutes then consider
  // the table to be reserved
  if (
    isAfter(reservationDatetime, currentDatetime) &&
    isAfter(
      currentDatetime,
      // Add 1 so that a table is marked as reserved if we have a reservation for 10:00, currentDatetime is 09:30 and tableStatusReservedMinutes is 30 minutes
      subMinutes(reservationDatetime, tableStatusReservedMinutes + 1),
    )
  ) {
    return TableStatus.RESERVED;
  } else if (
    isBefore(
      currentDatetime,
      subMinutes(reservationDatetime, tableStatusReservedMinutes),
    ) ||
    isAfter(
      currentDatetime,
      // Sub 1 so that a table is marked as free if we have a reservation for 10:00, currentDatetime is 12:00 and reservationDuration is 2 hours
      addMinutes(reservationDatetime, reservationDurationMinutes - 1),
    )
  ) {
    return TableStatus.FREE;
  } else {
    // In all other cases it is OCCUPIED. The NOT_RESERVED status
    // is set if we have no reservation at all and is set at another time
    return TableStatus.OCCUPIED;
  }
}
