import { UploadButton } from '@gts-common/client';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useCallback, useState, MouseEvent, useEffect } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { serverComm } from '@gts-ft/ui';
import { DummyFileObject, FileObject } from './types';
import { FilePreview } from './FilePreview';
import { getFile, readFile, uploadFileObjects } from './helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '200px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.action.active,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey['200'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outlineColor: theme.palette.info.main,
      cursor: 'pointer',
    },
  }),
);

/*
 * Note:
 * 'Nur Bilder kleiner als 1MB sind erlaubt.' is currently static and won't react to different file size limits or types
 */

export enum FileUploaderAction {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  REMOVE = 'REMOVE',
  READ_DROPPED_FILES = 'READ_DROPPED_FILES',
  DROP = 'DROP',
}

interface FileUploadProps {
  path: string;
  accept?: Array<string>;
  maxFiles?: number;
  maxSize?: number;
  multiple?: boolean;
  fileIds: Array<string>;
  onSuccess?: (
    action: FileUploaderAction,
    fileId: Array<string> | string | undefined,
  ) => void;
  onFailure?: (
    action: FileUploaderAction,
    reason: string,
    originalError?: unknown,
  ) => void;
}

function getDropzoneText(isDragActive: boolean, isDragReject: boolean) {
  if (isDragReject) {
    return 'Nur Bilder kleiner als 1MB sind erlaubt.';
  }

  if (isDragActive) {
    return 'Drop, um das Logo auszuwählen.';
  } else {
    return 'Klick oder Drag & Drop, um ein Logo auszuwählen.';
  }
}

export const FileUploader = ({
  path,
  maxFiles = 1,
  maxSize = 1 * 1024 * 1024, // 1MB
  multiple = false,
  accept = ['image/*'],
  fileIds = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFailure = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess = () => {},
}: FileUploadProps) => {
  const classes = useStyles();
  const [fileObjects, setFileObjects] = useState<
    Array<FileObject | DummyFileObject>
  >([]);
  const [disableUploadBtn, setDisableUploadBtn] = useState<boolean>(true);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      return Promise.all(acceptedFiles.map((file) => readFile(file)))
        .then((data) => {
          return data.map((d, index) => ({
            file: acceptedFiles[index],
            data: d,
            isLoading: false,
          }));
        })
        .then((newFileObjects) => {
          setFileObjects(newFileObjects);
        })
        .catch((e: unknown) => {
          onFailure(
            FileUploaderAction.READ_DROPPED_FILES,
            'Fehler: Bitte versuchen Sie es erneut oder wählen Sie eine andere Datei aus.',
            e,
          );
        });
    },
    [onFailure],
  );

  const onDropRejected = useCallback(() => {
    onFailure(
      FileUploaderAction.DROP,
      'Nur Bilder kleiner als 1MB sind erlaubt.',
    );
  }, [onFailure]);

  // Click handler for the delete button
  const onRemove = useCallback(
    (e: MouseEvent, index: number) => {
      e.stopPropagation();

      const fileToDelete = fileObjects[index];
      const fileId = fileToDelete.id;

      let promise: Promise<string | undefined>;

      if (fileId !== undefined) {
        const loadingFileObjects = fileObjects.map((fileObject, i) => {
          if (i === index) {
            return {
              ...fileObject,
              isLoading: true,
            };
          } else {
            return fileObject;
          }
        });
        setFileObjects(loadingFileObjects);

        promise = serverComm
          .execDeleteRequest(`${path}/${fileId}`)
          .then(() => fileId);
      } else {
        promise = Promise.resolve<undefined>(undefined);
      }

      promise
        .then((fileId: string | undefined) => {
          const newFileObjects = fileObjects.filter(
            (_fileObject, i) => index !== i,
          );
          // First inform the caller that the file was removed so that they can update
          // any file ids to remove the file before setting the file objects.
          // If we first set the file objects and then inform the caller we will send
          // a server request with the previous ID which in the case of the restaurant logo
          // results in an error because the file is no longer available.
          // This is not a very clean solution and we should refactor this component to avoid
          // such a dependency
          onSuccess(FileUploaderAction.REMOVE, fileId);

          setFileObjects(newFileObjects);
        })
        .catch((e: unknown) => {
          const newFileObjects = fileObjects.map((fileObject, i) => {
            if (i === index) {
              return {
                ...fileObject,
                isLoading: false,
              };
            } else {
              return fileObject;
            }
          });

          setFileObjects(newFileObjects);
          onFailure(
            FileUploaderAction.REMOVE,
            'Fehler: Bitte versuchen Sie es erneut.',
            e,
          );
        });
    },
    [fileObjects, onFailure, onSuccess, path],
  );

  // Click handler for the upload button
  const onUpload = useCallback(() => {
    const filesToUpload = fileObjects
      .filter((fileObject) => fileObject.id === undefined)
      .map((fileObject) => {
        return {
          ...fileObject,
          isLoading: true,
        };
      }) as Array<FileObject>;
    const alreadyUploadedFiles = fileObjects.filter(
      (fileObject) => fileObject.id !== undefined,
    );
    setFileObjects([...alreadyUploadedFiles, ...filesToUpload]);

    uploadFileObjects(path, multiple, filesToUpload)
      .then((fileIds: Array<string>) => {
        const newFileObjects = fileObjects.map((fileObject, i) => {
          return {
            ...fileObject,
            isLoading: false,
            id: fileIds[i],
          };
        });

        setFileObjects([...alreadyUploadedFiles, ...newFileObjects]);
        onSuccess(FileUploaderAction.UPLOAD, fileIds);
      })
      .catch((e: unknown) => {
        const newFileObjects = fileObjects.map((fileObject) => {
          return {
            ...fileObject,
            isLoading: false,
          };
        });

        setFileObjects(newFileObjects);
        onFailure(
          FileUploaderAction.UPLOAD,
          'Fehler: Bitte versuchen Sie es erneut.',
          e,
        );
      });
  }, [path, multiple, fileObjects, onFailure, onSuccess]);

  // Load the files that are already uploaded
  // Warning: If this is triggered and later the component
  // gets unmounted before the promises resolved it will result
  // in an exception and potential memory leak.
  // In such a case either make sure that no change is triggered or the
  // component is not unmounted or add a way to cancel the promises
  useEffect(() => {
    const knownIds = fileObjects.map((file) => file.id);
    // Only download unknown files. If we already have
    // the file no need to download it again
    const fileIdsToDownload = fileIds.filter(
      (fileId) => !knownIds.includes(fileId),
    );
    // Get files that we already know and we still need because those
    // are in the given fileIds. Concat this with the new files
    // from the server
    const knownFiles = fileObjects.filter((file) => {
      if (file.id) {
        return fileIds.includes(file.id);
      } else {
        return false;
      }
    });

    const dummyFiles: Array<DummyFileObject> = fileIdsToDownload.map(
      (fileId) => {
        return {
          id: fileId,
          isLoading: true,
          data: undefined,
          file: undefined,
        };
      },
    );

    if (fileIdsToDownload.length > 0) {
      setFileObjects(dummyFiles);
      Promise.all(
        fileIdsToDownload.map((fileId) => {
          return getFile(path, fileId);
        }),
      )
        .then((newFileObjects) => {
          setFileObjects([...knownFiles, ...newFileObjects]);
          onSuccess(FileUploaderAction.DOWNLOAD, fileIds);
        })
        .catch((e: unknown) => {
          onFailure(
            FileUploaderAction.DOWNLOAD,
            'Fehler: Bitte versuchen Sie es erneut.',
            e,
          );
        });
    }
  }, [path, fileIds, fileObjects, onFailure, onSuccess]);

  // Set the disableUploadBtn state
  useEffect(() => {
    if (fileObjects.length === 0) {
      setDisableUploadBtn(true);
    } else {
      const allUploaded = fileObjects.every(
        (fileObject) => fileObject.id !== undefined,
      );
      setDisableUploadBtn(allUploaded);
    }
  }, [fileObjects]);

  const dropzoneOptions: DropzoneOptions = {
    accept,
    onDrop,
    onDropRejected,
    maxFiles,
    maxSize,
    multiple,
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <div {...getRootProps({ className: classes.root })}>
        <input {...getInputProps()} />
        {fileObjects.length === 0 && (
          <Typography variant="body2">
            {getDropzoneText(isDragActive, isDragReject)}
          </Typography>
        )}
        <FilePreview fileObjects={fileObjects} onRemove={onRemove} />
      </div>
      <Box mt="2rem">
        <UploadButton onClick={onUpload} disabled={disableUploadBtn} />
      </Box>
    </Box>
  );
};
