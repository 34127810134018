import { RESERVATIONS_PATH, EditReservationRequestBody } from '@gts-ft/utils';
import {
  ReservationFormData,
  serverComm,
  getClientOperationFailureReason,
} from '@gts-ft/ui';
import { ThunkDispatch } from 'redux-thunk';
import {
  createPartial,
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { parse } from 'date-fns';
import { CLIENT_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import {
  Actions,
  RESERVATION_UPDATED,
  ReservationUpdatedAction,
  Thunk,
} from '../reduxActionTypes';
import { pairTableIds } from '../helpers/pairTableIds';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { ClientReservation, NavigationTo, ReduxState } from '../../types';

import { execReplace } from '../navigation';
import { prepareReservationForServer } from './helpers/prepareReservationForServer';

export function updateReservationSucceeded(
  reservationId: string,
  reservation: Partial<ClientReservation>,
): ReservationUpdatedAction {
  return {
    type: RESERVATION_UPDATED,
    payload: { reservationId, reservation },
  };
}

export function updateReservationOfflineDB(
  reservationId: string,
  update: Partial<
    ReservationFormData & { cancelledAt: string; arrived: boolean }
  >,
) {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, Actions>) => {
    try {
      await db.updateRecord('reservation', reservationId, update);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execUpdateReservation(
  reservationId: string,
  reservationToUpdate: Partial<ReservationFormData>,
  navigateBackTo: NavigationTo,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const originalData = getState().reservations.entities[reservationId];
    const partial = createPartial(originalData, reservationToUpdate);

    if (Object.keys(partial).length !== 0) {
      dispatch(startServerRequest());

      const partialReservation = pairTableIds(partial, originalData);
      const dataToSend: EditReservationRequestBody = prepareReservationForServer(
        partialReservation,
      );

      // Keep the client side date format
      const dataForClient = {
        ...dataToSend,
        date: reservationToUpdate.date,
        datetime: parse(
          `${reservationToUpdate.date} ${reservationToUpdate.time}`,
          `${CLIENT_DATE_FORMAT} ${TIME_FORMAT}`,
          new Date(),
        ),
      };

      return serverComm
        .execPatchRequest<Record<string, never>, EditReservationRequestBody>(
          `${RESERVATIONS_PATH}/${reservationId}`,
          dataToSend,
        )
        .then(
          (resp) => {
            if (resp.succeeded === true) {
              dispatch(serverRequestSucceeded('Reservierung aktualisiert.'));
              dispatch(
                updateReservationSucceeded(reservationId, dataForClient),
              );
              void dispatch(
                updateReservationOfflineDB(reservationId, dataToSend),
              );
              dispatch(execReplace(navigateBackTo));
            } else {
              dispatch(
                serverRequestFailed(getClientOperationFailureReason(resp)),
              );
            }
          },
          (e: unknown) => {
            dispatch(serverRequestFailed(getErrorModalMessage(e)));
          },
        )
        .catch((e: unknown) => {
          sendErrorToServer(serverComm, e);
          dispatch(showError(getErrorModalMessage(e)));
        });
    } else {
      dispatch(execReplace(navigateBackTo));
    }
  };
}
