import { Typography, Box } from '@material-ui/core';
import { Button, View } from '@gts-common/client';

import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import { HOME_VIEW } from '../../constants';
import { ClientReservationWithExtraProperties } from '../../types';
import { ReservationsListGroup } from './ReservationsListGroup';

interface ReservationsTodayViewProps {
  arrivedReservations: Array<ClientReservationWithExtraProperties>;
  notArrivedReservations: Array<ClientReservationWithExtraProperties>;
  numOfReservations: number;
  numOfPersons: number;
  isOnline: boolean;
  showWalkInTables: () => void;
  execSetArrivalStatus: (reservationId: string, arrived: boolean) => void;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
}

export const ReservationsTodayView = ({
  arrivedReservations,
  notArrivedReservations,
  showWalkInTables,
  execSetArrivalStatus,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
  isOnline,
  numOfReservations,
  numOfPersons,
}: ReservationsTodayViewProps) => {
  const subtitle = `Reservierungen: ${numOfReservations} Personen: ${numOfPersons}`;
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Heutige Reservierungen"
    >
      <Typography variant="subtitle1" align="center">
        {subtitle}
      </Typography>
      <ReservationsListGroup
        title="Nicht angekommen"
        reservations={notArrivedReservations}
        isOnline={isOnline}
        execCancelReservation={execCancelReservation}
        showReservationEdit={showReservationEdit}
        showReservationDetails={showReservationDetails}
        execSetArrivalStatus={execSetArrivalStatus}
        defaultOpen={true}
      />
      <Box mt="1rem">
        <ReservationsListGroup
          title="Angekommen"
          reservations={arrivedReservations}
          isOnline={isOnline}
          execCancelReservation={execCancelReservation}
          showReservationEdit={showReservationEdit}
          showReservationDetails={showReservationDetails}
          execSetArrivalStatus={execSetArrivalStatus}
          defaultOpen={false}
        />
      </Box>
      <Box mt="1rem">
        <Button
          onClick={showWalkInTables}
          label="Walk In"
          startIcon={<DirectionsWalkIcon />}
        />
      </Box>
    </View>
  );
};
