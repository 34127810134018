import { Box } from '@material-ui/core';
import { BackButton, ForwardButton } from '@gts-common/client';

interface Props {
  showBackBtn?: boolean;
  backClicked?: () => void;
  nextClicked?: () => void;
  submitForForm?: string;
  nextButtonLabel?: string;
  isDisabled?: boolean;
}

export const NavButtons = ({
  showBackBtn = true,
  nextButtonLabel = 'Weiter',
  backClicked,
  nextClicked,
  submitForForm,
  isDisabled = false,
}: Props) => {
  const justifyContent = showBackBtn ? 'space-between' : 'flex-end';

  return (
    <Box pt="24px" display="flex" justifyContent={justifyContent}>
      {showBackBtn && (
        <BackButton disabled={isDisabled} onClick={backClicked} />
      )}
      <ForwardButton
        onClick={nextClicked}
        label={nextButtonLabel}
        submitForForm={submitForForm}
        disabled={isDisabled}
      />
    </Box>
  );
};
