import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';

import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import { format } from 'date-fns';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { AddClosedOnDate } from './subforms/AddClosedOnDate';

interface Props {
  closedDates: Array<Date>;
  deleteClosedDate: (index: number) => void;
}

export const ClosedOnDates = ({ closedDates, deleteClosedDate }: Props) => {
  /* Need the Box here because AccordionDetails is a flex container
      this will change in the future https://github.com/mui-org/material-ui/pull/22809 */
  return (
    <Box width="100%">
      <Typography gutterBottom={true} align="justify">
        Sie können hier Tage eintragen, an denen Ihre Gäste nicht über das
        Reservierungsformular reservieren können, wie z. B. Betriebsferien,
        geschlossen Gesellschaft, usw.. Sie müssen jeden Tag einzeln hinzufügen
        und vergessen Sie anschließend das Speichern nicht.
      </Typography>
      <AddClosedOnDate closedDates={closedDates} />

      {closedDates.length > 0 && (
        <Box width="100%">
          <TableContainer>
            <Table>
              <TableBody>
                {closedDates.map((closedDate, index) => {
                  return (
                    <TableRow key={`${closedDate}`}>
                      <TableCell component="th" scope="row">
                        {format(closedDate, CLIENT_DATE_FORMAT)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => deleteClosedDate(index)}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};
