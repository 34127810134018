import { TableWithExtraProperties } from '../../../types';

export function groupWalkInTablesByTime(
  tables: Array<TableWithExtraProperties>,
) {
  return tables.reduce<Record<string, Array<TableWithExtraProperties>>>(
    (timeGroups, table) => {
      const time = table.firstReservationTime;

      // Note that this will not work if we look at reservations that are more than 24 hours in the future. In that case 18:00 might come again for a different day
      if (timeGroups[time]) {
        return {
          ...timeGroups,
          [time]: [...timeGroups[time], table],
        };
      } else {
        return {
          ...timeGroups,
          [time]: [table],
        };
      }
    },
    {},
  );
}
