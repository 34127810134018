import { connect } from 'react-redux';
import { addDays, set } from 'date-fns';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import {
  RESERVATIONS_ALL_VIEW,
  RESERVATIONS_DETAILS_PATH,
  RESERVATIONS_EDIT_PATH,
} from '../../constants';
import { ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import {
  changeReservationsDate,
  execCancelReservationDecision,
  execSetArrivalStatus,
  resetReservationsDate,
  showReservationAdd,
} from '../../actions';
import { ReservationsAllView } from '../../components/Reservations/ReservationsAllView';
import { getReservationsForInterval } from './helpers/getReservationsForInterval';
import { addTableNumbersAndStatus } from './helpers/addTableNumbersAndStatus';
import { splitIntoArrivedAndNotArrived } from './helpers/splitIntoArrivedAndNotArrived';
import { sortReservationsByTime } from './helpers/sortReservationsByTime';

const mapStateToProps = (state: ReduxState) => {
  const reservationsState = state.reservations;
  const isOnline = state.app.isOnline;
  const tables = state.tables;
  const tableStatusReservedTime =
    state.restaurant.runtimeSettings.tableStatusReservedTime;
  const liveDatetime = state.app.liveDatetime;
  const reservationsDatetime = state.reservations.reservationsDatetime;
  const denormalizedReservations = denormalize(reservationsState);

  // Filter from 04:00 today till 04:00 the next day.
  // This should be enough to cover most opening times of a restaurant.
  // This might not work for bars, clubs etc.
  const filterStartDatetime = set(reservationsDatetime, {
    hours: 4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const filterEndDatetime = addDays(filterStartDatetime, 1);
  const reservationsForSelectedDate = getReservationsForInterval(
    denormalizedReservations,
    filterStartDatetime,
    filterEndDatetime,
    false,
  );

  const {
    numOfPersons,
    numOfCancelled,
    numOfReservations,
  } = reservationsForSelectedDate.reduce(
    (prev, cur) => {
      if (cur.cancelledAt === null) {
        return {
          ...prev,
          numOfReservations: prev.numOfReservations + 1,
          numOfPersons: prev.numOfPersons + cur.personsNo,
        };
      } else {
        return {
          ...prev,
          numOfCancelled: prev.numOfCancelled + 1,
        };
      }
    },
    {
      numOfReservations: 0,
      numOfPersons: 0,
      numOfCancelled: 0,
    },
  );

  const reservationWithTableNumbers = addTableNumbersAndStatus(
    reservationsForSelectedDate,
    tables.entities,
    liveDatetime,
    tableStatusReservedTime,
  );

  const {
    arrivedReservations,
    notArrivedReservations,
  } = splitIntoArrivedAndNotArrived(reservationWithTableNumbers);

  return {
    arrivedReservations: sortReservationsByTime(arrivedReservations, 'DESC'),
    notArrivedReservations: sortReservationsByTime(notArrivedReservations),
    isOnline,
    numOfReservations,
    numOfPersons,
    numOfCancelled,
    reservationsDatetime,
  };
};

const cameFrom = {
  backButtonLabel: 'Reservierungen',
  to: RESERVATIONS_ALL_VIEW,
};

function getNavigationObject(path: string) {
  return {
    pathname: path,
    state: {
      cameFrom,
    },
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  showReservationAdd() {
    dispatch(showReservationAdd(cameFrom));
  },
  execSetArrivalStatus(reservationId: string, arrived: boolean) {
    dispatch(execSetArrivalStatus(reservationId, arrived));
  },
  showReservationDetails(reservationId: string) {
    dispatch(
      push(
        getNavigationObject(`${RESERVATIONS_DETAILS_PATH}/${reservationId}`),
      ),
    );
  },
  execCancelReservation(reservationId: string) {
    dispatch(execCancelReservationDecision(reservationId));
  },
  showReservationEdit(reservationId: string) {
    dispatch(
      push(getNavigationObject(`${RESERVATIONS_EDIT_PATH}/${reservationId}`)),
    );
  },
  changeReservationsDate(newReservationsDate: Date | null) {
    if (newReservationsDate !== null) {
      dispatch(changeReservationsDate(newReservationsDate));
    }
  },
  resetReservationsDate() {
    dispatch(resetReservationsDate());
  },
});

export const ReservationsAllViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationsAllView);
