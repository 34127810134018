import {
  normalize,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  getErrorModalMessage,
  Normalized,
  sendErrorToServer,
  showError,
} from '@gts-common/client';
import {
  RESERVATIONS_PATH,
  ClientAddReservationRequestBody,
  ClientAddReservationResponse,
  AddedReservation,
} from '@gts-ft/utils';
import {
  ReservationFormData,
  serverComm,
  getClientOperationFailureReason,
} from '@gts-ft/ui';

import {
  Actions,
  RESERVATION_ADDED,
  ReservationAddedAction,
  Thunk,
} from '../reduxActionTypes';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { ClientReservation, NavigationTo } from '../../types';

import { execReplace } from '../navigation';
import { prepareReservationForServer } from './helpers/prepareReservationForServer';
import { prepareReservationForClient } from './helpers/prepareReservationForClient';

export function addReservationSucceeded(
  reservation: Normalized<ClientReservation>,
): ReservationAddedAction {
  return {
    type: RESERVATION_ADDED,
    payload: reservation,
  };
}

export function addReservationOfflineDB(
  reservation: AddedReservation,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.addRecord('reservation', reservation);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execAddReservation(
  reservation: ReservationFormData,
  navigateBackTo: NavigationTo,
): Thunk<Actions> {
  return (dispatch) => {
    const dataToSend: ClientAddReservationRequestBody = prepareReservationForServer(
      reservation,
    );

    dispatch(startServerRequest());

    return serverComm
      .execPostRequest<
        ClientAddReservationResponse,
        ClientAddReservationRequestBody
      >(RESERVATIONS_PATH, dataToSend)
      .then(
        (resp) => {
          if (resp.succeeded === true) {
            const reservationForClient = prepareReservationForClient(resp.body);
            const normalizedReservation = normalize(
              'reservationId',
              reservationForClient,
            );

            dispatch(serverRequestSucceeded('Reservierung hinzugefügt.'));
            dispatch(addReservationSucceeded(normalizedReservation));
            dispatch(addReservationOfflineDB(resp.body));
            dispatch(execReplace(navigateBackTo));
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
