import { Route, Switch, useRouteMatch, Link } from 'react-router-dom';

import {
  SERVICE_AREAS_ADD_VIEW,
  SERVICE_AREAS_EDIT_VIEW,
  SERVICE_AREAS_LIST_VIEW,
} from '../../constants';
import { ServiceAreaAddContainer } from '../../containers/ServiceAreas/ServiceAreaAdd';
import { ServiceAreaEditContainer } from '../../containers/ServiceAreas/ServiceAreaEdit';
import { ServiceAreasListContainer } from '../../containers/ServiceAreas/ServiceAreasList';

export const ServiceAreasRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={SERVICE_AREAS_ADD_VIEW}>
        <Link to="/" />
        <ServiceAreaAddContainer />
      </Route>
      <Route path={SERVICE_AREAS_EDIT_VIEW}>
        <ServiceAreaEditContainer />
      </Route>
      <Route path={SERVICE_AREAS_LIST_VIEW}>
        <ServiceAreasListContainer />
      </Route>
      <Route path={path} exact={true}>
        <ServiceAreasListContainer />
      </Route>
    </Switch>
  );
};
