import { initialState } from '../initialState';
import {
  Actions,
  SETUP_COMPLETED,
  SETUP_WIZARD_NEXT_STEP,
  SETUP_WIZARD_PREVIOUS_STEP,
  LOGIN_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
} from '../actions/reduxActionTypes';
import { ReduxState } from '../types';

export function setupWizardReducer(
  state = initialState.setupWizard,
  action: Actions,
): ReduxState['setupWizard'] {
  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        isSetupCompleted: action.payload.isSetupCompleted,
      };

    case LOGIN_CHECK_SUCCEEDED:
      return {
        ...state,
        isSetupCompleted: action.payload.isSetupCompleted,
      };

    case LOGOUT_SUCCEEDED:
      return initialState.setupWizard;

    case SETUP_WIZARD_NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case SETUP_WIZARD_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };

    case SETUP_COMPLETED:
      return {
        ...state,
        isSetupCompleted: true,
      };

    default:
      return state;
  }
}
