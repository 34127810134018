import { Grid } from '@material-ui/core';
import { TableWithExtraProperties } from '../../../types';
import { UnblockTable } from './UnblockTable';

interface WalkInTablesListProps {
  tables: Array<TableWithExtraProperties>;
  isOnline: boolean;
  tableClicked: (table: TableWithExtraProperties) => void;
}

export const UnblockTablesList = ({
  tables,
  isOnline,
  tableClicked,
}: WalkInTablesListProps) => {
  return (
    <Grid
      container
      spacing={2}
      justify="center"
      style={{ marginBottom: '1rem' }}
    >
      {tables.map((table) => {
        return (
          <Grid item xs={6} sm={4} md={3} lg={2} key={table.tableId}>
            <UnblockTable
              isOnline={isOnline}
              tableClicked={tableClicked}
              table={table}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
