import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { denormalize } from '@gts-common/client';
import {
  NotReservedTable,
  ReduxState,
  TableWithExtraProperties,
} from '../../types';
import { TablesBookingStatusView } from '../../components/Tables/BookingStatus/TablesBookingStatusView';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import { Actions } from '../../actions/reduxActionTypes';
import {
  changeTableBookingStatusDatetime,
  resetTableBookingStatusDatetime,
} from '../../actions';
import { getReservationsAfterDate } from './helpers/getReservationsAfterDate';
import { getTableExtraProperties } from './helpers/getTableExtraProperties';
import { addExtraTableProperties } from './helpers/addExtraTableProperties';

const mapStateToProps = (state: ReduxState) => {
  const reservationsState = state.reservations;
  const tablesState = state.tables;
  const tableStatusReservedTime =
    state.restaurant.runtimeSettings.tableStatusReservedTime;
  const tableBookingStatusDatetime = state.tables.tableBookingStatusDatetime;
  const denormalizedTables = denormalize(
    tablesState.items,
    tablesState.entities,
  );

  const denormalizedReservations = denormalize(reservationsState);

  const reservations = getReservationsAfterDate(
    denormalizedReservations,
    tableBookingStatusDatetime,
  );

  const tableUpdates = getTableExtraProperties(
    reservations,
    tableBookingStatusDatetime,
    tablesState.entities,
    tableStatusReservedTime,
  );

  // Filter out all virtual tables because those are
  // basically defined by their real tables
  const filteredTables = denormalizedTables.filter((table) => !table.isVirtual);

  const updatedTables = addExtraTableProperties(filteredTables, tableUpdates);

  const updateTablesArray = Array.from(Object.values(updatedTables));

  const {
    tablesVisibleInWidget,
    tablesNotVisibleInWidget,
  } = sortTablesByTableNo(updateTablesArray).reduce<{
    tablesVisibleInWidget: Array<NotReservedTable | TableWithExtraProperties>;
    tablesNotVisibleInWidget: Array<
      NotReservedTable | TableWithExtraProperties
    >;
  }>(
    (prev, cur) => {
      if (cur.visibleInWidget) {
        prev.tablesVisibleInWidget.push(cur);
      } else {
        prev.tablesNotVisibleInWidget.push(cur);
      }
      return prev;
    },
    {
      tablesVisibleInWidget: [],
      tablesNotVisibleInWidget: [],
    },
  );

  return {
    tablesVisibleInWidget,
    tablesNotVisibleInWidget,
    tableBookingStatusDatetime,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  changeTableBookingStatusDatetime(newTableBookingStatusDatetime: Date | null) {
    if (newTableBookingStatusDatetime !== null) {
      dispatch(changeTableBookingStatusDatetime(newTableBookingStatusDatetime));
    }
  },
  resetTableBookingStatusDatetime() {
    dispatch(resetTableBookingStatusDatetime());
  },
});

export const TablesBookingStatusViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TablesBookingStatusView);
