import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ReservationFormData } from '@gts-ft/ui';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { NavigationTo, ReduxState } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { execUpdateReservation } from '../../actions';
import { ReservationEdit } from '../../components/Reservations/ReservationEdit';
import { RESERVATIONS_EDIT_VIEW } from '../../constants';
import { getLocationState } from '../helpers/getLocationState';

const matchSelector = createMatchSelector<
  RouterRootState,
  { reservationId: string }
>(RESERVATIONS_EDIT_VIEW);

const mapStateToProps = (state: ReduxState) => {
  const match = matchSelector(state);
  const reservationId = match ? match.params.reservationId : undefined;
  const reservationsState = state.reservations;
  const tableEntities = state.tables.entities;
  const isOnline = state.app.isOnline;
  const { entities } = reservationsState;
  const selectedReservation = entities[reservationId ?? ''];
  const routerState = getLocationState(state);

  // reservationId could be defined but still not existent
  // so check to make sure we found a reservation
  if (selectedReservation) {
    return {
      reservation: selectedReservation,
      tables: tableEntities,
      cameFrom: routerState.cameFrom,
      isOnline,
    };
  } else {
    return {
      reservation: undefined,
      tables: tableEntities,
      cameFrom: routerState.cameFrom,
      isOnline,
    };
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execUpdateReservation(
    reservationId: string,
    reservationToUpdate: ReservationFormData,
    navigateBackTo: NavigationTo,
  ) {
    dispatch(
      execUpdateReservation(reservationId, reservationToUpdate, navigateBackTo),
    );
  },
});

export const ReservationEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationEdit);
