import { Actions, Thunk } from '../reduxActionTypes';
import { RESERVATIONS_ADD_VIEW } from '../../constants';
import { NavigationCameFrom } from '../../types';
import { execPush } from '../navigation';

export function showReservationAdd(
  cameFrom: NavigationCameFrom,
): Thunk<Actions> {
  return (dispatch) => {
    dispatch(
      execPush({
        pathname: RESERVATIONS_ADD_VIEW,
        state: {
          cameFrom,
        },
      }),
    );
  };
}
