import {
  MIN_TABLE_STATUS_RESERVED_TIME,
  MAX_TABLE_STATUS_RESERVED_TIME,
  TABLE_STATUS_RESERVED_TIME_STEP,
} from '@gts-ft/utils';

function getLabel(value: number) {
  return `${value - 0.25}`.replace('.', ',');
}

function timeUnits() {
  const reservedTimeUnits = [];
  for (
    let i = MIN_TABLE_STATUS_RESERVED_TIME;
    i <= MAX_TABLE_STATUS_RESERVED_TIME;
    i += TABLE_STATUS_RESERVED_TIME_STEP
  ) {
    reservedTimeUnits.push({ value: i, label: getLabel(i) });
  }
  return reservedTimeUnits;
}

export function getTableReservedTimeUnits() {
  return timeUnits().map((time) => ({
    value: time.value,
    label: `${time.label} ${
      time.value <= 1 + TABLE_STATUS_RESERVED_TIME_STEP ? 'Stunde' : 'Stunden'
    }`,
  }));
}
