import { Box, Collapse, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ClientReservationWithExtraProperties } from '../../types';
import { ReservationsList } from './ReservationsList';

const useStyles = makeStyles({
  groupTitle: {
    cursor: 'pointer',
  },
});

interface ReservationListGroupProps {
  title: string;
  isOnline: boolean;
  reservations: Array<ClientReservationWithExtraProperties>;
  execSetArrivalStatus: (reservationId: string, arrived: boolean) => void;
  execCancelReservation: (reservationId: string) => void;
  showReservationEdit: (reservationId: string) => void;
  showReservationDetails: (reservationId: string) => void;
  defaultOpen: boolean;
}

export const ReservationsListGroup = ({
  title,
  isOnline,
  reservations,
  execSetArrivalStatus,
  execCancelReservation,
  showReservationEdit,
  showReservationDetails,
  defaultOpen,
}: ReservationListGroupProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.groupTitle}
        display="flex"
        alignItems="center"
        onClick={() => setOpen((currentOpen) => !currentOpen)}
      >
        {open ? (
          <ExpandMore fontSize="large" />
        ) : (
          <ChevronRightIcon fontSize="large" />
        )}
        <Typography variant="subtitle1">
          {title} {reservations.length}
        </Typography>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <ReservationsList
          reservations={reservations}
          isOnline={isOnline}
          execCancelReservation={execCancelReservation}
          showReservationEdit={showReservationEdit}
          showReservationDetails={showReservationDetails}
          execSetArrivalStatus={execSetArrivalStatus}
        />
      </Collapse>
    </>
  );
};
