import { LoginStatus } from '@gts-common/client';

import { subWeeks } from 'date-fns';
import { ReduxState } from './types';
import { getStartOfNextReservationInterval } from './helpers/getStartOfNextReservationInterval';

const nowDatetime = new Date();

export const initialState: Omit<ReduxState, 'router' | 'common'> = {
  app: {
    isOnline: false,
    offlineDataTimestamp: undefined,
    liveDatetime: nowDatetime,
    startOfNextInterval: getStartOfNextReservationInterval(nowDatetime),
    loginStatus: LoginStatus.PENDING,
    warnings: {
      couldNotStoreOfflineDataShown: false,
    },
    // Is true when we are offline and there is no user data in indexeddb
    showOfflineView: false,
  },
  reservations: {
    // Used to know the earliest reservation we loaded and also during
    // login to get all reservations between a week ago and the newest reservation
    // we have for the restaurant
    earliestLoadedDate: subWeeks(nowDatetime, 1),
    entities: {},
    items: [],
    // Used in the reservation list to filter the reservations to show
    reservationsDatetime: nowDatetime,
  },
  tables: {
    entities: {},
    items: [],
    tableBookingStatusDatetime: getStartOfNextReservationInterval(nowDatetime),
  },
  serviceAreas: {
    items: [],
    entities: {},
  },
  restaurant: {
    restaurantId: '',
    openingTimes: {
      closedOnDates: [],
      reservationHours: [],
    },
    // This object is used in the restaurant settings form
    settings: {
      logoFilename: null,
      restaurantName: '',
      tableSelectionVisibleInWidget: false,
      tableStatusReservedTime: 0,
      widgetRestaurantInfoText: '',
      maxPersonsInWidget: 0,
      reservationText: '',
      reservationLeadtime: 0,
      reservationDuration: 0,
      reservationInAdvancedWeeks: 0,
      maxReservationsAtGivenTime: 0,
      widgetActive: false,
    },
    // Settings used by the app. These are a subset of restaurantSettings
    runtimeSettings: {
      reservationDuration: 0,
      tableStatusReservedTime: 0,
      widgetUrl: '',
    },
  },
  setupWizard: {
    currentStep: 0,
    isSetupCompleted: false,
  },
};
