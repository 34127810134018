import {
  TABLES_BULK_PATH,
  AddedTable,
  BulkAddTablesRequestBody,
  BulkAddTablesResponse,
  AddRealTableRequestBody,
} from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  trimData,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  Normalized,
  normalize,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  TABLE_BULK_ADDED,
  TableBulkAddedAction,
  Thunk,
} from '../reduxActionTypes';
import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';

function bulkAddTableSucceeded(
  tables: Normalized<AddedTable>,
): TableBulkAddedAction {
  return {
    type: TABLE_BULK_ADDED,
    payload: tables,
  };
}

function addTablesOfflineDB(tables: BulkAddTablesResponse): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.clearTable('table').then(() => db.addRecords('table', tables));
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execBulkAddTables(
  tables: Array<AddRealTableRequestBody>,
  actionToDispatchOnSuccess: Actions,
): Thunk<Actions> {
  return (dispatch) => {
    const dataToSend = tables.map((table) => trimData(['tableNo'], table));
    dispatch(startServerRequest());

    return serverComm
      .execPostRequest<BulkAddTablesResponse, BulkAddTablesRequestBody>(
        TABLES_BULK_PATH,
        { tables: dataToSend },
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            const normalizedTables = normalize('tableId', resp.body);
            dispatch(bulkAddTableSucceeded(normalizedTables));
            dispatch(addTablesOfflineDB(resp.body));
            dispatch(serverRequestSucceeded('Tische hinzugefügt.'));
            dispatch(actionToDispatchOnSuccess);
          } else {
            dispatch(
              serverRequestFailed(getClientOperationFailureReason(resp)),
            );
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
