import {
  Actions,
  TABLE_BOOKING_STATUS_DATETIME,
  TableBookingStatusDatetimeChanged,
  Thunk,
} from '../reduxActionTypes';
import { execGetAdditionalReservations } from '../reservations/getAdditional';

function tableBookingStatusDatetimeChanged(
  newDate: Date,
): TableBookingStatusDatetimeChanged {
  return {
    type: TABLE_BOOKING_STATUS_DATETIME,
    payload: newDate,
  };
}

export function changeTableBookingStatusDatetime(
  newDate: Date,
): Thunk<Actions> {
  return (dispatch) => {
    if (newDate) {
      dispatch(execGetAdditionalReservations(newDate));
    }

    dispatch(tableBookingStatusDatetimeChanged(newDate));
  };
}

export function resetTableBookingStatusDatetime(): Thunk<Actions> {
  return (dispatch, getState) => {
    const startOfNextInterval = getState().app.startOfNextInterval;
    dispatch(tableBookingStatusDatetimeChanged(startOfNextInterval));
  };
}
