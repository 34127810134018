import { PickerType, PickerVariantType } from './types';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

interface PickerProps {
  type: PickerType;
  variant: PickerVariantType;
  onAccept?: (newDate: Date | null) => void;
  onChange?: (newDate: Date | null) => void;
  label: string;
  disabled?: boolean;
  value: Date | null;
}

export const Picker = ({
  type,
  variant,
  value,
  onChange,
  onAccept,
  label,
  disabled = false,
}: PickerProps) => {
  if (type === 'date') {
    return (
      <DatePicker
        variant={variant}
        value={value}
        onAccept={onAccept}
        onChange={onChange}
        label={label}
        disabled={disabled}
      />
    );
  } else {
    return (
      <TimePicker
        variant={variant}
        value={value}
        onAccept={onAccept}
        onChange={onChange}
        label={label}
        disabled={disabled}
      />
    );
  }
};
