import { ErrorModal } from '@gts-common/client';
import { Action } from 'redux';
import { DialogContentText } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  onDialogAction: (action?: Action) => void;
}

export const ServiceWorkerNotInstalledModal = ({
  isOpen,
  onDialogAction,
}: Props) => {
  return (
    <ErrorModal isOpen={isOpen} onDialogAction={onDialogAction}>
      <DialogContentText>
        Achtung: die Anwendung kann aktuell nicht lokal vorgehalten werden
      </DialogContentText>
      <DialogContentText>
        Was dies bedeutet: Sie können grundsätzlich ganz normal weiterarbeiten.
        Sollte aber in Zukunft die Internetverbindung ausfallen oder
        Wartungsarbeiten auf Seiten von free-table stattfinden, können Sie die
        bestehenden Reservierungen nicht sehen bis die Internetverbindung wieder
        besteht oder die Wartungsarbeiten abgeschlossen sind.
      </DialogContentText>
      <DialogContentText>
        Was kann ich machen, um das Problem zu beheben:
      </DialogContentText>
      <ul>
        <li>
          <DialogContentText>
            Sie können diese Seite neu laden. Sollte diese Warnung nach dem
            Neuladen der Seite nicht erneut angezeigt werden, ist alles in
            Ordnung.
          </DialogContentText>
        </li>
        <li>
          <DialogContentText>
            Sollte die Warnung erneut angezeigt werden, prüfen Sie bitte die
            Einstellungen in Ihrem Browser, ob das Speichern von Daten
            eingeschränkt ist.
          </DialogContentText>
        </li>
      </ul>
    </ErrorModal>
  );
};
