import { Box, Grid, Typography } from '@material-ui/core';

import { View, WarningText, LinkButton } from '@gts-common/client';
import { useEffect } from 'react';
import { HOME_VIEW, TABLE_SETTINGS_LIST_VIEW } from '../../../constants';
import { NotReservedTable, TableWithExtraProperties } from '../../../types';
import { Picker } from '../../UI-Components/Picker/Picker';
import { BookingStatusTable } from './BookingStatusTable';

interface Props {
  tablesVisibleInWidget: Array<TableWithExtraProperties | NotReservedTable>;
  tablesNotVisibleInWidget: Array<TableWithExtraProperties | NotReservedTable>;
  tableBookingStatusDatetime: Date;
  changeTableBookingStatusDatetime: (
    newTableBookingStatusDatetime: Date | null,
  ) => void;
  resetTableBookingStatusDatetime: () => void;
}

export const TablesBookingStatusView = ({
  tablesVisibleInWidget,
  tablesNotVisibleInWidget,
  tableBookingStatusDatetime,
  changeTableBookingStatusDatetime,
  resetTableBookingStatusDatetime,
}: Props) => {
  useEffect(() => {
    return () => {
      resetTableBookingStatusDatetime();
    };
  }, [resetTableBookingStatusDatetime]);
  const noTablesAvailable =
    tablesVisibleInWidget.length === 0 && tablesNotVisibleInWidget.length === 0;

  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Tischbelegung"
    >
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <Picker
          label="Tischbelegung am"
          value={tableBookingStatusDatetime}
          onAccept={(newDate) => changeTableBookingStatusDatetime(newDate)}
          type="date"
          variant="button"
        />
        <Picker
          label="Tischbelegung um"
          value={tableBookingStatusDatetime}
          onAccept={(newDate) => changeTableBookingStatusDatetime(newDate)}
          type="time"
          variant="button"
        />
      </Box>
      {noTablesAvailable && (
        <>
          <WarningText>Sie haben noch keine Tische.</WarningText>
          <Box mt="1rem" textAlign="center">
            <LinkButton label="Tische anlegen" to={TABLE_SETTINGS_LIST_VIEW} />
          </Box>
        </>
      )}
      <Grid container spacing={2}>
        {tablesVisibleInWidget.map((table) => (
          <Grid item xs={4} md={3} lg={2} key={table.tableId}>
            <BookingStatusTable
              tableNo={table.tableNo}
              personsNo={table.personsNo}
              status={table.status}
              reservationTime={table.firstReservationTime}
            />
          </Grid>
        ))}
        {tablesNotVisibleInWidget.length > 0 && (
          <Grid item xs={12}>
            <Typography align="center" variant="body1">
              Tische, die nicht direkt von Kunden reserviert werden können:
            </Typography>
          </Grid>
        )}
        {tablesNotVisibleInWidget.map((table) => (
          <Grid item xs={4} md={3} lg={2} key={table.tableId}>
            <BookingStatusTable
              tableNo={table.tableNo}
              personsNo={table.personsNo}
              status={table.status}
              reservationTime={table.firstReservationTime}
            />
          </Grid>
        ))}
      </Grid>
    </View>
  );
};
