import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { PickerType, PickerVariantType } from './types';

interface PickerVariantProps {
  type: PickerType;
  variant: PickerVariantType;
  label: string;
  value: string;
  disabled: boolean;
  onClick: () => void;
}

export const PickerVariant = ({
  type,
  variant,
  label,
  value,
  onClick,
  disabled,
}: PickerVariantProps) => {
  const icon = type === 'date' ? <InsertInvitationIcon /> : <AccessTimeIcon />;

  if (variant === 'button') {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          {type === 'date' ? 'am' : 'um'}{' '}
          <Typography component="span" variant="h6">
            {value}
          </Typography>
        </Typography>
        <IconButton color="primary" onClick={onClick}>
          {icon}
        </IconButton>
      </Box>
    );
  } else {
    const endAdornment = <InputAdornment position="end">{icon}</InputAdornment>;

    return (
      <TextField
        label={label}
        value={value}
        disabled={disabled}
        onClick={onClick}
        InputProps={{
          readOnly: true,
          endAdornment,
        }}
      />
    );
  }
};
