"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWeekDay = exports.getDateObjectFromServerDateString = exports.createTimeListInRange = exports.createDecimalTimeListInRange = exports.getDecimalIntervals = exports.convertServerDateStringToLocalDateString = exports.convertLocalDateStringToServerDateString = exports.toDayTime = exports.getWeekDayFromDecimal = exports.toAbsoluteDecimal = exports.decimalToHoursAndMinutes = exports.convertDecimalTimeToString = exports.convertTimeStringToDecimal = exports.getMinutesFromDecimal = exports.getDecimalFromMinutes = void 0;
const constants_1 = require("./constants");
// Be careful when using this function. For some minutes
// it can return a number lots of numbers after the decimal point
// 0, 15, 30 and 45 minutes that we usually use in the application are no issue but
// you need to account for this if you use the minutes from a random date
function getDecimalFromMinutes(minutes) {
    return Number(minutes) / 60;
}
exports.getDecimalFromMinutes = getDecimalFromMinutes;
function getMinutesFromDecimal(decimal) {
    return decimal * 60;
}
exports.getMinutesFromDecimal = getMinutesFromDecimal;
function convertTimeStringToDecimal(time) {
    const [hours, minutes] = time.split(':');
    return Number(hours) + getDecimalFromMinutes(minutes);
}
exports.convertTimeStringToDecimal = convertTimeStringToDecimal;
function padNumberString2(numberString) {
    switch (numberString.length) {
        case 0:
            return '00';
        case 1:
            return `0${numberString}`;
        default:
            return numberString;
    }
}
function convertDecimalTimeToString(time) {
    const normalized = time % 24;
    const hours = Math.floor(normalized);
    const minutes = Math.floor(getMinutesFromDecimal(normalized - hours));
    return `${padNumberString2(String(hours))}:${padNumberString2(String(minutes))}`;
}
exports.convertDecimalTimeToString = convertDecimalTimeToString;
function decimalToHoursAndMinutes(decimal) {
    const hours = Math.floor(decimal);
    const minutes = Math.floor(getMinutesFromDecimal(decimal - hours));
    return [hours, minutes];
}
exports.decimalToHoursAndMinutes = decimalToHoursAndMinutes;
function toAbsoluteDecimal(day, time) {
    return 24 * day + convertTimeStringToDecimal(time);
}
exports.toAbsoluteDecimal = toAbsoluteDecimal;
function getWeekDayFromDecimal(decimal) {
    return Math.floor(decimal / 24);
}
exports.getWeekDayFromDecimal = getWeekDayFromDecimal;
function toDayTime(decimal) {
    // 24 * 7 = 1 week => normalize to one week
    const normalized = decimal % (24 * 7);
    const day = getWeekDayFromDecimal(decimal);
    const time = convertDecimalTimeToString(normalized - day * 24);
    return [day, time];
}
exports.toDayTime = toDayTime;
function convertLocalDateStringToServerDateString(dateString) {
    const [date, month, year] = dateString.split('.');
    return `${year}-${month}-${date}`;
}
exports.convertLocalDateStringToServerDateString = convertLocalDateStringToServerDateString;
function convertServerDateStringToLocalDateString(dateString) {
    const [year, month, date] = dateString.split('-');
    return `${date}.${month}.${year}`;
}
exports.convertServerDateStringToLocalDateString = convertServerDateStringToLocalDateString;
function roundUpToStep(timeDecimal) {
    const hours = Math.floor(timeDecimal);
    const minutesDecimal = timeDecimal - hours;
    if (!minutesDecimal) {
        return hours;
    }
    else if (minutesDecimal <= 0.25) {
        return hours + 0.25;
    }
    else if (minutesDecimal <= 0.5) {
        return hours + 0.5;
    }
    else if (minutesDecimal <= 0.75) {
        return hours + 0.75;
    }
    else {
        return hours + 1;
    }
}
function getDecimalIntervals(day, intervals) {
    return intervals.reduce((prev, interval) => {
        const start = interval.startTime;
        const end = interval.endTime;
        if (start <= end) {
            return [...prev, { start, end }];
        }
        // ex: So-Mi, day = Di => {start: Mo, end: Mi}
        // ex: So-Mi, day = So => {start: So, end: Mi + 1 week}
        if (day <= Math.floor(interval.endTime / 24)) {
            return [...prev, { start: 0, end: end }];
        }
        else {
            return [...prev, { start, end: end + 24 * 7 }];
        }
    }, []);
}
exports.getDecimalIntervals = getDecimalIntervals;
function createDecimalTimeListInRange(minTime = constants_1.MIN_TIME, maxTime = constants_1.MAX_TIME, timeStep = constants_1.TIME_STEP) {
    const roundedMinTime = roundUpToStep(minTime);
    const selectTimes = [];
    if (roundedMinTime > maxTime) {
        return selectTimes;
    }
    for (let i = roundedMinTime; i <= maxTime; i += timeStep) {
        selectTimes.push(i);
    }
    return selectTimes;
}
exports.createDecimalTimeListInRange = createDecimalTimeListInRange;
function createTimeListInRange(minTime = constants_1.MIN_TIME, maxTime = constants_1.MAX_TIME, timeStep = constants_1.TIME_STEP) {
    if (minTime > maxTime) {
        throw new Error('maxTime must be larger than minTime');
    }
    const selectTimes = [];
    for (let i = roundUpToStep(minTime); i <= maxTime; i += timeStep) {
        selectTimes.push(convertDecimalTimeToString(i));
    }
    return selectTimes;
}
exports.createTimeListInRange = createTimeListInRange;
function getDateObjectFromServerDateString(dateString) {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
}
exports.getDateObjectFromServerDateString = getDateObjectFromServerDateString;
function getWeekDay(dayIndex, short) {
    const weekDays = short
        ? ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
        : [
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag',
            'Sonntag',
        ];
    return weekDays[dayIndex];
}
exports.getWeekDay = getWeekDay;
