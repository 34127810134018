import { SERVICE_AREA_PATH, EditServiceAreaRequestBody } from '@gts-ft/utils';

import { serverComm, getClientOperationFailureReason } from '@gts-ft/ui';
import {
  createPartial,
  getErrorModalMessage,
  trimData,
  serverRequestSucceeded,
  serverRequestFailed,
  startServerRequest,
  execOpenMessage,
  MessageType,
  AlertType,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import {
  Actions,
  SERVICE_AREA_UPDATED,
  ServiceAreaUpdatedAction,
  Thunk,
} from '../reduxActionTypes';
import { clientErrors } from '../../validationMessages';

import { db } from '../../db';
import { showOnceCouldNotStoreOfflineData } from '../showOnceCouldNotStoreOfflineData';
import { SERVICE_AREAS_LIST_VIEW } from '../../constants';
import { execReplace } from '../navigation';
import { isServiceAreaNameUnique } from './helpers/isServiceAreaNameUnique';

function updateServiceAreaSucceeded(
  serviceAreaId: string,
  serviceArea: EditServiceAreaRequestBody,
): ServiceAreaUpdatedAction {
  return {
    type: SERVICE_AREA_UPDATED,
    payload: { serviceAreaId, serviceArea },
  };
}

function updateServiceAreaOfflineDB(
  serviceAreaId: string,
  update: EditServiceAreaRequestBody,
): Thunk<Actions> {
  return async (dispatch) => {
    try {
      await db.updateRecord('serviceArea', serviceAreaId, update);
    } catch (e: unknown) {
      sendErrorToServer(serverComm, e);
      dispatch(showOnceCouldNotStoreOfflineData());
    }
  };
}

export function execUpdateServiceArea(
  serviceAreaId: string,
  serviceAreaToUpdate: EditServiceAreaRequestBody,
): Thunk<Actions> {
  return (dispatch, getState) => {
    const { entities, items } = getState().serviceAreas;
    const originalData = entities[serviceAreaId];

    const partial = createPartial(
      originalData,
      trimData(['name'], serviceAreaToUpdate),
    );

    if (Object.keys(partial).length !== 0) {
      const dataToSend: EditServiceAreaRequestBody = partial;

      if (
        isServiceAreaNameUnique(dataToSend.name, items, entities, serviceAreaId)
      ) {
        dispatch(startServerRequest());

        return serverComm
          .execPatchRequest<Record<string, never>, EditServiceAreaRequestBody>(
            `${SERVICE_AREA_PATH}/${serviceAreaId}`,
            dataToSend,
          )
          .then(
            (resp) => {
              if (resp.succeeded === true) {
                dispatch(
                  serverRequestSucceeded('Servicebereich aktualisiert.'),
                );
                dispatch(updateServiceAreaSucceeded(serviceAreaId, dataToSend));
                dispatch(updateServiceAreaOfflineDB(serviceAreaId, dataToSend));
                dispatch(execReplace(SERVICE_AREAS_LIST_VIEW));
              } else {
                dispatch(
                  serverRequestFailed(getClientOperationFailureReason(resp)),
                );
              }
            },
            (e: unknown) => {
              dispatch(serverRequestFailed(getErrorModalMessage(e)));
            },
          )
          .catch((e: unknown) => {
            sendErrorToServer(serverComm, e);
            dispatch(showError(getErrorModalMessage(e)));
          });
      } else {
        dispatch(
          execOpenMessage({
            type: MessageType.ALERT,
            alertType: AlertType.ERROR,
            text: clientErrors.SERVICE_AREA_NAME_EXISTS,
          }),
        );
      }
    } else {
      dispatch(execReplace(SERVICE_AREAS_LIST_VIEW));
    }
  };
}
