import Dexie from 'dexie';
import { LoginResponse } from '@gts-ft/utils';

class Db {
  db: Dexie;
  dbSupported = Boolean(window.indexedDB);

  constructor(dbName: string) {
    this.db = new Dexie(dbName, { autoOpen: false });
    this.versionUpdate();
  }

  versionUpdate() {
    this.db.version(1).stores({
      props: 'key',
      table: 'tableId',
      reservation: 'reservationId',
      restaurant: 'restaurantId',
      serviceArea: 'serviceAreaId',
    });
  }

  async saveInitialData(
    tables: LoginResponse['tables'],
    reservations: LoginResponse['reservations'],
    restaurant: LoginResponse['restaurant'],
    serviceAreas: LoginResponse['serviceAreas'],
  ) {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    const dbTables = [
      this.db.table('props'),
      this.db.table('table'),
      this.db.table('reservation'),
      this.db.table('restaurant'),
      this.db.table('serviceArea'),
    ];

    await this.db.transaction('rw', dbTables, async () => {
      await this.db.table('table').clear();
      await this.db.table('reservation').clear();
      await this.db.table('restaurant').clear();
      await this.db.table('serviceArea').clear();

      await this.db.table('table').bulkPut(tables);
      await this.db.table('reservation').bulkPut(reservations);
      await this.db.table('restaurant').put(restaurant);
      await this.db.table('serviceArea').bulkPut(serviceAreas);

      await this.db.table('props').put({ key: 'timestamp', value: Date.now() });
    });
  }

  deleteDb() {
    this.db.close();
    return this.db.delete();
  }

  async addRecord(dbTableName: string, data: unknown) {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    const dbTables = [this.db.table('props'), this.db.table(dbTableName)];
    return this.db.transaction('rw', dbTables, async () => {
      await this.db.table(dbTableName).add(data);
      await this.db.table('props').put({ key: 'timestamp', value: Date.now() });
    });
  }

  async addRecords(dbTableName: string, data: Array<unknown>) {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    const dbTables = [this.db.table('props'), this.db.table(dbTableName)];
    return this.db.transaction('rw', dbTables, async () => {
      const table = this.db.table(dbTableName);
      // just add all overwriting already existing entries
      await table.bulkPut(data);
      await this.db.table('props').put({ key: 'timestamp', value: Date.now() });
    });
  }

  async updateRecord(
    dbTableName: string,
    id: string,
    data: Record<string, unknown>,
  ) {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    const dbTables = [this.db.table('props'), this.db.table(dbTableName)];
    return this.db.transaction('rw', dbTables, async () => {
      await this.db.table(dbTableName).update(id, data);
      await this.db.table('props').put({ key: 'timestamp', value: Date.now() });
    });
  }

  async deleteRecord(dbTableName: string, id: string) {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    const dbTables = [this.db.table('props'), this.db.table(dbTableName)];
    return this.db.transaction('rw', dbTables, async () => {
      await this.db.table(dbTableName).delete(id);
      await this.db.table('props').put({ key: 'timestamp', value: Date.now() });
    });
  }

  async getRecords<T>(dbTableName: string): Promise<Array<T>> {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    return this.db.table(dbTableName).toArray();
  }

  async getTimestamp() {
    if (!this.db.isOpen()) {
      await this.db.open();
    }

    return this.db.table('props').get('timestamp');
  }
  async clearTable(dbTableName: string) {
    const dbTables = [this.db.table('props'), this.db.table(dbTableName)];
    return this.db.transaction('rw', dbTables, async () => {
      await this.db.table(dbTableName).clear();
    });
  }
}

export const db = new Db('free-table');
