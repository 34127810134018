import { MouseEvent, PureComponent } from 'react';

import { Snackbar } from '@material-ui/core';
import { AccountMenu } from './AccountMenu';
import { SettingsMenu } from './SettingsMenu';
import { HeaderAppBar } from './HeaderAppBar';

interface HeaderProps {
  date: string;
  time: string;
  offlineDataTimestamp: string;
  isOnline: boolean;
  isSetupCompleted: boolean;
  execLogout: () => void;
}

interface State {
  accountAnchor: MouseEvent['currentTarget'] | null;
  settingsAnchor: MouseEvent['currentTarget'] | null;
}

export class Header extends PureComponent<HeaderProps, State> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      accountAnchor: null,
      settingsAnchor: null,
    };

    this.openAccountMenu = this.openAccountMenu.bind(this);
    this.openSettingsMenu = this.openSettingsMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openAccountMenu(event: MouseEvent) {
    this.setState({
      accountAnchor: event.currentTarget,
      settingsAnchor: null,
    });
  }

  openSettingsMenu(event: MouseEvent) {
    this.setState({
      accountAnchor: null,
      settingsAnchor: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      accountAnchor: null,
      settingsAnchor: null,
    });
  }

  render() {
    const {
      execLogout,
      date,
      time,
      offlineDataTimestamp,
      isOnline,
      isSetupCompleted,
    } = this.props;
    const { accountAnchor, settingsAnchor } = this.state;
    const isAccountMenuOpen = Boolean(accountAnchor);
    const isSettingsMenuOpen = Boolean(settingsAnchor);

    return (
      <>
        <HeaderAppBar
          date={date}
          time={time}
          openSettingsMenu={this.openSettingsMenu}
          openAccountMenu={this.openAccountMenu}
          isSetupCompleted={isSetupCompleted}
        />
        <Snackbar
          open={!isOnline}
          message={`Offline Modus. Speichern und Ändern von Daten ist aktuell nicht möglich! Es werden alle Reservierungen angezeigt, die bis zum ${offlineDataTimestamp} Uhr gespeichert wurden.`}
        />
        <AccountMenu
          anchor={accountAnchor}
          isOpen={isAccountMenuOpen}
          isOnline={isOnline}
          execLogout={execLogout}
          closeMenu={this.closeMenu}
        />
        <SettingsMenu
          anchor={settingsAnchor}
          isOpen={isSettingsMenuOpen}
          closeMenu={this.closeMenu}
        />
      </>
    );
  }
}
