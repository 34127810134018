import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { denormalize } from '@gts-common/client';
import { AddedTable } from '@gts-ft/utils';
import { TableSettingsList } from '../../components/TableSettings/TableSettingsList';
import { getAssociatedTables } from '../../helpers/getAssociatedTables';

import { execDeleteTableDecision } from '../../actions';
import { ReduxState, TableWithTableNos } from '../../types';
import { Actions } from '../../actions/reduxActionTypes';
import { sortTablesByTableNo } from '../helpers/sortTablesByTableNo';
import {
  TABLE_SETTINGS_ADD_REAL_VIEW,
  TABLE_SETTINGS_ADD_VIRTUAL_VIEW,
  TABLE_SETTINGS_EDIT_PATH,
} from '../../constants';

function addTableNosToTables(
  tables: Array<AddedTable>,
  allTables: Record<string, AddedTable>,
): Array<TableWithTableNos> {
  return tables.map((table) => {
    if (table.tableIds.length > 0) {
      return {
        ...table,
        tableNos: sortTablesByTableNo(
          getAssociatedTables(table, allTables),
        ).map((realTable) => realTable.tableNo),
      };
    } else {
      return {
        ...table,
        tableNos: [],
      };
    }
  });
}

function positionTables(tables: Array<TableWithTableNos>) {
  return sortTablesByTableNo(tables).reduce<{
    leftTables: Array<TableWithTableNos>;
    rightTables: Array<TableWithTableNos>;
  }>(
    (acc, cur, idx) => {
      if (idx % 2 === 0) {
        acc.leftTables.push(cur);
      } else {
        acc.rightTables.push(cur);
      }
      return acc;
    },
    { leftTables: [], rightTables: [] },
  );
}

const mapStateToProps = (state: ReduxState) => {
  const isOnline = state.app.isOnline;
  const tablesState = state.tables;

  const denormalizedTables = denormalize(
    tablesState.items,
    tablesState.entities,
  );

  const { tables, virtualTables } = denormalizedTables.reduce<{
    tables: Array<AddedTable>;
    virtualTables: Array<AddedTable>;
  }>(
    (acc, cur) => {
      if (cur.isVirtual) {
        return {
          tables: acc.tables,
          virtualTables: [...acc.virtualTables, cur],
        };
      } else {
        return {
          tables: [...acc.tables, cur],
          virtualTables: acc.virtualTables,
        };
      }
    },
    { tables: [], virtualTables: [] },
  );

  const tablesWithTableNos = addTableNosToTables(tables, tablesState.entities);
  const virtualTablesWithTableNos = addTableNosToTables(
    virtualTables,
    tablesState.entities,
  );

  return {
    realTables: positionTables(tablesWithTableNos),
    virtualTables: positionTables(virtualTablesWithTableNos),
    isOnline,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  onDelete(tableId: string, isVirtual: boolean) {
    dispatch(execDeleteTableDecision(tableId, isVirtual));
  },
  onEdit(tableId: string) {
    dispatch(push(`${TABLE_SETTINGS_EDIT_PATH}/${tableId}`));
  },
  onAdd(isVirtual: boolean) {
    if (isVirtual) {
      dispatch(push(TABLE_SETTINGS_ADD_VIRTUAL_VIEW));
    } else {
      dispatch(push(TABLE_SETTINGS_ADD_REAL_VIEW));
    }
  },
});

export const TableSettingsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableSettingsList);
