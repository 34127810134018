import { AddButton, DatepickerField } from '@gts-common/client';
import { Form } from 'formik';

import { Box } from '@material-ui/core';

interface Props {
  closedDates: Array<Date>;
}

const closedOnDatesFormId = 'closed-on-dates-form';

export const AddClosedOnDate = ({ closedDates }: Props) => {
  return (
    <Form id={closedOnDatesFormId} noValidate={true}>
      <DatepickerField
        label="Geschlossener Tag"
        name="closedDate"
        extraDisabledDays={closedDates}
        required
      />
      <Box mt="1rem" textAlign="center">
        <AddButton submitForForm={closedOnDatesFormId} />
      </Box>
    </Form>
  );
};
