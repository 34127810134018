import {
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  List,
  Box,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddButton, View } from '@gts-common/client';
import { TableWithTableNos } from '../../types';
import { HOME_VIEW } from '../../constants';
import { Table } from './Table';

interface TableViewProps {
  title: string;
  tables: {
    leftTables: Array<TableWithTableNos>;
    rightTables: Array<TableWithTableNos>;
  };
  onEdit: (tableId: string) => void;
  onDelete: (tableId: string, isVirtual: boolean) => void;
  onAdd: (isVirtual: boolean) => void;
  isOnline: boolean;
  btnLabel: string;
  isVirtual: boolean;
  infoText: string;
}

const TableView = ({
  title,
  tables,
  onEdit,
  onDelete,
  onAdd,
  isOnline,
  btnLabel,
  isVirtual,
  infoText,
}: TableViewProps) => (
  <Accordion defaultExpanded>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{title}</Typography>
    </AccordionSummary>
    {/* Need the Box here because AccordionDetails is a flex container
      this will change in the future https://github.com/mui-org/material-ui/pull/22809 */}
    <AccordionDetails>
      <Box width="100%">
        <Typography>{infoText}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <List>
              {tables.leftTables.map((table) => (
                <Table
                  key={table.tableId}
                  {...table}
                  onEdit={() => onEdit(table.tableId)}
                  onDelete={() => onDelete(table.tableId, isVirtual)}
                  isOnline={isOnline}
                  realTableNos={table.tableNos}
                  isVirtual={isVirtual}
                />
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              {tables.rightTables.map((table) => (
                <Table
                  key={table.tableId}
                  {...table}
                  onEdit={() => onEdit(table.tableId)}
                  onDelete={() => onDelete(table.tableId, isVirtual)}
                  isOnline={isOnline}
                  realTableNos={table.tableNos}
                  isVirtual={isVirtual}
                />
              ))}
            </List>
          </Grid>
        </Grid>
        <Box mt="1rem" textAlign="center">
          <AddButton
            onClick={() => onAdd(isVirtual)}
            label={btnLabel}
            disabled={!isOnline}
          />
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
);

interface TablesProps {
  realTables: {
    leftTables: Array<TableWithTableNos>;
    rightTables: Array<TableWithTableNos>;
  };
  virtualTables: {
    leftTables: Array<TableWithTableNos>;
    rightTables: Array<TableWithTableNos>;
  };
  onEdit: (tableId: string) => void;
  onDelete: (tableId: string, isVirtual: boolean) => void;
  onAdd: (isVirtual: boolean) => void;
  isOnline: boolean;
}

export const TableSettingsList = ({
  realTables,
  virtualTables,
  onEdit,
  onDelete,
  onAdd,
  isOnline,
}: TablesProps) => {
  return (
    <View
      hasBackButton={true}
      navigateBackTo={HOME_VIEW}
      backButtonLabel="Startseite"
      title="Tische verwalten"
    >
      <TableView
        title="Tische"
        tables={realTables}
        onEdit={onEdit}
        onDelete={onDelete}
        onAdd={onAdd}
        isOnline={isOnline}
        btnLabel="Neuer Tisch"
        isVirtual={false}
        infoText="Hier können Sie Ihre Tische aus Ihrer Gastronomie hinzufügen."
      />
      <TableView
        title="Tischkombination"
        tables={virtualTables}
        onEdit={onEdit}
        onDelete={onDelete}
        onAdd={onAdd}
        isOnline={isOnline}
        btnLabel="Neue Tischkombination"
        isVirtual
        infoText="Tischkombinationen bieten Ihnen die Möglichkeit Reservierungen für eine größere Personenanzahl zu ermöglichen. Beispiel: Auf Basis von zwei 4er - Tische können sie eine 6er - oder 8er - Tischkombination erstellen. Die Tischkombination erhält eine eigene Tischnummer, hat eine Mindestbelegung und kann auch über das Reservierungsformular gebucht werden. Die Tischkombination ist über das Reservierungsformular nur buchbar, wenn zum gegebenen Zeitpunkt alle Tische frei sind, die der Tischkombination zugeordnet sind."
      />
    </View>
  );
};
