import { RouterLocation } from 'connected-react-router';
import { NavigationCameFrom, ReduxState } from '../../types';
import { HOME_VIEW } from '../../constants';

const cameFromHome: NavigationCameFrom = {
  backButtonLabel: 'Startseite',
  to: HOME_VIEW,
};

export function getLocationState<T extends Record<string, unknown>>(
  state: ReduxState,
  defaultCameFrom = cameFromHome,
):
  | (Partial<T> & { cameFrom: NavigationCameFrom })
  | { cameFrom: NavigationCameFrom } {
  const routerLocation = state.router.location as RouterLocation<
    | (T & {
        cameFrom?: NavigationCameFrom;
      })
    | undefined
  >;
  const routerState = routerLocation.state;

  if (routerState === undefined) {
    return {
      cameFrom: defaultCameFrom,
    };
  } else {
    return {
      ...routerState,
      cameFrom: routerState.cameFrom ?? defaultCameFrom,
    };
  }
}
