import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'connected-react-router';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { Home } from '../components/Home';
import { showReservationAdd } from '../actions';
import {
  HOME_VIEW,
  RESERVATIONS_ALL_VIEW,
  RESERVATIONS_TODAY_VIEW,
  TABLES_BOOKING_STATUS_VIEW,
  TABLES_UNBLOCK_VIEW,
  TABLES_WALK_IN_VIEW,
} from '../constants';

const mapStateToProps = null;

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  showReservationAdd() {
    dispatch(
      showReservationAdd({
        backButtonLabel: 'Startseite',
        to: HOME_VIEW,
      }),
    );
  },
  showReservationsToday() {
    dispatch(push(RESERVATIONS_TODAY_VIEW));
  },
  showReservationsAll() {
    dispatch(push(RESERVATIONS_ALL_VIEW));
  },
  showTablesBookingStatus() {
    dispatch(push(TABLES_BOOKING_STATUS_VIEW));
  },
  showWalkInTables() {
    dispatch(push(TABLES_WALK_IN_VIEW));
  },
  showUnblockTables() {
    dispatch(push(TABLES_UNBLOCK_VIEW));
  },
});

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
