import { Typography } from '@material-ui/core';
import { NotReservedTable, TableWithExtraProperties } from '../../../types';
import { WalkInTablesList } from './WalkInTablesList';

interface WalkInTableGroupProps {
  title: string;
  tables: Array<TableWithExtraProperties | NotReservedTable>;
  isOnline: boolean;
  tableClicked: (table: TableWithExtraProperties | NotReservedTable) => void;
}

export const WalkInTableGroup = ({
  title,
  tables,
  isOnline,
  tableClicked,
}: WalkInTableGroupProps) => {
  return (
    <>
      <Typography align="center" variant="h6" gutterBottom={true}>
        {title}
      </Typography>
      <WalkInTablesList
        isOnline={isOnline}
        tableClicked={tableClicked}
        tables={tables}
      />
    </>
  );
};
